import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSpring, animated } from "react-spring";

const Burger = ({ parentCallback }) => {
  //Using react hooks to setup toggle
  const [isOpen, toggle] = useState(false);
  const burgerAnimation = useSelector(state => state.sideMenu.burgerAnimation);
  const closeAllAction = useDispatch();

  // Check to see if a burger animation is needed
  useEffect(() => {
    if (burgerAnimation) {
      toggle(false);
    }
    closeAllAction({ type: "CLOSE_ALL" });
  }, [burgerAnimation, isOpen, closeAllAction]);

  const first = useSpring({
    transform: isOpen ? "translate(5px, 32px) rotate(-45deg)" : "translate(2px, 7px) rotate(0deg)",
  });
  const second = useSpring({
    transform: isOpen ? "translate(10px, 4px) rotate(45deg)" : "translate(2px, 19px) rotate(0deg)",
  });
  const third = useSpring({
    transform: isOpen ? "translate(5px, 32px) rotate(-45deg)" : "translate(2px, 31px) rotate(0deg)",
  });

  return (
    <div className="d-print-none BurgerMenu">
      <svg
        onClick={() => {
          toggle(prevState => !prevState);
          parentCallback(!isOpen);
        }}
        width="40"
        height="32"
        viewBox="0 0 44 44"
        fill="#73CEC1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.rect width="40" height="4" rx="2" style={first} />
        <animated.rect width="40" height="4" rx="2" style={second} />
        <animated.rect width="40" height="4" rx="2" style={third} />
      </svg>
    </div>
  );
};
export default Burger;
