import axios from 'axios'
import history from './history'
import store from './store'
import { SET_CURRENT_USER } from './redux/actions/types'

export const version = '1.01' // cache version
// A reverse proxy is used on resources not found (setupProxy.js)
export const backInstance = axios.create({
  baseURL: '/api',
})

// Cognito configuration production
export const CLIENT_ID = '7mvcs9cjf772c2lusvt0f550tm'
export const USER_POOL_ID = 'eu-west-1_Lr4DbdvVt'
export const COGNITO_REGION = 'eu-west-1'
export const COGNITO_APP_SECRET_KEY = '1t5mnkhikms17mdo4d717ass3r4a4s6ltru31hkrgl100ejm5td1'

// Cognito configuration staging
// export const CLIENT_ID = '65upu2fpjlgg1iu1nt6ddvid8e'
// export const USER_POOL_ID = 'us-east-2_GGaU3epFB'
// export const COGNITO_REGION = 'us-east-2'
// export const COGNITO_APP_SECRET_KEY = 'ap47pk5qpau8m4cs2betue6leggit026nmip90mm1dhr1inbb4c'

// Insert the cognito access token to the authorization header before each API request
backInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('AccessToken')
  config.headers['Authorization'] = `Bearer ${accessToken}`
  config.headers['credentials'] = 'same-origin'
  config.headers['Accept'] = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  config.headers['Access-Control-Allow-Headers'] = '*'
  config.headers['crossDomain'] = 'true'
  config.headers['Access-Control-Expose-Headers'] = `X-Total-Count`
  return config
})

backInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('AccessToken')
      localStorage.removeItem('state')
      store.dispatch({
        type: SET_CURRENT_USER,
        payload: null,
        tokenExp: null,
      })
      history.push('/')
    }

    return Promise.reject(error)
  }
)


