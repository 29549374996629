export const SHOW_PROFILE = 'SHOW_PROFILE'
export const SHOW_MESSAGES = 'SHOW_MESSAGES'
export const SHOW_APPOINTMENTS = 'SHOW_APPOINTMENTS'
export const BURGER_ANIMATION = 'BURGER_ANIMATION'
export const CLOSE_ALL = 'CLOSE_ALL'
export const CLEAR_CSS = 'CLEAR_CSS'
export const REMOVE_CLEAR_CSS = 'REMOVE_CLEAR_CSS'
export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const NEW_USER = 'NEW_USER'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const FETCH_RESULTS_START = 'FETCH_RESULTS_START'
export const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS'
export const FETCH_RESULTS_FAILURE = 'FETCH_RESULTS_FAILURE' 
export const RH_SELECTED = 'RH_SELECTED'
export const FETCH_RH_DETAILS = 'FETCH_RH_DETAILS'
export const FETCH_SCAN = 'FETCH_SCAN'
export const FETCH_SCAN_SPECIES = 'FETCH_SCAN_SPECIES'
export const FETCH_PRACTICE_LABEL = 'FETCH_PRACTICE_LABEL'
export const FETCH_PRACTICE_DATA_INDEX_REDUCER = 'FETCH_PRACTICE_DATA_INDEX_REDUCER'
export const FETCH_IMAGES = 'FETCH_IMAGES'
export const FETCH_WORKLIST_START = 'FETCH_WORKLIST_START'
export const FETCH_WORKLIST_SUCCESS = 'FETCH_WORKLIST_SUCCESS'
export const FETCH_WORKLIST_FAILURE = 'FETCH_WORKLIST_FAILURE'
export const EDIT_WORKLIST = 'EDIT_WORKLIST'
export const EDIT_BATCH_DETAILS = 'EDIT_BATCH_DETAILS'
export const DELETE_WORKLIST = 'DELETE_WORKLIST'
export const FETCH_PRACTICE_PRODUCTS = 'FETCH_PRACTICE_PRODUCTS'
export const FETCH_PRACTICE_DOCUMENTATION = 'FETCH_PRACTICE_DOCUMENTATION'
export const GET_ACTIVE_DEVICE_ID = 'GET_ACTIVE_DEVICE_ID'
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR'
export const GET_SCAN_COMMENT_DATA = 'GET_SCAN_COMMENT_DATA'
export const GET_SCAN = 'GET_SCAN'
export const GET_ACTIVE_DEVICE_DATA = 'GET_ACTIVE_DEVICE_DATA'
export const GET_PRACTICE_ID = 'GET_PRACTICE_ID'
export const FETCH_BATCH_DETAILS = 'FETCH_BATCH_DETAILS'
export const USER_ALERT = 'USER_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const GET_PRACTICE_DATA = 'GET_PRACTICE_DATA'
export const FETCH_BATCH_ERROR = 'FETCH_BATCH_ERROR'
export const SET_RH_PREVIOUS_PAGE = 'SET_RH_PREVIOUS_PAGE'
export const RESET_RH_PREVIOUS_PAGE = 'RESET_RH_PREVIOUS_PAGE'
export const FETCH_BATCH_REPORT = 'FETCH_BATCH_REPORT'
export const FETCH_BATCH_COMMENT = 'FETCH_BATCH_COMMENT'
export const CLEAR_IMAGES = 'CLEAR_IMAGES'
export const PENDING_SCANS_TABLE_PAGE = 'PENDING_SCANS_TABLE_PAGE'
export const RESULTS_HISTORY_TABLE_PAGE = 'RESULTS_HISTORY_TABLE_PAGE'
export const EQUIPMENT_SELECTED = 'EQUIPMENT_SELECTED'
export const EQUIPMENT_TABLE_PAGE = 'EQUIPMENT_TABLE_PAGE'
export const FETCH_DEVICE_AUTH = 'FETCH_DEVICE_AUTH'
export const FETCH_SCAN_CREDITS = 'FETCH_SCAN_CREDITS'
export const FETCH_EQUIPMENT_START = 'FETCH_EQUIPMENT_START'
export const FETCH_EQUIPMENT_SUCCESS = 'FETCH_EQUIPMENT_SUCCESS'
export const FETCH_EQUIPMENT_FAILURE = 'FETCH_EQUIPMENT_FAILURE' 
export const FETCH_LATEST_UPDATE = 'FETCH_LATEST_UPDATE'