import React from 'react'
import { useLangContext } from '../context/LangContext'

const LangSelector = () => {
  const { locale, setLocale } = useLangContext()

  return (
    <select
      style={{
        border: 'none',
        cursor: 'pointer',
      }}
      defaultValue={locale}
      onChange={event => {
        setLocale(event.target.value)
      }}
    >
      <option value='en'>{'EN'}</option>
      <option value='fr'>{'FR'}</option>
    </select>
  )
}

export default LangSelector
