import React, { useCallback, useEffect} from 'react'
import { connect } from 'react-redux'
import { 
  fetchBatchReport, 
  editBatchScanComment,
  fetchBatchDetails,
  getPracticeDataIndex, 
} from '../../redux/actions/fetchResultHistoryActions';
import generatePDF, { Margin } from "react-to-pdf";
import { useState } from 'react';
import { FaAlignLeft, FaAlignRight } from "react-icons/fa";
import { getPracticeData } from '../../redux/actions/deviceSettingsActions'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import { object } from 'yup'
import {ThemeContext} from '../../context/theme-context';


const practiceAndBatchDescription = (batchReport, t, fetchedPracticelabel) => {
  console.log('Practice Label ' + fetchedPracticelabel)

  if (batchReport === null) {
    return <></>
  } else {
    const parasiteList = batchReport.batch_scans?.find(parasite => parasite.results?.length !== 0)
    const parasites =
      parasiteList &&
      parasiteList.results.map(parasite => ({
        description: parasite.description,
        display_name: parasite.display_name,
      }))

  return (
    
    <ThemeContext.Consumer>
    {({theme, darkMode}) => (
    <div className='mt-4 grid-container'>
        <div className='grid-item left-column'>
          <span className='left-col-text'>{t('batchID')}: </span>
          <span id='br-id'> {batchReport.batch_scans[0].owner_name}</span>
        </div>
        <div className='grid-item right-column'>
          <span className='left-col-text'>{t('testDate')}:</span>
          <span id='br-date'>
          {new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(batchReport.batch_scans[0].created.$date)}
          </span>
        </div>
        <div className='grid-item left-column'>
          <span className='left-col-text'>{t('ownerReference')}: </span>
          <span> {batchReport.batch_scans[0].owner_name}</span>
        </div>
        <div className='grid-item right-column'>
          <span className='left-col-text'>{t('practice')}:</span>
          <span> {fetchedPracticelabel ? fetchedPracticelabel : '' }</span>
        </div>
        <div className='grid-item left-column'>
          <span className='left-col-text'>{t('species')}: </span>
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {batchReport?.species ? batchReport.species.label?.replaceAll('_', ' ') : '-'}
          </span>
        </div>
        <div className='grid-item left-column'>
              <span className='left-col-text'>{t('description')}: </span>
              <span className='flex-wrapper-report_text'> {batchReport.description}</span>  
          </div>
    </div>
    )}
      </ThemeContext.Consumer>
  );
  
  }

  

}
const detailColumn = (batchReport, t, fetchedPracticelabel) => {

  if (batchReport === null) {
    return <></>
  } else {
    const parasiteList = batchReport.batch_scans?.find(parasite => parasite.results?.length !== 0)
    const parasites =
      parasiteList &&
      parasiteList.results.map(parasite => ({
        description: parasite.description,
        display_name: parasite.display_name,
      }))

    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <div>
        {practiceAndBatchDescription(batchReport, t, fetchedPracticelabel.scan)}
        
        <div className='table-search-wrapper'>
                <h4 className='report-header-text'>{t('batchReport')}</h4>
        </div>
              {/* This is the line */}
              <h4  
              className='mb-4'
              style={{
                paddingTop: '5px',
                paddingBottom: '15px',
                paddingRight: '10px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
              >
        </h4>
              
        <div className='mb-4'>
          <div className='col-12 table-overflow' >
            <table className='dr-table batch-report-table'>
              <thead>
                <tr className='print_table'>
                  <th >{t('animalName')}</th>
                  <th>{t('scanID')}</th>
                  <th>{t('scanState')}</th>
                  {parasiteList?.results?.map(result => (
                    <th key={result.description}>{result.display_name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className='dr-table-body'>
                {batchReport.batch_scans.map(scan => {
                  const resultsList = []

                  if (scan.results.length === 0 && scan?.scan_state === 'created' && parasiteList) {
                    const emptyParatsites = Array(parasiteList?.results?.length).fill({
                      ...Object.keys(...parasiteList?.results).map(key => ({
                        [key]: '',
                      })),
                    })
                    resultsList.push(...emptyParatsites)
                  } else {
                    resultsList.push(...scan.results)
                  }
                  return (
                    <tr id={`results-table`} style={{backgroundColor: theme.inputcolor, color:theme.textcolor}} key={scan.uri}>
                      <td>{scan.animal_id}</td>
                      <td>{scan.uri.replace(/[^\d.]/g, '')}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {scan?.scan_state?.replaceAll('_', ' ')}
                      </td>
                      {resultsList.map((result, i) => {
                        return <td key={i}>{result?.eggs_per_gram ?? '-'}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {parasites &&
            parasites.map(parasite => {
              const parasiteName = parasite.description[0].toUpperCase() + parasite.description.slice(1)
              return (
                batchReport?.buckets?.[parasite.description] && (
                  <div className='mb-3 parasite-text' key={parasite.description}>
                    <p className='mb-1'>
                      {parasite.description === 'anoplocephala'
                        ? parasite.display_name
                        : parasiteName.replaceAll('_', ' ')}
                    </p>
                    <div className='ml-5 api-text'>{batchReport.buckets[parasite.description]}</div>
                  </div>
                )
              )
            })}
        </div>
      </div>
    )}
      </ThemeContext.Consumer>
    )
  }
}

function myTextArea(batchDetails) {
  if (
    batchDetails == null
  ) {
    return <>
    </>
  } else {
  return (
    <ThemeContext.Consumer>
    {({theme, darkMode}) => (
    <>
      <textarea
      id = 'textArea'
      name="comment"
      className="report-comments"
      type="text"
      wrap='soft'
      placeholder={batchDetails.comment}
      title='Insert Additional Comments'
      style={{backgroundColor: theme.inputcolor, color:theme.textcolor}}
    />
   </> 
       )}
       </ThemeContext.Consumer>
    )
  }
}



const BatchReport = ({fetchBatchReport, editBatchScanComment, fetchBatchDetails, selectRH, batchReport, device, getPracticeData, batchDetails, getPracticeDataIndex, fetchedPracticelabel}) => {


  const [practiceEmblemState, setEmblemCentre] = useState(false);

  useEffect(() => {
    fetchBatchReport(selectRH.batch_id)
  }, [fetchBatchReport, selectRH])

  useEffect(() => {
    fetchBatchDetails((selectRH.batch_id));
  }, [fetchBatchDetails, selectRH]);


  useEffect(() => {
    getPracticeDataIndex();
  }, []);

  const getLogo = useCallback(async () => {
    await getPracticeData()
  }, [getPracticeData])
  useEffect(() => {
    getLogo()
  }, [getLogo])

  const { t } = useTranslation();

  return (
    <>
    <ThemeContext.Consumer>
        {({theme, darkMode}) => (
      <div className='dr-container' >
        <div className='white-background-customers'>
          <div className='table-search-wrapper'>
            <h3 style={{color:theme.textcolor}} className='dr-header'>{t('batchReport')}</h3>
          </div>
        </div>
        <div className='dr-button-row  mt-4'>
          <NavLink to='/results-history'>
            <button type='button' className='dr-register-button dr-back'>
              {t('back')}
            </button>
          </NavLink>


          <div className='container d-flex justify-content-end'>
              <div className='row align-buttons d-flex justify-content-center'>
                <div className='col'>
                  <FaAlignLeft style={{color:theme.textcolor}}  className='align-center' size={'100%'}  onClick={() =>  setEmblemCentre(false)}></FaAlignLeft> 
                </div>
                <div className='col'>
                  <FaAlignRight style={{color:theme.textcolor}}  className='align-right' size={'100%'}  onClick={() => setEmblemCentre(true)}></FaAlignRight> 
                </div>
              </div>
            </div>
           
          {batchReport !== null && batchReport.batch_scans.length !== 0 && (
            <div className='container d-flex justify-content-end' style={{padding:'0px'}}>
              <div className='row rh-print-buttons justify-content-end'>
                <div className=''>
                  {/* <FaCloudDownloadAlt className='' size={'100%'} onClick={this.setEmblemRight}></FaCloudDownloadAlt> */}
                  <button onClick={downloadPDF} type='button' className='dr-register-button dr-print'>
                    {t('📥	↦ PDF')}
                  </button>
                </div>
                <div className=''>
                  <button onClick={window.print} type='button' className='dr-register-button dr-print'>
                    {t('print')}
                  </button>
                </div>
              </div>
            </div>
          )}
   
        </div>
        <div id='detailed-report-wrapper' className='detailed-report-wrapper' style={{ backgroundColor: theme.background, color:theme.textcolor}} >
          <div
            className='dr-print-wrapper section-to-print-detailed-width'
            id='section-to-print'
            style={{ backgroundColor: theme.background}}
          >
            <div className='container'>
              <div id='print-style' className='mx-auto print-styling' style={{ color:theme.textcolor, backgroundColor: theme.background}}>
                  {batchReport === null ? (
                    <p className='individual-report-title'>{t('loading')}...</p>
                  ) : batchReport !== null && batchReport.batch_scans.length !== 0 ? (
                    <>
                    {/* This is the practice emblem  */}
                    {device.myPractice && (
                      <div className='d-flex align-items-end report-logo'>
                        {practiceEmblemState ? (
                            <div className='col'></div>
                        ) : (<></>)}
                          <img src={device.myPractice} alt=''/>
                      </div>
                    )}
                  </>
                  ) : (
                    <p className='individual-report-title'>{t('noReportAvailable')}.</p>
                  )}
            
            <div className='col pr-3' >
             {detailColumn(batchReport, t, fetchedPracticelabel)}
            </div>
          </div>

          <div className='table-search-wrapper'>
              <h4 className='report-header-text'>{t('comments')}</h4>
          </div>
          <h4
              className='mb-4'
              style={{
                paddingTop: '5px',
                paddingBottom: '15px',
                paddingRight: '10px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
                marginRight:'5%'
              }}
              >
          </h4>
          <div className='ml-0'>
          <div className='col-md-8 flex-wrapper-report'>
                <span><b>{t('currentBatchScanComment')}:</b><span id ='current_comment_span'> {typeof(batchDetails) !== object & typeof(batchDetails) !== 'object' ? '' : batchDetails.comment === null ? '' : batchDetails.comment}</span></span>   
          
          </div>
            <div id='no-print'>
              <div className="col-md-8 scan-inputs-properties noprint" >
              <span id='comment_instruction_span' >{typeof(batchDetails) !== object & typeof(batchDetails) !== 'object' ? (t('addComment')) : batchDetails.comment === null ?( t('addComment')) : batchDetails.comment.length > 1 ?( t('editComment')) : t('addComment')}</span> 
                    {myTextArea(batchDetails)}
              </div>
            <div className='col-md-8 noprint'>    
                <button className='dr-submit-button noprint' onClick={() => onSubmit(editBatchScanComment, selectRH.batch_id)}>
                <span id='comment_button_text_span' >{typeof(batchDetails) !== object & typeof(batchDetails) !== 'object' ?  ( t('add')) : batchDetails.comment === null ?( t('add')) : batchDetails.comment.length > 1 ?( t('edit')) : t('add')}</span> 
                </button>
            </div>
          </div>
          </div>
          </div>
        </div>
        </div>
      </div>
            )}
            </ThemeContext.Consumer>
    </>
    
  )
}

function onSubmit(editBatchScanComment, id){
  
  let textArea = document.getElementById('textArea');
  let input = textArea.value
  const editRequest = {
    comment:input 
  };

  
  let comment_instruction_span = document.getElementById('comment_instruction_span');
  let comment_button_text_span = document.getElementById('comment_button_text_span');
  let current_comment_span = document.getElementById('current_comment_span');

  editBatchScanComment(editRequest, id );

  current_comment_span.innerText = input   
  if(comment_instruction_span.innerText === 'Add Comment'){
    comment_instruction_span.innerText = 'Edit Comment'
  }
  if(comment_button_text_span.innerText === 'Add'){
    comment_button_text_span.innerText = 'Edit'
  }
  current_comment_span.innerText = input
};


function downloadPDF(){

  let down_section = document.getElementById("section-to-print")
  let print_style = document.getElementById("print-style")
  // let results_table = document.getElementById("results-table")
  let non_print_section = document.getElementById('no-print')
  let detailed_report_wrapper = document.getElementById('detailed-report-wrapper')
  let batch_id = document.getElementById('br-id').innerText.replace(/\s/g, '');
  let date = document.getElementById('br-date').innerText.replace(/\s/g, '');
  let filename = 'Batch-Report_' + batch_id + '_' + date

  down_section.style.backgroundColor = "white"
  print_style.style.backgroundColor= "white"
  // results_table.style.backgroundColor= "white"
  
  down_section.style.color = "black"
  print_style.style.color= "black"
  // results_table.style.color= "black"

  if(non_print_section){
    non_print_section.setAttribute('hidden', 'true')
    generatePDF(() => down_section, {
      method: "save",
      filename: filename,
      page: { margin: Margin.MEDIUM },
    }); 
    non_print_section.removeAttribute('hidden')
  }

  down_section.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
  print_style.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
  down_section.style.color = detailed_report_wrapper.style.color
  print_style.style.color= detailed_report_wrapper.style.color
}

const mapStateToProps = state => ({
  selectRH: state.selectRH,
  batchReport: state.batchDetails.batchReport,
  batchDetails: state.batchDetails.batch_details,
  
  fetchedPracticelabel: state.getPracticeDataIndex,

  fetchedScan: state.scan.scan,
  device: state.device,
  practiceEmblemState: state.practiceEmblemState,
})
 
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchBatchReport,
      fetchBatchDetails,
      getPracticeData,
      editBatchScanComment,
      getPracticeDataIndex,
      
    },
    dispatch
  )
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BatchReport))
