import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import DataTable from 'react-data-table-component'
import { withRouter, NavLink, Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
// import TitleCase from 'react-title-case'
import history from '../../history'
import { ThemeContext } from '../../context/theme-context';
import { resultsHistoryPageHandler } from "../../redux/actions/tablePaginationActions"
import {
  fetchResults,
  selectResultHistory,
  fetchResultHistoryDetails,
  fetchBatchDetails,
} from '../../redux/actions/fetchResultHistoryActions'
import { withTranslation } from 'react-i18next'
import { Visibility } from '@material-ui/icons'
import FilterDataTable from "../../components/FilterDataTable";

const initialState = {
  dropDownText: [{ query:"", plain_text:"" }],
  species_selected: [{ species:"", plain_text:"" }],
  operators: [{ operator:"", plain_text:"" }],
  showDetailPreview: false,
  showBatchPreview: false,
  selectedRow: {},
  selectedRowID: null,
  loading: false,
  page: 1,
  filteredData: [],
}

class ResultsHistory extends Component {
  constructor() {
    super()
    this.number_per_page = 10;
    this.column_sort_field = false
    this.errorList = []
    this.speciesDict = JSON.parse(localStorage.getItem('species'))
    this.scan_product_dict = JSON.parse(localStorage.getItem('scanProductDict'))
    // this.filterHeadingArray = [] // used to quickly check what filter headings have been selected
    this.filterTextQuery = ''
    this.state = initialState
  }

  componentDidMount() {

  }

  rowExpanded = row => {
    return(
      <ThemeContext.Consumer>
      {({ theme }) => (
    <div className='' style={{backgroundColor: theme.textcolor, color: theme.textcolor}}>
     {this.state.showDetailPreview ? (
              <div className='dpc-sdp-wrapper' style={{backgroundColor: theme.foreground, color: theme.textcolor}}>
                <div className= {window.innerWidth < 768 ? 'col pt-2 pb-1' : 'col-12 col-md-4 pt-4 pb-4' }>
                  <div className='dpc-wrapper' style={{backgroundColor: theme.background, color: theme.textcolor}}>
                    {this.state.loading ? 'loading' : this.detailPreviewColumn()}
                  </div>
                </div>
                <div className= {window.innerWidth < 768 ? 'col pt-1 pb-2' : 'col-12 col-md-4 pt-4 pb-4' }>
                  <div className='dpc-wrapper'  style={{backgroundColor: theme.background, color: theme.textcolor}} >
                    {this.state.loading ? 'loading' : this.detailPreviewColumnParasitesResults()}
                  </div>
                </div>
              </div>
            ) : this.state.showBatchPreview ? (
              <div className='dpc-sdp-wrapper_batch' style={{backgroundColor: theme.background, color: theme.textcolor}} >
                <div className= {'p-6' }>
                  <div className='dpc-wrapper_batch' style={{backgroundColor: theme.background, color: theme.textcolor}} >
                    {this.state.loading ? 'loading' : this.batchPreviewColumn()}
                  </div>
                </div>
              </div>
            ) : (
              <></>
      )}
      </div>
      )}
      </ThemeContext.Consumer>
    )
  }

  renderResultsHistory() {
    if (this.props.history !== null) {
      return this.props.history.history.map(history => {
        if (history.description === 'None') {
          history.description = ''
        }
        if (history.animal_id === 'None') {
          history.animal_id = ''
        }
        if (history.scan_product === 'None') {
          history.scan_product = ''
        }
        if (history.owner_name === 'None') {
          history.owner_name = ''
        }
        if (history.description === 'None') {
          history.description = ''
        }
        
        return {
          date: new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(history.created.$date),
          animal_owner: history.owner_name,
          scan_product: history.scan_product === null || history.scan_product === undefined ? 'none' : history.scan_product.$ref,
          animal_id: history.animal_id,
          description: history.description,
          verified: history.verified.toString(),
          scan_id: history.$uri.replace(/[^\d.]/g, ''),
          batch_id: history.parent_batch === null ? 'none' : history.parent_batch.$ref.replace(/[^\d.]/g, ''),
        }
      })
    }
  }

  limitationLines = () => {
    return (
      this.props.rhDetails.details.map(detail => {
      const parasiteName = detail.description[0].toUpperCase() + detail.description.slice(1)
        detail.buckets !== '' && (
          <div className='mb-3 parasite-text' key={detail.description}>
            <p className='mb-1'>
              {detail.description === 'anoplocephala'
                ? detail.display_name
                : parasiteName.replaceAll('_', ' ')}
            </p>
            <div className='api-text ml-5'>{detail.buckets}</div>
          </div>
        )
    }))
  }

  detailPreviewColumn = () => {
    if (this.props.rhDetails.details[0] !== undefined && this.state.loading === false) {
      return (
        <>
          <span className='row dpc-header justify-content-center'> {this.props.t('details')}</span>
          {/* </div> */}
          <div className='row'>
            <hr
              style={{
                width: '100%',
              }}
            />
          </div>
          <div className='row'>
            {this.props.t('date')}: <span className='dpc-value'>{this.state.selectedRow.row.date}</span>
          </div>
          <div className='row dpc-details-row'>
            {this.props.t('ownerReference')}: {' '}
            <span className='dpc-value'>{this.state.selectedRow.row.animal_owner}</span>
          </div>
          <div className='row'>
            {this.props.t('animalName')}: {' '}
            <span className={this.state.selectedRow.row.animal_id ? this.state.selectedRow.row.animal_id.length > 40 & this.state.selectedRow.row.animal_id.length <= 60 ? 'dpc-value_description_mid_length' : this.state.selectedRow.row.animal_id.length > 60 ? 'dpc-value_description' : 'dpc-value' : ''}>{this.state.selectedRow.row.animal_id}</span>
          </div>
          <div className='row'>
            {this.props.t('species')}: {' '}
            <span
              style={{
                textTransform: 'capitalize',
              }}
              className='dpc-value'
            >
              
              {localStorage.getItem('scanProductDict') ? JSON.parse(localStorage.getItem('scanProductDict'))[this.state.selectedRow.row.scan_product]:''}
              { } </span>
          </div>
          <div className='row'>
            {this.props.t('description')}: {' '}
            <span className={this.state.selectedRow.row.description ? this.state.selectedRow.row.description.length > 40 & this.state.selectedRow.row.description.length <= 60 ? 'dpc-value_description_mid_length' : this.state.selectedRow.row.description.length > 60 ? 'dpc-value_description' : 'dpc-value' : ''}>{this.state.selectedRow.row.description}</span>

          </div>


          <div className='row justify-content-center'>
                <hr className='dpc-lines-before-after'
                  style={{
                    width: '100%',
                  }}
                />
            </div>

        </>
      )
    } else {
      return (
        <>
          <div> {this.props.t('noReportAvailable')}</div>
        </>
      )
    }
  }


  detailPreviewColumnParasitesResults = () => {

    if (this.props.rhDetails.details[0] !== undefined && this.state.loading === false) {
      return (
        <ThemeContext.Consumer>
          {({ theme }) => (
            <>
              <span className='row dpc-header justify-content-center'> {this.props.t('parasites')} {' & '}{this.props.t('resultsForInTable')}</span>
              {/* </div> */}
              <div className='row'>
                <hr className='dpc-lines-before-after'
                  style={{
                    width: '100%',
                  }}
                />
              </div>

              <div className='dpc-details container'>
                {this.props.rhDetails.details.map((item, i) => (
                  <div key={i}>
                    <div className='para-table row'>
                    <div className='col parasite-details'>
                      <Link
                        to={{
                          pathname: '/image-gallery',
                          state: { parasite: item.description },
                        }}
                      >
                        <div style={{color:theme.textcolor}} className='parasite-description'>{item.display_name.replace('_', ' ') + ': '}</div>
                      </Link>
                    </div>
                      <div className='parasite-details flex flex-col'>
                        {item.eggs_per_gram > 0 ? <strong style={{fontSize:'14.5px'}}>{item.eggs_per_gram}</strong>: item.eggs_per_gram}
                      </div>
                    </div>
                  </div>
                ))}

              </div>
              
              <div> {/*Create false rows to even out left and right sides for below rows. This aint right chief */}
              {/* {diff > 0 ? Array.from({ length: diff }, (_, key)  => (
                <div style={{visibility: 'hidden'}}className='para-table row'>-</div>
              )) :''} */}
              </div>
              <div className='row justify-content-center'>
                <hr className='dpc-lines-before-after'
                  style={{
                    width: '100%',
                  }}
                />
              </div>


              {this.state.loading ? 'loading' : this.props.rhDetails.details.map(detail => {
                        const parasiteName = detail.description[0].toUpperCase() + detail.description.slice(1)
                        return (
                          detail.buckets !== '' && (
                            <div className='para-table row' key={detail.description}>
                              <div className='col-4' key={detail}>
                                {detail.description === 'anoplocephala'
                                ? detail.display_name + ':'
                                : parasiteName.replaceAll('_', ' ') + ':'}
                                </div>
                                <div className='col-8' key={detail.description}>
                                  {detail.buckets}
                              </div>
                            </div>
                          )
                        )
                        
              })}

            <div className='row justify-content-center'>
                <hr className='dpc-lines-before-after'
                  style={{
                    width: '100%',
                  }}
                />
            </div>

            </>
          )}
        </ThemeContext.Consumer>
      )

    } else {
      return (
        <>
          <div className='d-flex justify-content-center'> {this.props.t('noReportAvailable')}</div>
        </>
      )
    }
  }


  batchPreviewColumn = () => {
    if (
      this.props.batchDetails.batch_details !== undefined &&
      this.state.loading === false &&
      this.props.batchDetails.batch_details !== null
    ) {
      return (
        <>
          <div className='container m-6 pb-4' >
            <div className='row'>
              <hr className=''
                style={{
                  width: '100%',
                }}
              />
            </div>
            {/* <div className='col dpc-header'> */}
            <span className='row dpc-header justify-content-center'> {this.props.t('batchDetails')}</span>
            {/* </div> */}
            <div className='row'>
              <hr className=''
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div className='row'>
              <div className='col'>
                {this.props.t('batchName')}:
              </div>
              <div className='col'>
                {this.props.batchDetails.batch_details.description}
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                {this.props.t('batchComment')}:
              </div>
              <div className='col'>
                {this.props.batchDetails.batch_details.comment}
              </div>
            </div>
            <div className='row pb-1'>
              <div className='col'>
                <span >{this.props.t('batchID')}{' '}</span>
              </div>
              <div className='col'>
                {this.props.batchDetails.batch_details.$uri.replace(/[^\d.]/g, '')}
              </div>
            </div>
            <div className='row '>
              <hr className=''
                style={{
                  width: '100%',
                }}
              />
            </div>
            <div className='row justify-content-center'>
              <div className='col-12 justify-content-center'>
                <strong className=''>{this.props.t('animalNames')}:</strong>
              </div>
              <div className='row'>
                <hr className=''
                  style={{
                    width: '1%',
                  }}
                />
              </div>
            </div>
            {this.props.batchDetails.batch_details.batch_scans.map(option => (
              <div className='row' key={option.$uri + option.animal_id}>

                <div className='col-12 justify-content-center'>
                  {option.animal_id}
                </div>
              </div>
            ))}
            <div className='row pt-3'>
              <hr className=''
                style={{
                  width: '100%',
                }}
              />
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='talign-center justify-content-center'>{this.props.t('noBatchAvailable')}</div>
        </>
      )
    }
  }

  detailPreview = async row => {
    this.setState(prevState => ({
      showDetailPreview: true,
      showBatchPreview: false,
      loading: true,
      selectedRow: {
        ...prevState.selectedRow,
        row,
      },
    }))

    await this.props.fetchResultHistoryDetails(row.scan_id)
    this.props.selectResultHistory(row.scan_id, row.batch_id)

    this.setState({
      loading: false,
    })
  }

  batchPreview = async row => {
    this.setState(prevState => ({
      showBatchPreview: true,
      showDetailPreview: false,
      loading: true,
      selectedRow: {
        ...prevState.selectedRow,
        row,
      },
    }))

    await this.props.fetchBatchDetails(row.batch_id)

    this.props.selectResultHistory(row.scan_id, row.batch_id)


    this.setState({
      loading: false,
    })
  }

  selectRow = async row => {
    this.setState(prevState => ({
      selectedRow: {
        ...prevState.selectedRow,
        row,
      },
    }))
  }

  actionOptions = row => {
    return (
      <div>
        <Dropdown.Item
          onClick={() => {
            this.detailPreview(row)
          }}
        >
          <div className='table-edit'>{this.props.t('scanDetails')}</div>
        </Dropdown.Item>
        {row.batch_id !== 'none' && (
          <Dropdown.Item
            onClick={() => {
              this.batchPreview(row)
            }}
          >
            <div className='table-delete'>{this.props.t('batchDetails')}</div>
          </Dropdown.Item>
        )}
      </div>
    )
  }


  handleSort = async (column, sortDirection) => {
    this.column_sort_field = {
    }
    if (sortDirection === 'asc') {
      this.column_sort_field[column.sortField] = false
    }
    else {
      this.column_sort_field[column.sortField] = true
    }
    // this.props.fetchResults(this.props.resultsHistoryTablePage, this.filterTextQuery, this.column_sort_field, this.number_per_page)
  };


  returnSpecies(speciesUri) {
    if (this.speciesDict) {
      return this.speciesDict[speciesUri]
    }
    return ''
  }

  returnSpecies_fromScanProduct(speciesUri) {
    if (this.scan_product_dict) {
      return this.scan_product_dict[speciesUri]
    }
    return ''
  }

  renderButtons(){
    return (
      <div className='rh-button-row '>
      <button
        type='button'
        onClick={
          this.state.showDetailPreview === true && this.state.loading === false
            ? () => history.push('/detailed-report')
            : undefined
        }
        className={
          this.state.showDetailPreview === true && this.state.loading === false
            ? 'rh-register-button rh-detailed-selected'
            : 'rh-register-button'
        }
      >
        {this.props.t('scanReport')}
      </button>
      <button
        type='button'
        onClick={
          (this.state.showDetailPreview === true || this.state.showBatchPreview === true) &&
            this.props.selectRH.batch_id !== 'none' &&
            this.state.loading === false
            ? () => history.push('/batch-report')
            : undefined
        }
        className={
          (this.state.showDetailPreview === true || this.state.showBatchPreview === true) &&
            this.props.selectRH.batch_id !== 'none' &&
            this.state.loading === false
            ? 'rh-register-button rh-detailed-selected'
            : 'rh-register-button'
        }
      >
        {this.props.t('batchReport')}
      </button>
        <button
          type='button'
          onClick={
            this.state.showDetailPreview === true && this.state.loading === false
              ? () => history.push('/image-gallery')
              : undefined
          }
          className={
            this.state.showDetailPreview === true && this.state.loading === false
              ? 'rh-register-button rh-images-selected'
              : 'rh-register-button'
          }
        >
          {this.props.t('images')}
        </button>
    </div>
    )
  }

  render() {
    let conditionalRowStyles
    if (this.state.selectedRow.row !== undefined) {
      conditionalRowStyles = [
        {
          when: row => row.scan_id === this.state.selectedRow.row.scan_id,
          style: {
            color: ThemeContext.Consumer._currentValue.theme.blue,
          },
        },
      ]
    }

    const col_width = "100%"
    const columns = [
      {
        name: this.props.t('date'),
        selector: 'date',
        grow: 1,
        sortable: true,
        sortField: "created",
        filterTerm:'created',
        type:'date',
      },
      {
        name: this.props.t('ownerReference'),
        selector: 'animal_owner',
        grow: 1,
        center: true,
        // sortable: true,
        sortField: "owner_name",
        maxWidth: col_width,
        hide: 'md',
        filterTerm:'owner_name',
        type:'str',
      },
      {
        name: this.props.t('animalName'),
        selector: 'animal_id',
        grow: 1,
        center: true,
        // sortable: true,
        sortField: "animal_id",
        maxWidth: col_width,
        hide: 'sm',
        filterTerm:'animal_id',
        type:'str',
      },
      {
        name: this.props.t('species'),
        selector: 'scan_product',
        grow: 1,
        center: true,
        cell: row => this.returnSpecies_fromScanProduct(row.scan_product),
        hide: 'md',
        filterTerm:'scan_product',
        type:'dropdown',
      },
      {
        name: this.props.t('scanID'),
        selector: 'scan_id',
        grow: 1,
        center: true,
        // sortable: true,
        sortField: '$uri',
        maxWidth: col_width,
        filterTerm:'$uri',
        type:'int',
      },
      {
        name: this.props.t('batchID'),
        selector: 'batch_id',
        grow: 1,
        center: true,
        maxWidth: col_width,
        // sortable: true,
        sortField: "parent_batch",
        filterTerm:'parent_batch',
        type:'NotInUse',
      },
      {
        name: this.props.t('details'),
        type:'details',
        cell: row => (
          <Dropdown drop='right' title='rh-dropdown' className=''>
            <DropdownButton title='' drop='right' className='edit-table-wrapper'>
              {this.actionOptions(row)}
            </DropdownButton>
          </Dropdown>
        ),
      },
    ]

    let customStyles
    customStyles = {
      title: {
        style: {
          fontWeight: '900',
          // backgroundColor:ThemeContext.Consumer._currentValue.theme.foreground ,
        }
      },
      rows: {
        style: {
          // color:ThemeContext.Consumer._currentValue.theme.textcolor,
          fontFamily: 'sans-serif',
          fontSize: '14px',
          minHeight: '38px',
          maxHeight: '40px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.background,

        }
      },
      headCells: {
        style: {
          // color:ThemeContext.Consumer._currentValue.theme.textcolor,
          fontFamily: 'sans-serif',
          fontSize: '14px',
          minHeight: '38px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.foreground,
        },
      },
      cells: {
        style: {
          fontSize: '15px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.background,
          // background: '#73AD21';
        },
      },

    }

    return (
      <ThemeContext.Consumer>
        {({ theme, darkMode }) => (
          <div>
            <div className='white-background-customers' style={{background:theme.foreground, color: theme.textcolor }}>
              <div className='table-search-wrapper'>
                <h3 className='header-worklist' style={{ color: theme.textcolor }}>{this.props.t('resultsHistory')}</h3>
              </div>
              <FilterDataTable 
                columns={columns} 
                conditionalRowStyles={conditionalRowStyles} 
                fetchTableData={this.props.fetchResults} 
                pageHandler={this.props.resultsHistoryPageHandler} 
                onRowClicked={this.detailPreview}
                customStyles={customStyles}
                expandOnRowClicked={true}
                expandableRowsComponent={this.rowExpanded(this.props)}
                expandableRowExpanded={(row) => (row == this.state.selectedRow.row)}
                expandableRowsHideExpander={true}
                expandableRows={true}
                renderList={this.renderResultsHistory()}
                workList={this.props.history}
                status={this.props.status}
                extraButtons={this.renderButtons()}
                paginationPage={this.props.resultsHistoryTablePage}
                handleSort={this.handleSort}
              >
              </FilterDataTable>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
  history: state.resultsHistory.history,
  selectRH: state.selectRH,
  rhDetails: state.rhDetails,
  batchDetails: state.batchDetails,
  count: state.resultsHistory.count,
  status:state.resultsHistory.status,
  resultsHistoryTablePage: state.resultsHistoryTablePage.page,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchResults,
      selectResultHistory,
      fetchResultHistoryDetails,
      fetchBatchDetails,
      resultsHistoryPageHandler,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(ResultsHistory))
