import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SupportMaterial from '../../../components/SupportMaterial'
import ThumbWithText from '../../../components/ThumbWithText'
import {ThemeContext} from '../../../context/theme-context';

const FecScanHelp = ()  => {
  const { t } = useTranslation()

  var eq = false
  var la = false
  var ca = false
  if(localStorage.getItem('practiceDocumentation')){var cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
  eq = cache['eq'];la = cache['la'];ca = cache['ca']
  
  return (
    <ThemeContext.Consumer>
    {({ theme, darkMode }) => (
    <div className='dashboard-container cow-bg' style={{ height: 'auto', background: theme.foreground, color: theme.textcolor}}>
      <div className='white-background about-us-container mx-auto pb-4 '
        style={{
          background: theme.foreground,
          padding: '0px 25px 25px',
          color: theme.textcolor,
        }}
      >
        <h3 className='' style={{ paddingTop: '120px', color: theme.textcolor }}>
          {t('helpwithFECScan')}
        </h3>
        <div className='' style={{ paddingTop: '20px', color: theme.textcolor }}>
        <p>
            {t('thisIsAhelpPageFEC')}
            <br /> <br />
            {t('pleaseFollowBelow')}
            <br /> <br />
            {t('ovacyteSupportIsHereToHelp')}{' '}
            <Link to='/contact'>
              <u style={{ color: '#009FEE' }}>{t('contactUs')}</u>
            </Link>  
          </p>
        </div>

        {/* <div
          className='row'
          style={{
            color: '#212529',
            fontSize: '1rem',
            fontWeight: '400',
            margin: 'auto',
          }}
        > */}

        <div
          className='row'
          style={{
            color: '#212529',
            fontSize: '1rem',
            fontWeight: '400',
            margin: 'auto',
            color: theme.textcolor,
          }}
        >
          <div className='col-12 col-md-10 mr-5 pl-0'>
            <h4
              className='mb-4'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
            >

            </h4>

            <div className='ThumbWithText' style={{ paddingTop: '12px' }}>
            <ThumbWithText explainText='TestVid.mp4' url='FEC_Sample_Prep.m4v' thumbnail='FEC_Sample_Prep_Graphic.png'>
            </ThumbWithText>
            <br></br>
            {/* <p><b>Tutorial Video </b></p>
            To <b>start </b> a Fec scan follow these steps */}
            </div>  

            <div className='fecHowToList' >
            <ol>
                <li>{t('placeEmptySamplePotOntoTheScaleAndPressT_toZeroTheScale')}</li>
                <li>{t('nowWeighOut3gOfFaecalMatterTakenFromYourOriginalSamplePot')}</li>
                <li>{t('fecPointThreeAddFloatationFluid')}</li>
                <li>{t('fecPointFourMixSample')} </li>
                <li>{t('fecPointFivePourLiquid')}</li>
                <li>{t('fecPointSixSwirlLiquid')}</li>
                <li>{t('fecPointSevenInsertLiquid')}</li>
                <li>{t('fecPointEightPooledSamples')}<a href={t('pooledSampleInstructionsDownload')} target='_blank'><u style={{color: theme.textcolor}}>{t('pooledSampleInstructions')}</u></a></li>
            </ol>
            </div>      
            <div>
              <br></br>
              <br></br>
            </div>

            <h4
              className='mb-4'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
            >
            {t('browseHelpTopics')} 
            </h4>
            <div className='helpLinks'> 
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/fluke-scan-help' hidden={!eq}>
              <div style={{color: theme.textcolor}}>{t('helpwithFlukeScan')}</div>
              </Link>
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/ca-scan-help' hidden={!ca}>
              <div style={{color: theme.textcolor}}> {t('helpWithCaScan')}</div>
              </Link>
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/support'>
              <div style={{color: theme.textcolor}}>{t('frequentlyAskedQuestions')}</div>
              </Link>
              <br></br>
            </div>
          </div>
            {/* <SupportMaterial /> */}
        </div>
      </div>
    </div>
      )}
      </ThemeContext.Consumer>

  )
}

const mapStateToProps = state => ({
  security: state.security,
  practiceDocumentation: state.practiceDocumentation,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
    }, 
    dispatch
  )
}


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FecScanHelp)
