
import React from 'react'
export const themes = {
    light: {
      foreground: '#f2f2f2',
      background: '#ffffff',
      blue: '#00a0ee',
      textcolor: '#343334',
      inputcolor: '#ffffff',
      image: '#f2f2f2c0'
    },
    dark: {
      foreground: '#343535',
      background: '#222222',
      blue: '#00a0ee',
      textcolor: '#eaeaea',
      inputcolor: '#3D3D3D',
      image:'#343535'
      
    },
  };
  
export const ThemeContext = React.createContext({
  theme: themes.light,
  darkMode: false,
  toggleTheme: () => {},
});