import {
    FETCH_PRACTICE_DOCUMENTATION,
  } from "./types";
  import {backInstance} from "../../config";

  function findProductDocList(practiceDocumentation){
    var docList = []
    let eq = false;
    let ca = false;
    let la = false;
    
    if(typeof practiceDocumentation === 'object' &&
    practiceDocumentation !== null) 
    {
        for(let i = 0; i < practiceDocumentation.length; i++){
            docList.push(practiceDocumentation[i]['label'])
        }

        if(docList.includes('LA & EQ docs')){
            eq = true;
            la = true;
        }

        if(docList.includes('CA docs')){
            ca = true;
        }
        
        return {'ca':ca, 'eq':eq,'la':la}
        }
    else{
        return {'ca':ca, 'eq':eq,'la':la}
    }
  }

  export const fetchPracticeDocumentation = async () => {
      try {
      const res = await backInstance.get(`/practice/documentation`)
      const practiceDocumentation = findProductDocList(res.data)
      localStorage.setItem('practiceDocumentation', JSON.stringify(practiceDocumentation))
    } catch (error) {
      console.log('error with fetchPracticeDocumentation', error)
    }
  }
