import ResetPassword from './ResetPassword'
import DeviceSettings from '../containers/User/DeviceSettings'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../context/theme-context'
function SupportMaterial(practiceDocumentation){


    // const practiceDocumentation = params['practiceDocumentation']
    const { t } = useTranslation()
    var eq = false
    var la = false
    var ca = false
    if(localStorage.getItem('practiceDocumentation')){var cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
    eq = cache['eq'];la = cache['la'];ca = cache['ca']

    return(
        <ThemeContext.Consumer>
        {({theme}) => (
        <div className='col-12 col-md-3' style={{color: theme.textcolor}}>
        <h4
        className='mb-4'
        style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #4472C4',
            fontSize: '20px',
        }}
        >
        {t('supportMaterial')}
        </h4>
        <ul className='pl-4'>
        <li className='mb-2'>{t('installingOvacyte')}</li>
        <li className='mb-2'>{t('usingOvacyteFaecalAnalyzer')}</li>
        <li  >
            <a href='/PrivacyPolicy.pdf' target='_blank'>
                <u style={{color: theme.textcolor}}> {t('ovacytePrivacyPolicy')}</u>
            </a>
        </li>

        <li hidden={!eq} >
            <a href={t('userManualDownloadEQ')} target='_blank'>
                <u style={{color: theme.textcolor}}>{t('usermanualEq')}</u>
            </a>
        </li>
        <li hidden={true} > {/* This needs to be changed back once we have the files */}
            <a href={t('userManualDownloadCA')} target='_blank'>
                <u>{t('usermanualCa')}</u>
            </a>
        </li>
        </ul>
        
        {/* Company Logo */}
        <div className=''>
        <h4
            className='mb-4'
            style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #4472C4',
            fontSize: '20px',
            }}
        >
            {t('companyLogo')}
        </h4>
        <DeviceSettings />
        </div>
        {/* Password Reset */}
        <div className=''>
        <h4
            className='mb-4'
            style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #4472C4',
            fontSize: '20px',
            }}
        >
            {t('resetPassword')}
        </h4>
        <ResetPassword />
        </div>
        </div>
        )}
    </ThemeContext.Consumer>
    );
    
}
  
export default SupportMaterial
