export const translationFr = {
  //Login
  loginToOvacyte: 'Se connecter à OvaCyte',
  username: "Nom d'utilisateur",
  password: 'Mot de passe',
  logIn: 'Connexion',
  //Singup
  insertUsername: "Saisir le nom d'utilisateur",
  insertPassword: 'Saisir le mot de passe',
  login: 'Connexion',
  usernameMustBeAtLeast6Characters: "Le nom d'utilisateur doit comporter au moins 6 caractères",
  emailMustBeAtLeast6Characters: "L'adresse e-mail doit comporter au moins 6 caractères",
  notAValidEmail: 'Adresse e-mail non valide',
  passwordMustBeAtLeast8Characters: 'Le mot de passe doit comporter au moins 8 caractères',
  createAccount: 'Créer un compte',
  alreadyHaveAnAccount: 'Vous avez déjà un compte?',
  email: 'Adresse e-mail',
  insertAddress: "Saisir l'adresse e-mail",
  signUp: "S'inscrire",
  //ForgotPassword
  makeSureUpdateAnalyser:"Si vous sélectionnez un type de test Fluke, vous DEVEZ d'abord vous assurer que votre analyseur a été mis à jour avec la dernière version du logiciel.",

  emailMustMoreThan3Characters: "L'adresse e-mail doit comporter plus de 3 caractères",
  forgotPassword: 'Mot de passe oublié?',
  enterYourEmailAddressToResetYourPassword:
    'Saisissez votre adresse e-mail pour réinitialiser votre mot de passe.',
  enterEmailAddress: "Saisir l'adresse e-mail",
  submit: 'Soumettre',
  back: 'Retour',
  //VerifyEmail
  verifyYourEmail: 'Vérifiez votre adresse e-mail',
  anEmailHasBeenSentTo: 'Un e-mail a été envoyé à',
  withALinkToVerifyYourAccount: 'avec un lien pour vérifier votre compte',
  ifThisIsNotYourEmailPlease: "Si ce n'est pas votre courriel, veuillez",
  updateIt: 'La mettre à jour',
  ifYouHaveNotReceivedAnEmailAfterAFewMinutesPleaseCheckYourSpamFolder:
    "Si vous n'avez pas reçu d'e-mail dans les quelques minutes qui suivent, veuillez vérifier votre dossier «Spam».",
  resendEmail: "Renvoyer l'e-mail",
  contactSupport: "Contacter l'assistance",
  //DeviceSettings
  chooseFile: 'Choisir un fichier',
  noFileChosen: 'Aucun fichier choisi',
  uploading: 'Téléchargement...',
  imageUploadSuccess: "L'image a été téléchargée avec succès",
  imageUploadFailed: "Échec du téléchargement de l'image",
  imageUploadReq: 'Images (JPG, JPEG, PNG)',
  imageSize: "La taille de l'image ne doit pas dépasser 1MB",
  deviceSettings: "Paramètres de l'appareil",
  description: 'Description',
  location: 'Emplacement',
  comms: 'Communications',
  active: 'Actif',
  usage: 'Consommation',
  letterhead: 'En-tête',
  info: 'Infos',
  currentBatchScanComment: 'Commentaire actuel',
  commentHasBeenChangedSuccessfully: 'Le commentaire a été modifié avec succès',
  currentScanComment: 'Commentaire actuel',
  commentNotAcceptedPleaseTryAgain: 'Commentaire non accepté, veuillez réessayer',
  editComment: 'Modifier le commentaire',
  addComment: 'Ajouter un commentaire',
  add: 'Ajouter',
  companyLogo: "Logo de l'entreprise",
  hardware: 'Matériel',
  qrCode: 'Code QR',
  security: 'Sécurité',
  network: 'Réseau',
  serviceDue: 'Entretien à effectuer',
  update: 'Mettre à jour',
  //CreateScan
  aSingleSpeciesMustBeSelectedToSubmitTheScan:
    'Une seule espèce doit être sélectionnée pour soumettre le scan',
  pleaseEnterOwnerReference: 'Veuillez saisir la nom du propriétaire',
  pleaseEnterBatchName: 'Veuillez saisir le nom du lot',
  pleaseEnterAnimalName: "Veuillez saisir le nom de l'animal",
  animalName: "Nom de l'animal",
  animalNames:"Noms d'animaux",
  batchComment:"Commentaire sur le lot",
  insertName: 'Saisir le nom',
  required: 'obligatoire',
  insertAdditionalComments: 'Saisir des commentaires supplémentaires',
  editScan: 'Modifier le scan',
  createScan: 'Créer un scan',
  ownerReference: 'Nom du propriétaire',
  batchName: 'Nom du lot',
  species: 'Espèce',
  equine: 'Equine',
  bovine: 'Bovine',
  ovine: 'Ovine',
  addAnotherAnimal: 'Ajouter un autre animal',
  saveScan: 'Enregistrer le scan',
  testType: 'Type de test',
  //Contact Form
  fullName: 'Nom complet',
  reasonForContact: 'Motif du contact',
  choose: 'Sélectionner',
  customerSupportQuery: "Demande d'assistance client",
  scanRelatedQuery: 'Demande liée au scan',
  orderQuery: 'Demande liée à une commande',
  billingQuery: 'Demande liée à une facture',
  complaint: 'Réclamation',
  dataProtection: 'Protection des données',
  detailsForYourContact: 'Coordonnées de votre contact',
  //ContactUsOption
  sendNow: 'Envoyer maintenant',
  helpSupport: 'Aide et soutien',
  askSupportAQuestion: "Poser une question à l'assistance",
  submitADataProtectionRequest: 'Soumettre une demande/question relative à la protection des donné',
  salesSupport: 'Assistance commerciale',
  raiseAQueryWithSales: 'Poser une question sur des ventes/commandes',
  generalQuery: "Question d'ordre général",
  askAGeneralProductionQuestion: "Poser une question d'ordre général sur la production",
  //ErrorModal
  badRequest: 'Mauvaise requête',
  loginFailed: 'Échec de connexion',
  usernameOrPasswordIsIncorrectPleaseTryAgain:
    "Nom d'utilisateur ou mot de passe incorrect. Veuillez essayer à nouveau.",
  noAccessAllowed: 'Accès non autorisé',
  theResourceDoesNotExist: "La ressource n'existe pas",
  connectionTimeout: 'Expiration de la connexion',
  close: 'Fermer',
  //Sidemenu
  pendingScans: 'Scans en cours',
  resultsHistory: 'Historique de résultats',
  aboutUs: 'À propos de nous',
  support: 'Assistance',
  contactUs: 'Nous contacter',
  logout: 'Déconnexion',
  //submenu
  FECPreperation: 'Préparation FEC',
  FECPrep: 'Prep FEC', 
  FlukePreperation: 'Préparation Fluke', 
  FlukePrep: 'Prep Fluke', 
  CaPreperation: 'Préparation CA', 
  CaPrep: 'Prep CA',
  //About
  aboutP1:
    "En tant qu'entreprise technologique internationale innovante, nous sommes engagés dans le développement d'une nouvelle méthode de référence en parasitologie numérique au point de service pour un traitement ciblé",
  aboutP2a:
    "Nous avons achevé l'élaboration d'une plateforme technologique automatisée abordable et précise, utilisée pour identifier, analyser rapidement et de manière efficace les œufs de parasites les plus critiques chez les bovins, ovins et équidé.",
  aboutP2b:
    "La plateforme révolutionnaire de récupération, traitement et analyse des œufs de parasites OvaCyte™' utilise une cassette à usage unique qui permet une nouvelle récupération des œufs avec une tension superficielle (STER) géométrique pour la flottaison et la concentration des œufs de parasites.",
  aboutP3a:
    "La plateforme technologique brevetée utilise le pouvoir de l'intelligence artificielle en fournissant aux utilisateurs des informations de diagnostique cruciales, en temps réel. Notre technologie de pointe traite et analyse pour les vétérinaires des échantillons de matière fécale en quelques minutes leur permettant ainsi de prendre des décisions basées sur des données fiables, éclairées et rapides concernant un traitement ciblé.",
  aboutP3b:
    "Lors de l'analyse des échantillons, notre technologie utilise une microscopie numérique pour obtenir de multiples images numérisées.",
  aboutP3c:
    "Notre IA fournit des données de prévalence géographique précieuses. Plus les utilisateurs ont recours à notre technologie, plus les données sont précises, fournissant ainsi aux professionnels de santé et vétérinaires du monde entier des connaissances inestimables qui peuvent être partagées et utilisées pour faire avancer la recherche scientifique sur la santé et le bien-être animal.",
  aboutP3d:
    "Nous croyons qu'OvaCyte™ et d'autres technologies que nous développons ont la capacité d'influencer de manière positive les industries vétérinaire, agricole, médicale et environnementale.",
  aboutP4: 'Notre mission est de devenir le leader mondial en parasitologie numérique.',
  //BatchReport
  selectaSpecies: 'Sélectionner une espèce',
  batchReport: 'Rapport de lot',
  batchDescription: 'Description du lot',
  batchID: 'Description du lot',
  testDate: "Date d'essai",
  results: 'RÉSULTATS',
  scanID: "Numéro d'identification du scan",
  scanState: 'État du scan',
  epg: 'OPG',
  eggsPerGram: 'Œufs par gramme',
  noEggsDetected: 'Aucun œuf détecté',
  print: 'Imprimer',
  loading: 'Chargement',
  noReportAvailable: 'Aucun rapport disponible',
  comments: 'Commentaires',
  //Contact
  welcomeToOvaCyteSupport: "Bienvenue à l'assistance d'OvaCyte",
  contactP1:
    "Communiquer avec nos clients est l'un des aspects les plus importants de notre travail, que ce soit d'entendre ce que vous avez à dire ou vous aider à trouver une solution.",
  contactP2:
    "Si vous avez besoin d'aide, n'hésitez pas à nous contacter à l'aide des options contacter nous ci-dessous ou de nos coordonnées à droite.",
  talkToUs: 'Discuter avec nous',
  ovaCyteSupport: 'Assistance OvaCyte',
  hebronIndustrialEstate: 'Hebron Industrial Estate',
  hebronRd: 'Hebron Rd',
  kilkenny: 'Kilkenny',
  ireland: 'Ireland',
  //DetailedSupport
  totalVolumeAnalysed: 'Volume total analysé',
  individualReport: 'Rapport individuel',  
  summary: 'Résumé',
  //FAQ
  faq: 'FAQ',
  imageGallery: "Galerie d'images",
  //ResultsHistory
  WebAppSearchFunctionalityUpdate: '/WebAppSearchFunctionalityUpdateFR.pdf',
  Incorrectsearchurl: "Url de recherche incorrecte",
  InputmustbeanumberforanID: "L'entrée doit être un numéro d'identification",
  NoOperatorSelected: "Pas d'opérateur sélectionné",
  NoDropdownText: 'Pas de texte déroulant',
  EnterSearchText: 'Saisir le texte de recherche',
  EnterFilterFirst: "Sélectionnez d'abord un filtre",
  ownerName: 'Nom du propriétaire',
  contains: 'Contient',
  equals: 'Égal',
  notEquals: 'Pas égal',
  addQuery: 'Ajouter une requête',
  query: 'Requête',
  clearAll: 'Effacer tout',
  search: 'Recherche',
  filterHeading: 'Titre du filtre',
  filterOperator: 'Opérateur de filtre',
  selectSpecies: "Sélectionner l'espèce",
  searchScans:'Recherche de scans',
  date: 'Date',
  parasites: 'Parasites',
  batchScans: 'Scans du lot',
  animal: 'Animal',
  noBatchAvailable: 'Aucun lot disponible',
  scanDetails: 'Informations sur le scan',
  batchDetails: 'Informations sur le lot',
  details: 'Informations',
  searchResultHistory: 'Rechercher',
  scanReport: "Rapport d'analyse",
  images: 'Images',
  emailMustBeMoreThan3Characters: "L'adresse e-mail doit comporter plus de 3 caractères",
  PasswordMustBeBetween3And100Characters: 'Le mot de passe doit comporter entre 3 et 100 caractères',
  resultsForInTable: 'Résultats',
  //Updates
  updates: 'Mises à jour',
  searchWorklist: 'Rechercher',
  delete: 'Supprimer',
  edit: 'Modifier',
  //HelpLinks
  browseHelpTopics: "Parcourir les rubriques d'aide",
  helpwithFlukeScan: "Préparation des échantillons Fluke",
  helpwithFECScan: "Préparation des échantillons FEC",
  helpWithCaScan: "Préparation d'échantillons d'animaux de compagnie",
  frequentlyAskedQuestions: "Questions fréquemment posées",
  practice:"Pratique",
  //Support
  usernameIsRequired: "Le nom d'utilisateur est requis",
  passwordIsRequired: 'Le mot de passe est requis',
  invalidUsernameOrPassword: "Nom d'utilisateur ou mot de passe invalide",
  loggedInSuccessfully: 'Connecté avec succès',
  passwordHasBeenChanged: 'Le mot de passe a été modifié avec succès',
  signOutFromAllDevices: 'Se déconnecter de tous les appareils',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  confirmChangePasswordMessage: 'Êtes-vous sûr de vouloir modifier votre mot de passe ?',
  confirmChangePassword: 'Confirmer le changement de mot de passe',
  supportMaterial: 'Matériel de soutien',
  resetPassword: 'Réinitialiser le mot de passe',
  currentPassword: 'Mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  requiredField: 'Champ obligatoire',
  passwordsMustMatch: 'Les mots de passe doivent correspondre',
  newPasswordMatches:
    'Le nouveau mot de passe doit être alphanumérique, 12 caractères au minimum, dont au moins une majuscule et un caractère spécial.',
  ovacyteSupport: 'Assistance OvaCyte',
  welcomeToOvacyteSupport:
    "Bienvenue sur la page d'assistance d'OvaCyte. Vous y trouverez tout, des documents de soutien, la foire aux questions, etc.",
  ovacyteSupportIsHereToHelp: "Si vous n'arrivez pas à trouver ce que vous cherchez, n'hésitez pas à",
  frequentlyAskedQuestions: 'Foire aux questions',
  ovaCyteFaecalAnalyserScanningHelp:
    "Assistance avec l'analyseur de matière fécale OvaCyte/la numérisation: «Non valide» est affiché",
  ovaCyteFaecalAnalyserScanningHelpText1:
    "Le message «Cassette non valide» apparaîtra dans le champ 'Cassette' de l'Ovacyte lorsque:",
  ovaCyteFaecalAnalyserScanningHelpText2:
    'une cassette qui a déjà été scannée est utilisée/scannée à nouveau.',
  ovaCyteFaecalAnalyserScanningHelpText3:
    'la lecture du QR code est non valide.',
  ovaCyteFaecalAnalyserScanningHelpText4: 'Que faire pour résoudre ce problème?',
  ovaCyteFaecalAnalyserScanningHelpText5:
    'Si la cassette a déjà été scannée et utilisée auparavant, veuillez ouvrir une nouvelle cassette et la remplir avec votre échantillon déjà préparé et utilisé (si dans les 30 minutes du processus de préparation).',
  ovaCyteFaecalAnalyserScanningHelpText6:
    "S'il s'agit d'une nouvelle cassette, veuillez contacter notre numéro d'assistance pour obtenir un nouveau QR code.",
  ovaCyteFaecalAnalyserScanningLongRunningScan:
    "Assistance avec l'analyseur de matière fécale OvaCyte/la numérisation: scan de longue durée",
  ovaCyteFaecalAnalyserScanningLongRunningScanText1:
    "Un scan peut être plus long que d'habitude pour plusieurs raisons, veuillez vérifier ce qui suit: ",
  ovaCyteFaecalAnalyserScanningLongRunningScanText2: "Une vitesse de connexion Wi-Fi ou internet haut débit lente",
  ovaCyteFaecalAnalyserScanningLongRunningScanText3:
    "Si l'analyseur Ovacyte est connecté à internet sans fil, veuillez essayer ce qui suit:",
  ovaCyteFaecalAnalyserScanningLongRunningScanText4:
    "Placez l'analyseur Ovacyte plus près de votre modem-routeur/point d'accès sans fil.",
  ovaCyteFaecalAnalyserScanningLongRunningScanText5:
    "Effectuez un test et vérifiez s'il y a eu un impact positif sur la performance.",
  ovaCyteFaecalAnalyserScanningLongRunningScanText6:
    "La cassette n'a pas été bien mise bien à plat sur l'analyseur Ovacyte",
  ovaCyteFaecalAnalyserScanningLongRunningScanText7:
    "La prochaine fois que vous mettez une cassette sur l'analyseur Ovacyte, veuillez:",
  ovaCyteFaecalAnalyserScanningLongRunningScanText8:
    "Utiliser vos deux mains pour mettre la cassette sur l'axe central.",
  ovaCyteFaecalAnalyserScanningLongRunningScanText9:
    "Vous assurer que l'écrou de verrouillage est mis tout droit dans l'axe et reste droit lorsque vous le vissez dedans (l'écrou de verrouillage doit être serré, mais pas trop).",
  ovaCyteFaecalAnalyserScanningLongRunningScanText10:
    "Veuillez-vous assurer qu'il n'y a aucun débris sur la surface plate de l'axe (veuillez consulter",
  ovaCyteFaecalAnalyserScanningLongRunningScanText11:
    "Comment entretenir/nettoyer l'analyseur Ovacyte)",
  ovaCyteFaecalAnalyserScanningLongRunningScanText12: "Il y a de nombreuses bulles d'air dans la cassette",
  ovaCyteFaecalAnalyserScanningLongRunningScanText13: "La prochaine fois que vous préparez un échantillon, ",
  ovaCyteFaecalAnalyserScanningLongRunningScanText14:
    "veillez à ce qu'il n'y ait aucune bulle d'air dans la seringue",
  ovaCyteFaecalAnalyserScanningLongRunningScanText15:
    "Tenez la cassette en position verticale de sorte que l'autocollant rond blanc soit à la position de midi.",
  ovaCyteFaecalAnalyserScanningLongRunningScanText16:
    "Appuyez lentement sur le piston de la seringue pour veiller à un écoulement uniforme dans la cassette.",
  cassetteHelpCanIReuseTheCassette: 'Assistance avec la cassette: puis-je réutiliser la cassette?',
  cassetteHelpCanIReuseTheCassetteText1:
    "Chaque cassette est propre et conçue pour recevoir un échantillon de matière fécale une seule fois de façon à ne pas contaminer l'échantillon d'un autre animal.",
  cassetteHelpCanIReuseTheCassetteText2:
    "N'essayez pas de nettoyer et de réutiliser la cassette, car il n'est alors pas garanti qu'elle ne contienne aucun œuf ou autre débris qui puisse interférer avec la capacité de l'analyseur Ovacyte de rechercher et identifier des œufs",
  cassetteHelpCanIReuseTheCassetteText3:
    "Une fois que vous avez fini d'utiliser la cassette, veuillez en disposer en suivant la procédure pour les déchets cliniques.",
  cassetteHelpCanIReuseTheCassetteText4:
    "Si vous avez besoin de commander de nouvelles cassettes, veuillez nous contacter sur notre numéro d'assistance.",
  cassetteHelpTheStickerIsLooseOrMissing:
    "Assistance avec la cassette: l'autocollant se détache ou a disparu",
  cassetteHelpTheStickerIsLooseOrMissingText1:
    "L'autocollant rond blanc couvrant l'évent sur la cassette se détache.",
  cassetteHelpTheStickerIsLooseOrMissingText2:
    "Avec un doigt propre et sec, faites glisser doucement votre doigt sur l'autocollant.",
  cassetteHelpTheStickerIsLooseOrMissingText3: "N'essayez pas de coller l'autocollant.",
  cassetteHelpTheStickerIsLooseOrMissingText4: "N'essayez pas de retirer l'autocollant.",
  cassetteHelpTheStickerIsLooseOrMissingText5:
    "Le scan fonctionnera quand même si l'autocollant se détache et est plat.",
  cassetteHelpTheStickerIsLooseOrMissingText6:
    "Il manque l'autocollant rond blanc couvrant l'évent sur la cassette.",
  cassetteHelpTheStickerIsLooseOrMissingText7:
    "Vérifiez si l'autocollant est dans l'emballage en aluminium. Si l'autocollant est dans l'emballage, ",
  cassetteHelpTheStickerIsLooseOrMissingText8: "veuillez retirer l'autocollant de l'emballage avec des doigts propres et secs",
  cassetteHelpTheStickerIsLooseOrMissingText9:
    "Trouvez le petit évent (il est directement aligné avec le trou d'injection de l'échantillon).",
  cassetteHelpTheStickerIsLooseOrMissingText10:
    "Placez l'autocollant sur le trou et appuyez doucement pour vous assurer qu'il est bien collé.",
  cassetteHelpTheStickerIsLooseOrMissingText11: "N'essayez pas de coller l'autocollant.",
  cassetteHelpTheStickerIsLooseOrMissingText12:
    "Si il manque l'autocollant blanc lorsque vous essayez de faire un scan, le message «Erreur de cassette identifiée» apparaîtra.",
  cassetteHelpTheStickerIsLooseOrMissingText13:
    "Si ce message apparaît après avoir essayé la solution ci-dessus, ",
  cassetteHelpTheStickerIsLooseOrMissingText14:
    "veuillez nous contacter sur notre numéro d'assistance pour obtenir de l'aide.",
  cassetteHelpTheStickerIsLooseOrMissingText15:
    "Veuillez conserver la cassette, car nous suivons nos cassettes à des fins d'assurance qualité.",
  cassetteHelpDroppedMyCassetteWhatDoIDo:
    "Assistance avec la cassette: j'ai fait tomber la cassette, que dois-je faire?",
  cassetteHelpDroppedMyCassetteWhatDoIDoText1:
    "Il est important de veiller à bien prendre soin de la cassette une fois remplie d'un échantillon de matière fécale afin de s'assurer que les œufs aient le temps de remonter à la surface et que toutes les bulles d'air aient eu le temps de reposer.",
  cassetteHelpDroppedMyCassetteWhatDoIDoText2:
    "Si vous avez fait tomber la cassette, veuillez procéder comme suit:",
  cassetteHelpDroppedMyCassetteWhatDoIDoText3:
    "Tenez la cassette perpendiculaire au sol de sorte que l'autocollant rond blanc soit à la position de midi.",
  cassetteHelpDroppedMyCassetteWhatDoIDoText4:
    "Vérifiez si l'échantillon de matière fécale s'est écoulé de la cassette. Si c'est le cas, veuillez remplir la cassette afin qu'aucune bulle d'air ne soit visible.",
  cassetteHelpDroppedMyCassetteWhatDoIDoText5:
    "Laissez la cassette à plat pendant six minutes avant de la remettre sur l'analyseur OvaCyte.",
  cassetteHelpDroppedMyCassetteWhatDoIDoText6:
    "Important: chaque fois que la cassette tombe ou est secouée, de petites bulles d'air se forment dans la cassette, ce qui rallonge la durée de l'analyse. Veuillez faire preuve de patience pendant cette période, le temps que le scanner isole ces bulles d'air.",
  clinicalHelpCanIUseMyOwnFlotationFluid:
    'Assistance clinique: puis-je utiliser mon propre liquide de flottation?',
  clinicalHelpCanIUseMyOwnFlotationFluidText1:
    "N'essayez pas d'utiliser votre propre liquide de flottaison (NaCL), notre liquide de flottaison a été conçu pour fonctionner avec notre cassette et l'analyseur OvaCyte.",
  clinicalHelpCanIUseMyOwnFlotationFluidText2:
    "Si vous essayez d'utiliser votre propre solution, vous risquez d'obtenir des comptages d'œufs inexacts ou inexistants, une rupture précoce de la membrane des parasite ou une non-élévation des œufs au niveau approprié à l'intérieur de la cassette.",
  clinicalHelpCanIUseMyOwnFlotationFluidText3:
    "Cela peut conduire à l'utilisation d'une autre cassette pour injecter un nouvel échantillon avec notre propre liquide de flottaison.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScan:
    'Assistance clinique: puis-je omettre le processus de flottation et simplement faire un scan?',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText1:
    "Le système OvaCyte permet à nos clients de préparer des échantillons avant de commencer leur scan. Si vous souhaitez omettre le processus de flottaison, procédez comme suit:",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText2:
    "Attendez environ 10 minutes avant de commencer le processus de préparation de l'échantillon.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText3:
    "Préparez l'échantillon à injecter dans la cassette.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText4:
    "Injectez l'échantillon dans la cassette et posez-la sur une surface plate à côté de l'analyseur OvaCyte.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText5: 'Important: ',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText6:
    "L'échantillon doit avoir reposé pendant au moins six minutes avant de commencer le scan.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText7:
    "Quand le scan en cours est terminé, mettez la cassette sur l'analyseur OvaCyte.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText8:
    "Ne secouez pas, ne tournez pas et ne retournez pas la cassette quand vous la mettez sur l'analyseur OvaCyte. ",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText9: 'Dans le cas contraire, ',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText10:
    "veuillez consulter « j'ai fait tomber ma cassette, que dois-je faire?»",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText11: "Commencez la procédure de scan.",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText12:
    "Après avoir appuyé sur « Démarrer le scan », appuyez sur « Ignorer » en haut à droite de l'écran.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetected:
    "Erreur de cassette identifiée: aucune cassette valide n'a pu être détectée",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText1:
    "Si le message ci-dessus apparaît lorsque vous effectuez un scan, veuillez: ",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText2:
    "Vous assurer que la cassette est bien placée sur l'analyseur OvaCyte avant de commencer le scan.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText3:
    "Si la cassette est sur l'analyseur de matière fécale OvaCyte, vérifiez s'il ne manque pas l'autocollant rond blanc sur le bord extérieur de la cassette?",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText4: "Oui, l'autocollant est absent de la cassette.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText5:
    "Vérifiez si l'autocollant est dans l'emballage en aluminium. Si l'autocollant est dans l'emballage, veuillez retirer ",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText6: "l'autocollant de l'emballage avec des doigts propres et secs.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText7:
    "Trouvez le petit évent (il est directement aligné avec le trou d'injection de l'échantillon).",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText8:
    "Placez l'autocollant sur le trou et appuyez doucement pour vous assurer qu'il est bien collé.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText9: "N'essayez pas de coller l'autocollant.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText10:
    "Non, l'autocollant n'est pas dans le sachet d'aluminium.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText11:
    "Veuillez nous contacter sur notre numéro d'assistance.",
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText12:
    "Veuillez conserver la cassette, car nous suivons nos cassettes à des fins d'assurance qualité.",
  cassetteErrorIdentifiedAValidCassetteIsRequired:
    'Erreur de cassette identifiée: une cassette valide est nécessaire',
  cassetteErrorIdentifiedAValidCassetteIsRequiredText1:
    "Le message 'une cassette valide est nécessaire 'apparaîtra si un QR code non valide ou déjà utilisé est scanné et l'option 'Commencer le scan' est sélectionnée.",
  cassetteErrorIdentifiedAValidCassetteIsRequiredText2: 'Que faire pour résoudre ce problème?',
  cassetteErrorIdentifiedAValidCassetteIsRequiredText3:
    "Si la cassette a déjà été scannée et utilisée auparavant, veuillez ouvrir une nouvelle cassette et la remplir avec votre échantillon déjà préparé (si dans les 30 minutes du processus de préparation).",
  cassetteErrorIdentifiedAValidCassetteIsRequiredText4:
    "Si il s'agit d'une nouvelle cassette, veuillez contacter notre numéro d'assistance pour obtenir un nouveau QR code.",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectly:
    "Erreur de cassette identifiée: la cassette n'est pas correctement installée",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText1:
    "Cette erreur peut se produire lorsque la cassette n'est pas à plat sur l'analyseur OvaCyte et doit être réajustée, veuillez: ",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText2: "Dévisser l'écrou de verrouillage.",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText3:
    "Retirer la cassette et la mettre sur une surface propre et plate, et veillez à ce qu'il",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText4:
    "n'y ait aucun débris dans le trou au centre de la cassette et que",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText5:
    "la partie inférieure de la cassette près du trou soit propre et sans débris.",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText6:
    "vous assurer qu'il n'y a aucun débris dans l'axe",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText7:
    "(veuillez consulter 'Comment entretenir/nettoyer l'analyseur de matière fécale OvaCyte').",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText8:
    "Remettre la cassette sur l'analyseur OvaCyte à l'aide des deux mains.",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText9:
    "Insérer l'écrou de verrouillage tout droit en place dans l'axe. Veillez à ce qu'il reste droit pendant que vous le vissez dedans (l'écrou de verrouillage doit être serré, mais pas trop).",
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText10:
    "Appuyer sur 'Confirmer prêt à retester' sur l'écran de l'analyseur OvaCyte.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyser:
    "Assistance générale: comment entretenir/nettoyer l'analyseur de matière fécale Ovacyte",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText1:
    "L'analyseur OvaCyte doit être éteint correctement, puis débrancher du secteur avant d'être nettoyé.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText2: "Nettoyage de l'axe",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText3:
    "Pour nettoyer l'axe, procédez comme suit: ",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText4:
    "Brossez tout débris de la surface ou des côtés de l'axe.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText5:
    "Avec un chiffon humide (non trempé), essuyez tous les cristaux de sel sur l'axe.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText6:
    "Avec un chiffon sec non pelucheux, essuyez toute humidité.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText7:
    "N'utilisez jamais de produit de nettoyage à base d'alcool ou de solvant sur l'analyseur OvaCyte.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText8:
    "Nettoyage de la lentille (source lumineuse)",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText9:
    "Pour nettoyer la zone de la lentille et veiller à ce que la lentille qui émet la lumière soit propre et sèche, procédez comme suit: ",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText10:
    "À l'aide d'un chiffon humide (non trempé), tamponnez doucement la lentille pour humidifier toute accumulation de sel.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText11: "Attendez 10 secondes.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText12:
    "À l'aide d'un chiffon sec non pelucheux, tamponnez doucement la lentille pour retirer toute humidité.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText13:
    "Répétez les étapes ci-dessus jusqu'à ce que la zone de la lentille soit propre.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText14:
    "Nettoyage de la lentille (grossissement)",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText15:
    "Pour vérifier si la lentille de grossissement a besoin d'être nettoyée, procédez comme suit: ",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText16:
    "À l'aide d'un petit miroir, placez le miroir à un angle de 45° et regardez s'il y a des débris, des éclaboussures, etc.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText17:
    "À l'aide d'une seringue vide et propre, soufflez de l'air sur la lentille pour retirer toute poussière ou autre particule.",
  whatIsOvaCytesPrivacyPolicy: "Quelle est la politique de confidentialité d'OvaCyte?",
  whatIsOvaCytesPrivacyPolicyText1:
    "Il est important que vous compreniez quelles données personnelles nous utilisons dans le cadre de la prestation d'OvaCyte. Pour consulter notre déclaration de confidentialité, veuillez la télécharger ici: ",
  whatIsOvaCytesPrivacyPolicyText2: 'Politique de confidentialité',   
  supprtMaterial: "Documents d'assistance",
  privacyPolicyDownload: '/PrivacyPolicyFrench.pdf',
  installingOvacyte: "Installation de l'analyseur de matière fécale Ovacyte",
  usingOvacyteFaecalAnalyzer: "Utilisation de l'analyseur de matière fécale Ovacyte",
  ovacytePrivacyPolicy: "Politique de confidentialité d'OvaCyte",
  usermanualEq: "Manuel de l'utilisateur pour les grands animaux",
  userManualDownloadEQ: '/OvaCyte Faecal Analyser User Manual v1.4 French.pdf',
  usermanualCa: "Manuel de l'utilisateur pour les animaux de compagnie",
  userManualDownloadCA: '/OvaCyte Faecal Analyser User Manual v1.4 French.pdf',

  // Fec scan support

  FECScan: 'Analyse FEC',
  placeEmptySamplePotOntoTheScaleAndPressT_toZeroTheScale: 
  "Placez un pot à échantillon vide sur la balance et appuyez sur T pour mettre la balance à zéro.",
  nowWeighOut3gOfFaecalMatterTakenFromYourOriginalSamplePot:
  "Pesez 3 g de matière fécale prélevée dans votre pot d'échantillonnage original.", 
  fecPointThreeAddFloatationFluid: "Sans retirer le pot d'échantillonnage, ajoutez 47 grammes de liquide de flottaison FEC pour porter le total à 50 grammes. Vous avez maintenant 3 grammes de matière fécale et 47 grammes de liquide de flottaison.",
  fecPointFourMixSample: "À l'aide de la pince ou de la spatule fournie, mélangez bien l'échantillon pendant 15 secondes pour vous assurer que toutes les boules fécales sont brisées dans le liquide et que les œufs sont libérés dans le liquide.",
  fecPointFivePourLiquid: "En plaçant le filtre bleu sur un bécher propre, verser le liquide à travers les mailles du filtre et tapoter un certain nombre de fois pour s'assurer que tout le liquide passe dans le bécher avec les œufs.",
  fecPointSixSwirlLiquid: "À l'aide d'une seringue, agiter le liquide, prélever environ 10 ml au centre du liquide et les placer dans la cassette. Veillez à ce que l'autocollant de la cassette soit orienté vers 12 heures.",
  fecPointSevenInsertLiquid: "En exerçant une légère pression, insérez le liquide dans le canal de la cassette et vous êtes maintenant prêt à la placer sur l'analyseur et à procéder à l'analyse.",
  fecPointEightPooledSamples: "Pour les échantillons groupés ou la préparation d'échantillons secs, veuillez cliquer ici : ",
  pooledSampleInstructions: "Préparation de l'échantillon de soutien de la FEC",
  pooledSampleInstructionsDownload: '/FEC-Supporting-Sample-Prep.pdf',
  
  // Fluke scan support 
  FlukeScan: 'Analyse Fluke',
  placeAnEmptyCleanSamplePotOntoTheScale: "Placez un pot à échantillon vide et propre sur la balance. Appuyez sur T pour mettre la balance à zéro." ,
  flukePointTwoWeighFecalMatter: "Pesez maintenant 5 grammes de matière fécale à partir de votre pot d'échantillon original. Veillez à ce que l'échantillon original soit bien mélangé afin qu'un échantillon représentatif soit testé.",
  nowFillTheSamplePotWithWaterUpToThe50MlMark: "Remplissez maintenant le récipient d'échantillon avec de l'eau jusqu'à ce qu'il atteigne la marque de 50 ml.",
  mixTheSampleVeryWellWithTheForcepsToEnsureThatAllEggsAreRealisedFromTheFaecalMatter:
  "Mélangez très bien l'échantillon à l'aide de la pince pour vous assurer que tous les œufs sont retirés de la matière fécale.",
  placeTheGreyFilterOnTopOfTheCoralFilterNumbers1and2PointingUpwards: "Placer le filtre gris sur le filtre corail, les numéros 1 et 2 étant orientés vers le haut.", 
  flukePointSixPourFiltrate: "Verser la moitié du filtrat dans la pile de filtres et tapoter 30 fois pour s'assurer que tout le liquide passe dans le bécher.",
  NowPassTheSecondHalfThroughTheFilterStaceAndTapAnother30Times: "Faites ensuite passer la seconde moitié par la pile de filtres et tapez encore 30 fois.", // needs french
  flukePointEightHalfFillSamplePot: "Remplissez à moitié votre pot d'échantillonnage avec de l'eau, faites-le tourner et versez-le dans la pile de filtres. Cette opération a pour but d'éliminer les œufs qui pourraient s'être fixés à la surface du pot d'échantillonnage. Tapotez encore 30 fois pour vous assurer que tout le liquide passe à travers les deux piles de filtres dans le pot situé en dessous.",
  flukePointNineRemoveCoralFilter: "Retirez maintenant le filtre à corail, inversez-le et placez-le au-dessus d'un bécher propre de 50 ml. Avant d'inverser le filtre, vous devez vous assurer qu'il n'y a pas de liquide en surface sur la maille corallienne. Si l'étape de filtration est lente et que le nombre de tapes prescrit n'est pas suffisant pour achever le processus de filtration, cela indique que votre filtre présente une accumulation de résidus organiques et qu'il doit être trempé. Un deuxième filtre est fourni pour faciliter cette opération, et les instructions de trempage sont décrites dans la documentation.",
  flukePointTen: "Assurez-vous que le numéro 3 du filtre à corail est orienté vers le haut. Prélevez 50 ml de liquide de flottaison Fluke et inondez la surface du filtre. Une fois que le liquide a traversé le filtre et pénétré dans le bécher, tapotez le filtre 30 fois pour éliminer les gouttelettes d'eau et les œufs qui ont pu adhérer à la surface inférieure du filtre. La préparation de l'échantillon est presque terminée.",  
  flukePointeEleven: "Maintenant, à l'aide d'une seringue, prélevez le liquide contenu dans le bécher et insérez-le dans la cassette, l'autocollant étant orienté vers 12 heures. Vous êtes maintenant prêt à la placer sur l'analyseur et à procéder au test.",


  // CA support
  thisIsAhelpPageFEC: "Voici la page d'aide pour les appareils FEC. Elle explique la préparation nécessaire au lancement d'un balayage FEC.",
  thisIsAhelpPageFluke:"Voici la page d'aide pour les appareils Fluke. Elle explique la préparation nécessaire au lancement d'un balayage Fluke.",
  thisIsAhelpPageCA: "Il s'agit de la page d'aide pour les appareils destinés aux animaux de compagnie. Cette page explique la préparation nécessaire pour commencer un scanner TEP.",
  pleaseFollowBelow: "Veuillez suivre les étapes ci-dessous, telles qu'elles sont illustrées dans notre vidéo d'exemple.",
  
  fillTheCutterEndOfTheInsertWithFaeces: "Remplir l'extrémité creuse de l'insert avec des matiéres fécales. En cas de diarrhée, utiliser plutôt une seringue.",
  pushTheInsertIntoTheTube: "Intoduire l'insert dans le tube.",
  takeFlotationFluidUsingTheSyringe: "Prélever 12 ml de liquide de flottaison (LF) à l'aide de la seringue.",
  InjectAllTheFFIntoTheTube: "Injecter tous le LF dans le tube",
  GentlyMashTheSolutionUntilMixedThoroughly: "Malaxer délicatement la solution jusqu'à ce qu'elle soit homogéne.",
  UsingTheSyringeExtractTheFiltrate: "À l'aide de la seringue, aspirer le volume maximum de filtrat.",
  WithFingerOnTheSyringeTopPullBackThePlunder: "Boucher l'extrémité de la seringue avec un doigt, tirer le piston vers l'arriére aussi loin que possible, puis retirer le doigt pour évacuer l'air. ",
  DepressThePlungerWithTheSyringePointingUpwardsUntilTheFluidReachesTheTop: "Appuyer sur le piston en orientant la seringue vers le haut jusqu'à ce que le liquide atteigne l'extrémité de la seringue.",
  InjectTheFluidIntoTheCassetteAndPlaceOnTheAnalyser: "Injecter le filtrat dans la cassette et la placer sur l'analyser."

}
