import {ThemeContext} from '../context/theme-context';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

function ThemeTogglerButton() {
  return (
    <ThemeContext.Consumer>
      {({toggleTheme, theme, darkMode}) => (
        <div className='theme-button'>
        <FontAwesomeIcon onClick={toggleTheme}  style={{color: theme.textcolor}} icon={darkMode ? faSun : faMoon} size="3x" className="fontawesome-sidemenu" />
        </div>
      )}
    </ThemeContext.Consumer>
  );
}


export default ThemeTogglerButton;