import {
  FETCH_RESULTS_START,
  FETCH_RESULTS_SUCCESS,
  FETCH_RESULTS_FAILURE,
  RH_SELECTED,
  FETCH_RH_DETAILS,
  FETCH_SCAN,
  FETCH_IMAGES,
  FETCH_BATCH_DETAILS,
  FETCH_BATCH_ERROR,
  SET_RH_PREVIOUS_PAGE,
  FETCH_BATCH_REPORT,
  CLEAR_IMAGES,
  FETCH_SCAN_SPECIES,
  FETCH_PRACTICE_DATA_INDEX_REDUCER,
  FETCH_PRACTICE_LABEL,
  // EDIT_WORKLIST,
  EDIT_BATCH_DETAILS,
  GET_ERRORS,
} from './types'
import { backInstance } from '../../config'

import history from "../../history";
export const fetchBatchReport = batchId => {
  return async dispatch => {
    const response = await backInstance.get(`batch/${batchId}/report`,{
    headers: {
      'Cache-Control': 'no-cache'
    }})
    dispatch({ type: FETCH_BATCH_REPORT, payload: response.data })
  }
}

export const editBatchScanComment = (editBatchRequest, batchId) => async dispatch => {
  try {
      const res = await backInstance.patch(`/batch/${batchId}`, {
        comment: editBatchRequest.comment,
      });
      dispatch({
        type: EDIT_BATCH_DETAILS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }); 
  }  
}

const fetchResultsStart = () => {
  return {
    type: FETCH_RESULTS_START,
  }
}

const fetchResultsSuccess = (history, count) => {
  return {
    type: FETCH_RESULTS_SUCCESS,
    history,
    count,
  }
}

const fetchResultsFailure = error => {
  return {
    type: FETCH_RESULTS_FAILURE,
    error,
    status: error.response.status,
  }
}
const fetchResultsDetails = (page, vars=false, column=false, newPerPage=10) => {

  if(!vars){vars={}}
  try{
    vars['scan_state'] = 'done'
    let query = `scan?where=${JSON.stringify(vars)}&page=${page}&per_page=${newPerPage}&desc=true&sort={"created":true}`

    const response = backInstance.get(query)
    return response
  }
  catch(e){
    console.log('error', e)
  }
}

export const fetchResults =
  (page, searchTerm = '', sortCol=false, newPerPage=10) =>
  dispatch => {
    dispatch(fetchResultsStart())
    return fetchResultsDetails(page, searchTerm, sortCol, newPerPage).then(
      response => dispatch(fetchResultsSuccess(response.data, response.headers['x-total-count'])),
      error => dispatch(fetchResultsFailure(error))
    )
  }

export const selectResultHistory = (scan_id, batch_id) => {
  return {
    type: RH_SELECTED,
    scan_id: scan_id,
    batch_id: batch_id,
  }
}

export const fetchResultHistoryDetails = id => {
  return async dispatch => {
    const response = await backInstance.get(`scan/${id}/report`)
    dispatch({ type: FETCH_RH_DETAILS, payload: response.data })
  }
}

export const fetchBatchDetails = id => {
  return async dispatch => {
    try {
      const response = await backInstance.get(`batch/${id}`)
      dispatch({ type: FETCH_BATCH_DETAILS, payload: response.data })
    } catch (e) {
      dispatch({ type: FETCH_BATCH_ERROR, payload: null })
    }
  }
}

export const fetchScan = id => {
  return async dispatch => {
    const response = await backInstance.get(`scan/${id}`)
    dispatch({ type: FETCH_SCAN, payload: response.data })
  }
}

export const fetchScanSpecies = id => {
  return async dispatch => {
    const response = await backInstance.get(`species/${id}`)
    dispatch({ type: FETCH_SCAN_SPECIES, payload: response.data })
  }
}

export const getPracticeLabel = id => {
  return async dispatch => {
    try {
      const response = await backInstance.get(`practice/${id}`)
      dispatch({ type: FETCH_PRACTICE_LABEL, payload: response.data })
    } catch (e) {
      console.log('error with gettting practice label '+ e)
    }
  }
}

export const getPracticeDataIndex = () => async dispatch => {
  try {
    const res = await backInstance.get(`practice/mine`)
    dispatch({
      type: FETCH_PRACTICE_DATA_INDEX_REDUCER,
      payload: res.data,
    })
  } catch (error) {
    console.log('error with gettting practice index '+ error)
  }
}

export const fetchImages = (id, parasite = null) => {
  return async dispatch => {
    let response
    if (parasite) {
      response = await backInstance.get(`scan/${id}/images?parasite=${parasite}&bbox=bbox&filter=keep`)
    } else {
      response = await backInstance.get(`scan/${id}/images?&bbox=bbox&filter=keep`)
    }
    if (response.data === '') {
      dispatch({ type: FETCH_IMAGES, payload: [] })
    } else {
      dispatch({ type: FETCH_IMAGES, payload: response.data })
    }
  }
}

export const clearImages = () => {
  return dispatch => {
    dispatch({ type: CLEAR_IMAGES })
  }
}

export const setPreviousPage = pageId => {
  return dispatch => dispatch({ type: SET_RH_PREVIOUS_PAGE, payload: pageId })
}
