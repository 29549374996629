import React, { useReducer, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import faq1 from '../../../assets/faq/1.png'
import faq2 from '../../../assets/faq/2.png'
import { useTranslation, withTranslation } from 'react-i18next'
import Accordion from '../../../components/Accordion'
import SupportMaterial from '../../../components/SupportMaterial'
import { ThemeContext } from '../../../context/theme-context'
const initialState = {
  accordion_1: '',
  accordion_2: '',
  accordion_3: '',
  accordion_4: '',
  accordion_5: '',
  accordion_6: '',
  accordion_7: '',
  accordion_8: '',
  accordion_9: '',
  accordion_10: '',
  accordion_11: '',
  accordion_12: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'accordion_1':
      return {
        ...initialState,
        accordion_1: state.accordion_1 === 'active' ? '' : 'active',
      }
    case 'accordion_2':
      return {
        ...initialState,
        accordion_2: state.accordion_2 === 'active' ? '' : 'active',
      }
    case 'accordion_3':
      return {
        ...initialState,
        accordion_3: state.accordion_3 === 'active' ? '' : 'active',
      }
    case 'accordion_4':
      return {
        ...initialState,
        accordion_4: state.accordion_4 === 'active' ? '' : 'active',
      }
    case 'accordion_5':
      return {
        ...initialState,
        accordion_5: state.accordion_5 === 'active' ? '' : 'active',
      }
    case 'accordion_6':
      return {
        ...initialState,
        accordion_6: state.accordion_6 === 'active' ? '' : 'active',
      }
    case 'accordion_7':
      return {
        ...initialState,
        accordion_7: state.accordion_7 === 'active' ? '' : 'active',
      }
    case 'accordion_8':
      return {
        ...initialState,
        accordion_8: state.accordion_8 === 'active' ? '' : 'active',
      }
    case 'accordion_9':
      return {
        ...initialState,
        accordion_9: state.accordion_9 === 'active' ? '' : 'active',
      }
    case 'accordion_10':
      return {
        ...initialState,
        accordion_10: state.accordion_10 === 'active' ? '' : 'active',
      }
    case 'accordion_11':
      return {
        ...initialState,
        accordion_11: state.accordion_11 === 'active' ? '' : 'active',
      }
    case 'accordion_12':
      return {
        ...initialState,
        accordion_12: state.accordion_12 === 'active' ? '' : 'active',
      }
    default:
      return state
  }
}

const Support = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { t } = useTranslation()


  var eq = false
  var la = false
  var ca = false
  if(localStorage.getItem('practiceDocumentation')){var cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
  eq = cache['eq'];la = cache['la'];ca = cache['ca']
    
  return (
    <ThemeContext.Consumer>
      {({ theme, darkMode }) => (
        <div className='dashboard-container cow-bg' style={{ height: 'auto' }}>
          <div
            style={{
              backgroundColor: theme.image, // #f2f2f2c0
              padding: '0px 25px 25px',
            }}
          >
            <h3 className='' style={{ paddingTop: '120px', color: theme.textcolor }}>
              {t('ovacyteSupport')}
            </h3>
            <div className='' style={{ paddingTop: '20px', color: theme.textcolor }}>
              <p>
                {t('welcomeToOvacyteSupport')}
                <br /> <br />
                {t('ovacyteSupportIsHereToHelp')}{' '}
                <Link to='/contact'>
                  <u style={{ color: '#009FEE' }}>{t('contactUs')}</u>
                </Link>
                .
              </p>
            </div>

            <div
              className='row'
              style={{
                color: '#212529',
                fontSize: '1rem',
                fontWeight: '400',
                margin: 'auto',
                color: theme.textcolor
              }}
            >
              <div className='col-sm-12 col-md-8 mr-5 pl-0' >
                <h4
                  className='mb-4'
                  style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    borderBottom: '1px solid #4472C4',
                    fontSize: '20px',
                    color: theme.textcolor
                  }}
                >
                  {t('frequentlyAskedQuestions')}
                </h4>

                <Accordion
                  state={state.accordion_1}
                  dispatch={() => dispatch({ type: 'accordion_1' })}
                  title='ovaCyteFaecalAnalyserScanningHelp'
                  style={{ backgroundColor: theme.foreground, color: theme.textcolor }}
                >
                  <img alt='faq1' style={{ width: '300px' }} src={faq1} />
                  <br />
                  <br />
                  {t('ovaCyteFaecalAnalyserScanningHelpText1')}
                  <ul>
                    <li>{t('ovaCyteFaecalAnalyserScanningHelpText2')}</li>
                    <li>{t('ovaCyteFaecalAnalyserScanningHelpText3')}</li>
                  </ul>
                  <b>{t('ovaCyteFaecalAnalyserScanningHelpText4')}</b>
                  <br />
                  {t('ovaCyteFaecalAnalyserScanningHelpText5')}
                  <br />
                  <br />
                  {t('ovaCyteFaecalAnalyserScanningHelpText6')}
                </Accordion>

                <Accordion
                  state={state.accordion_2}
                  dispatch={() => dispatch({ type: 'accordion_2' })}
                  title='ovaCyteFaecalAnalyserScanningLongRunningScan'
                >
                  <p>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText1')}</p>
                  <ul>
                    <li>
                      {t('ovaCyteFaecalAnalyserScanningLongRunningScanText2')}
                      <br />
                      {t('ovaCyteFaecalAnalyserScanningLongRunningScanText3')}
                      <ul>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText4')}</li>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText5')}</li>
                      </ul>
                    </li>
                    <li>
                      {t('ovaCyteFaecalAnalyserScanningLongRunningScanText6')}
                      <br />
                      {t('ovaCyteFaecalAnalyserScanningLongRunningScanText7')}
                      <ul>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText8')}</li>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText9')}</li>
                        <li>
                          {t('ovaCyteFaecalAnalyserScanningLongRunningScanText10')}{' '}
                          <u>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText11')}</u>
                        </li>
                      </ul>
                    </li>
                    <li>
                      {t('ovaCyteFaecalAnalyserScanningLongRunningScanText12')}
                      <br />
                      {t('ovaCyteFaecalAnalyserScanningLongRunningScanText13')}
                      <ul>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText14')}</li>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText15')}</li>
                        <li>{t('ovaCyteFaecalAnalyserScanningLongRunningScanText16')}</li>
                      </ul>
                    </li>
                  </ul>
                </Accordion>

                <Accordion
                  state={state.accordion_3}
                  dispatch={() => dispatch({ type: 'accordion_3' })}
                  title='cassetteHelpCanIReuseTheCassette'
                >
                  <p>{t('cassetteHelpCanIReuseTheCassetteText1')}</p>
                  <p>{t('cassetteHelpCanIReuseTheCassetteText2')}</p>
                  <p>{t('cassetteHelpCanIReuseTheCassetteText3')}</p>
                  <p>{t('cassetteHelpCanIReuseTheCassetteText4')}</p>
                </Accordion>

                <Accordion
                  state={state.accordion_4}
                  dispatch={() => dispatch({ type: 'accordion_4' })}
                  title='cassetteHelpTheStickerIsLooseOrMissing'
                >
                  <p>{t('cassetteHelpTheStickerIsLooseOrMissingText1')}</p>
                  <ul>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText2')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText3')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText4')}</li>
                  </ul>
                  <p>{t('cassetteHelpTheStickerIsLooseOrMissingText5')}</p>
                  <p>{t('cassetteHelpTheStickerIsLooseOrMissingText6')}</p>
                  <ul>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText7')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText8')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText9')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText10')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText11')}</li>
                  </ul>

                  <p>{t('cassetteHelpTheStickerIsLooseOrMissingText12')}</p>
                  <img alt='faq2' style={{ width: '300px' }} src={faq2} />
                  <br />
                  <br />

                  <ul>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText13')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText14')}</li>
                    <li>{t('cassetteHelpTheStickerIsLooseOrMissingText15')}</li>
                  </ul>
                </Accordion>

                <Accordion
                  state={state.accordion_5}
                  dispatch={() => dispatch({ type: 'accordion_5' })}
                  title='cassetteHelpDroppedMyCassetteWhatDoIDo'
                >
                  <p>{t('cassetteHelpDroppedMyCassetteWhatDoIDoText1')}</p>
                  <p>{t('cassetteHelpDroppedMyCassetteWhatDoIDoText2')}</p>
                  <ul>
                    <li>{t('cassetteHelpDroppedMyCassetteWhatDoIDoText3')}</li>
                    <li>{t('cassetteHelpDroppedMyCassetteWhatDoIDoText4')}</li>
                    <li>{t('cassetteHelpDroppedMyCassetteWhatDoIDoText5')}</li>
                  </ul>
                  <p>{t('cassetteHelpDroppedMyCassetteWhatDoIDoText6')}</p>
                </Accordion>

                <Accordion
                  state={state.accordion_6}
                  dispatch={() => dispatch({ type: 'accordion_6' })}
                  title='clinicalHelpCanIUseMyOwnFlotationFluid'
                >
                  <p>{t('clinicalHelpCanIUseMyOwnFlotationFluidText1')}</p>

                  <p>{t('clinicalHelpCanIUseMyOwnFlotationFluidText2')}</p>

                  <p>{t('clinicalHelpCanIUseMyOwnFlotationFluidText3')}</p>
                </Accordion>

                <Accordion
                  state={state.accordion_7}
                  dispatch={() => dispatch({ type: 'accordion_7' })}
                  title='clinicalHelpCanISkipFlotationProcessAndJustDoAScan'
                >
                  <p>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText1')}</p>
                  <ul>
                    <li>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText2')}</li>
                    <li>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText3')}</li>
                    <li>
                      {t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText4')}

                      <br />
                      <strong>
                        <u>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText5')}</u>
                      </strong>
                      <u>:{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText6')}</u>
                    </li>
                    <li>
                      {t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText7')}
                      <br />
                      <strong>
                        <u>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText5')}</u>
                      </strong>
                      <u>: {t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText8')}</u>
                      <u> {t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText9')}</u>
                      {t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText10')}
                    </li>
                    <li>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText11')}</li>
                    <li>{t('clinicalHelpCanISkipFlotationProcessAndJustDoAScanText12')}</li>
                  </ul>
                </Accordion>

                <Accordion
                  state={state.accordion_8}
                  dispatch={() => dispatch({ type: 'accordion_8' })}
                  title='cassetteErrorIdentifiedNoValidCassetteCanBeDetected'
                >
                  <img alt='faq3' style={{ width: '300px' }} src={faq2} />
                  <br />
                  <br />
                  <p>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText1')}</p>
                  <ul>
                    <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText2')}</li>
                    <li>
                      {t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText3')}
                      <ul>
                        <li>
                          {t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText4')}

                          <ul>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText5')}</li>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText6')}</li>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText7')}</li>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText8')}</li>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText9')}</li>
                          </ul>
                        </li>
                        <li>
                          {t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText10')}
                          <ul>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText11')}</li>
                            <li>{t('cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText12')}</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Accordion>

                <Accordion
                  state={state.accordion_9}
                  dispatch={() => dispatch({ type: 'accordion_9' })}
                  title='cassetteErrorIdentifiedAValidCassetteIsRequired'
                >
                  <p>{t('cassetteErrorIdentifiedAValidCassetteIsRequiredText1')}.</p>

                  <p>
                    <strong>{t('cassetteErrorIdentifiedAValidCassetteIsRequiredText2')}</strong>
                  </p>
                  <p>{t('cassetteErrorIdentifiedAValidCassetteIsRequiredText3')}</p>
                  <p>{t('cassetteErrorIdentifiedAValidCassetteIsRequiredText4')}</p>
                </Accordion>

                <Accordion
                  state={state.accordion_10}
                  dispatch={() => dispatch({ type: 'accordion_10' })}
                  title='cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectly'
                >
                  <p>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText1')}</p>
                  <ul>
                    <li>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText2')}</li>
                    <li>
                      {t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText3')}

                      <ul>
                        <li>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText4')}</li>
                        <li>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText5')}</li>
                      </ul>
                    </li>
                    <li>
                      {t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText6')}

                      <br />
                      {t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText7')}
                    </li>
                    <li>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText8')}</li>
                    <li>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText9')}</li>
                    <li>{t('cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText10')}</li>
                  </ul>
                </Accordion>

                <Accordion
                  state={state.accordion_11}
                  dispatch={() => dispatch({ type: 'accordion_11' })}
                  title='generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyser'
                >
                  <p>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText1')}</p>

                  <p>
                    <strong>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText2')}</strong>
                  </p>
                  <p>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText3')}</p>
                  <ul>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText4')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText5')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText6')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText7')}</li>
                  </ul>

                  <p>
                    <strong>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText8')}</strong>
                  </p>
                  <p>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText9')}</p>
                  <ul>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText10')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText11')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText12')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText13')}</li>
                  </ul>

                  <p>
                    <strong>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText14')}</strong>
                  </p>
                  <p>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText15')}</p>
                  <ul>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText16')}</li>
                    <li>{t('generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText17')}</li>
                  </ul>
                </Accordion>

                <Accordion
                  state={state.accordion_12}
                  dispatch={() => dispatch({ type: 'accordion_12' })}
                  title='whatIsOvaCytesPrivacyPolicy'
                >
                  <p>{t('whatIsOvaCytesPrivacyPolicyText1')}</p>
                  <a href='/PrivacyPolicy.pdf' download={t('privacyPolicyDownload')}>
                    <span style={{ color: '#34AAF0' }}>{t('whatIsOvaCytesPrivacyPolicyText2')}</span>
                  </a>
                </Accordion>

                <br></br>
                <h4
                  className='mb-4'
                  style={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    borderBottom: '1px solid #4472C4',
                    fontSize: '20px',
                  }}
                >
                  {t('browseHelpTopics')}
                </h4>

                <div className='helpLinks' >
                  <Link className={`helpLink${darkMode ? '_dark' : ''}`} hidden={!eq} to='/fluke-scan-help'>
                    <div style={{color: theme.textcolor}}>{t('helpwithFlukeScan')}</div>
                  </Link>
                  <br></br>
                  <Link className={`helpLink${darkMode ? '_dark' : ''}`} hidden={!eq} to='/fec-scan-help' >
                  <div style={{color: theme.textcolor}}>{t('helpwithFECScan')}</div>
                  </Link>
                  <br></br>
                  <Link className={`helpLink${darkMode ? '_dark' : ''}`} hidden={!ca} to='/ca-scan-help'>
                    <div style={{color: theme.textcolor}}> {t('helpWithCaScan')}</div>
                  </Link>
                  <br></br>
                </div>

              </div>
                <SupportMaterial/>
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>

  )
}



const mapStateToProps = state => ({
  security: state.security,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
    },
    dispatch
  )
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Support))

