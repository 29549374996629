import ReactDOM from 'react-dom'

const Modal = ({ isShow, closeModal, children }) => {
  return ReactDOM.createPortal(
    <>
      <div
        onClick={closeModal}
        className={`custom-modal__backdrop ${
          isShow ? 'custom-modal__backdrop--show' : 'custom-modal__backdrop--hide'
        }`}
      />
      <div className={`custom-modal ${isShow ? 'custom-modal__show ' : 'custom-modal__hide'}`}>
        {children}
      </div>
    </>,
    document.getElementById('modal')
  )
}

export default Modal
