import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'


class Button extends Component {
    render() {
        const {
            styleClass,
            handleClick,
            value
        } = this.props;

        return(
            <button className={`btn ${styleClass}`} onClick={handleClick} >
                {this.props.t(value)}
            </button>
        );
    }
}

export default withTranslation()(Button);