import React, { Component} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { editScan, createScan, fetchpracticeproducts} from "../../redux/actions/scanActions";
import { withTranslation } from 'react-i18next'
import {ThemeContext} from '../../context/theme-context';

class CreateScan extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      prePopulate: false,
      species_scan_product_id: "",
      error: {},
      owner_name: "",
      animal_id: "",
      $uri: "",
      species: "",
      animalID: [{ animalName: "", comment: "" }],
      description: "",
      parent_batch: null,
      animalOwnerError: "",
      batchDescriptionError: "",
      animalIDError: "",
      animalSpeciesError: "",
      animalIdFormRowsCopies: 1,
      activeIndex: null,
      panelIsShown:-1,
      panelValue: null,
      product: null
    };
  }

  componentDidMount = async () => { 
  
    if (typeof this.props.location.prePopulate !== "undefined") {
      try {
        this.setState({
          ...this.props.location.prePopulate,
        });
        this.setState({ prePopulate: true });
      } catch (error) {
        this.setState({ error: error, show: true });
      }
    }
  };


  validate = () => {
    let isError = false;

    if (this.state.species.length === 0) {
      isError = true;
      this.setState({
        animalSpeciesError: this.props.t('aSingleSpeciesMustBeSelectedToSubmitTheScan'),
      });
    }
    if (this.state.owner_name.trim() === "") {
      isError = true;
      this.setState({
        animalOwnerError: this.props.t('pleaseEnterOwnerReference'),
      });
    }
    if (this.state.description.trim() === "" && this.state.animalID.length > 1) {
      isError = true;
      this.setState({
        batchDescriptionError: this.props.t('pleaseEnterBatchName'),
      });
    }
    if (
      !Object.values(this.state.animalID).every(x => x.animalName.trim() !== "") &&
      this.state.animal_id === ""
    ) {
      isError = true;
      this.setState({
        animalIDError: this.props.t('pleaseEnterAnimalName'),
      });
    }

    return isError;
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      animalOwnerError: "",
      batchDescriptionError: "",
      animalIDError: "",
      animalSpeciesError: "",
    });

    const err = this.validate();
    if (!err) {
      const scanRequest = {
        owner_name: this.state.owner_name,
        description: this.state.animalID.length > 1 ? this.state.description : "",
        animal_id: this.state.animalID,
        species: this.state.species,
        scan_product:this.state.species_scan_product_id,
      };

      const editRequest = {
        owner_name: this.state.owner_name,
        description: this.state.description,
        animal_id: this.state.animal_id,
        species: this.state.species,
        scan_product:this.state.species_scan_product_id,
      };
      this.state.prePopulate
        ? this.props.editScan(editRequest, this.state.$uri.replace(/[^\d.]/g, ""))
        : this.props.createScan(scanRequest);
    }
  };

  onSpeciesButtonClick = (e)=> {
    
    this.setState({
      product: null
    })
    this.setState({
      species: e.target.id,
      scan_product:e.target.name.split('/').pop(),
      
      species_scan_product_id:e.target.name.split('/').pop()// -1 gets ID number from scanproduct from practice/prodcuts
    });
  };

  changePanelStates(str, e){ 
    
      if(this.state.panelValue === str){
        this.setState({
          panelIsShown: this.state.panelValue,
          panelValue:''
        });
      }
      else{
        this.setState({
          panelIsShown: e.target.id,
          panelValue: str
        });
    }
  }

  onAnimalIDsubmit = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange(i, e) {
    const { name, value } = e.target;
    let animalID = [...this.state.animalID];
    animalID[i] = { ...animalID[i], [name]: value };
    this.setState({ animalID });
  }

  removeClick(i) {
    let animalID = [...this.state.animalID];
    animalID.splice(i, 1);
    this.setState({ animalID });
  }

  addClick() {
    this.setState(prevState => ({
      animalID: [...prevState.animalID, { animalName: "", comment: "" }],
    }));
  }

  setActiveAccordion = (index) => {

    if (this.state.activeIndex === index){
      this.setState({
        activeIndex: -1,
      });
    }
    else{
      this.setState({
        activeIndex: index,
      });
    }
  }; 

  animalIdFormRows = () => {
    return this.state.animalID.map((el, i) => (
      <ThemeContext.Consumer>
      {({theme}) => (
      <div className={window.innerWidth > 700 ? "row justify-content-start": "row justify-content-start mb-3"} key={i}>
        <div className="col-md-2  scan-inputs-properties-title">{this.props.t('animalName')}</div>
        <div className={window.innerWidth > 700 ? "col-md-4 scan-inputs-properties" : "row pl-4 pr-4 scan-inputs-properties" }>
          <input
            name="animalName"
            style={{backgroundColor: theme.inputcolor,color: theme.textcolor}} 
            className="form-control form-control-lg"
            type="text"
            placeholder={this.props.t('insertName')}
            value={el.animalName || ""}
            onChange={this.handleChange.bind(this, i)}
          />
          {this.state.animalIDError && (
            <div className="invalid-feedback d-block mb-1">{this.state.animalIDError}</div>
          )}
        </div>
        <div className={window.innerWidth > 700 ? "col-md-4 scan-inputs-properties" : "row pl-4 pr-4 scan-inputs-properties" }>
          <input
            style={{backgroundColor: theme.inputcolor,color: theme.textcolor}} 
            name="comment"
            className="form-control form-control-lg"
            type="text"
            value={el.comment || ""}
            placeholder={this.props.t('insertAdditionalComments')}
            onChange={this.handleChange.bind(this, i)}
          />
        </div>
        {this.state.animalID.length > 1 && (
          <div className="col-md-1 scan-inputs-properties">
            <input style={{ backgroundColor: theme.foreground, color: theme.textcolor }} type="button" value="X" onClick={this.removeClick.bind(this, i)} />
          </div>
        )}
        
      </div>
        )}
        </ThemeContext.Consumer>
    ));
  };

  editScanForm = () => {
    return (
      <ThemeContext.Consumer>
      {({theme}) => (
      <div className="row justify-content-start gap">
        <div className="col-md-2 scan-inputs-properties-title">{this.props.t('animalName')}</div>
        <div className="col-md-4 scan-inputs-properties">
          <input
            style={{backgroundColor: theme.inputcolor,color: theme.textcolor}} 
            name="animal_id"
            className="form-control form-control-lg"
            type="text"
            placeholder={this.props.t('insertName')}
            value={this.state.animal_id}
            onChange={e => this.onChange(e)}
          />
        </div>
        <div className="col-md-4 scan-inputs-properties">
          <input
            style={{backgroundColor: theme.inputcolor,color: theme.textcolor}} 
            name="description"
            className="form-control form-control-lg"
            type="text"
            placeholder={this.props.t('insertAdditionalComments')}
            value={this.state.description === null ? "" : this.state.description}
            onChange={e => this.onChange(e)}
          />
        </div>
        <hr className="hr-create-scan-species"/>
      </div>
      )}
        </ThemeContext.Consumer>
    );
  };

  render() {
  if (this.state.scan_product) {
    var scan_product = true;
  }
  //this.sortPracticeData() // this way we call it every render..this is done as in React.<js> its not possible to call code pre render 
  const makeButtons = (arr, test_type) => { // create array of buttons given a species type -> determined by /practice/products  
    return arr.map((speciesStr) =>

    <ThemeContext.Consumer>
      {({theme, darkMode}) => (
    <div key={speciesStr['$ref']} className={`scan-inputs-species ${test_type}-button-cs`}> 
          <button 
            name={speciesStr['$ref']} // takes out name of species such as Bovine Fluke
            id={speciesStr['$ref'].split('/').pop()} // takes scan product id integer  
            key={speciesStr['$ref']} // takes scanproduct e.g /scanproduct/1
            type="button"
            
            onClick={e => this.state.parent_batch === null && this.onSpeciesButtonClick(e)}
            className={`btn ${
              scan_product === true ? this.state.scan_product.$ref === speciesStr['$ref'] || this.state.species === speciesStr['$ref'].split('/').pop()
              ? "active-button"
            : "" : this.state.species === speciesStr['$ref'].split('/').pop()} ? "active-button" : ""
            }`
            
          }
          >
            {speciesStr['description']} 
          </button>
    </div>
      )}
    </ThemeContext.Consumer>
  );
}
  this.credtTypeproductAuth = JSON.parse(localStorage.getItem('credtTypeproductAuth'))
  this.largestCreditType = JSON.parse(localStorage.getItem('largestCreditType'))
  let creditType = Object.keys(this.credtTypeproductAuth)
  // creats an array of scanAccordians each containing one mapped to a credit type e.g Fluke and containign product data from practice/products
 
  if(creditType){creditType = [creditType[0], creditType[2], creditType[1]];}

  const makeTabs = creditType.map((creditTypes, index) =>
  <ThemeContext.Consumer>
  {({theme, darkMode}) => (
  <div key={index+'0'} className={`${darkMode ? 'scan-inputs-species_dark' : "scan-inputs-species"} ${creditTypes}-button-cs`}> 
      <button key={index} type="button" id={index} onClick={this.changePanelStates.bind(this, creditTypes)} className={`btn 
              ${index==this.state.panelIsShown ? 'active-button' : ''}`}>
              {creditTypes}
      </button>
  </div>
  )}
  </ThemeContext.Consumer>
  );
    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <form>
        <div className="container" id="scan-container" style={{backgroundColor: theme.foreground,color: theme.textcolor}}>
          {this.state.prePopulate && (
            <button onClick={() => this.props.history.push("/pending-scans")} className={`backBtn${darkMode ? '_dark' : ''}`}>
              {this.props.t('back')}
            </button>
          )}
          <div className="row">
            <div className="col create-scan-title">
              {this.state.prePopulate ? (
                <span  style={{color: theme.textcolor}} id="create-scan-title">{this.props.t('editScan')}</span>
              ) : (
                <span  style={{color: theme.textcolor}} id="create-scan-title">{this.props.t('createScan')}</span>
              )}
            </div>  
          </div>
          <div className="row justify-content-start">
            <div className="col-md-6">
              <div className="row justify-content-start">
                <div className="col-md-4 scan-inputs-properties-title">{this.props.t('ownerReference')}</div>
                <div className="col-md-8 scan-inputs-properties" style={{color: theme.textcolor}}>
                  <input
                    name="owner_name"
                    style={{backgroundColor: theme.inputcolor,color: theme.textcolor}}
                    value={this.state.owner_name}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder={this.props.t('ownerReference')}
                    onChange={e => this.onChange(e)}
                  />
                  {this.state.animalOwnerError && (
                    <div className="invalid-feedback d-block">{this.state.animalOwnerError}</div>
                  )}
                </div>
              </div>
            </div>
            {this.state.prePopulate ? (
              <div className="col-md-6"></div>
            ) : (
              this.state.animalID.length > 1 && (
                <div className="col-md-6">
                  <div className="row justify-content-start">
                    <div className={window.innerWidth > 760 ? "col-md-4" : 'mt-4 col-md-4'}><b>{this.props.t('batchName')}</b></div>
                    <div className="col-md-8 scan-inputs-properties">
                      <input
                        style={{backgroundColor: theme.inputcolor,color: theme.textcolor}} 
                        name="description"
                        value={this.state.description}
                        className="form-control form-control-lg"
                        type="text"
                        placeholder={this.props.t('batchName')}
                        onChange={e => this.onChange(e)}
                      />
                      {this.state.batchDescriptionError && (
                        <div className="invalid-feedback d-block">
                          {this.state.batchDescriptionError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <hr className="hr-create-scan-species" />
          <div className="row justify-content-start" id="create-scan-species-validation">
            <div className="col-md-2" />
            <div className="col-md-10">
              {this.state.animalSpeciesError && (
                <div className="invalid-feedback d-block">{this.state.animalSpeciesError}</div>
              )}
            </div>
            <div className="row justify-content-space-evenly">
            <div className="col-md-2 scan-inputs-species-title">{this.props.t('testType')}</div>
            </div>
            <div className="row justify-content-space-evenly">
            <div className={`col-md-2 scan-inputs-species${darkMode ? '_dark' : ''}`}>
                {makeTabs}
            </div>
           </div>
           {(this.state.panelIsShown > -1) && ( 
                  <div className="" style={{minHeight:(this.largestCreditType * 80)}}>
                  {makeButtons(this.credtTypeproductAuth[this.state.panelValue],this.state.panelValue)}
                  </div> 
           )}  
          
          <div>
          </div>  
          </div>
          {this.state.prePopulate ? (
            <>{this.editScanForm()}</>
          ) : (
            <>
              {this.animalIdFormRows()}
              <div className="row">
              <input
                type="button"
                className="col-md-2 btn btn-primary add-more"
                value={this.props.t('addAnotherAnimal')} 
                onClick={this.addClick.bind(this)}
              />
              </div>
            </>
          )}
          
          <hr className="hr-create-scan-species" />
          <div>
   
          <div className="row" id="scan-input-footer" style={{backgroundColor: theme.foreground,color: theme.textcolor}} >
            <div className="col-md-2 offset-md-10 scan-submit-button">
              <button type="submit" className="btn btn-primary" onClick={this.onSubmit}>
                {this.state.prePopulate ? this.props.t('submit') : this.props.t('saveScan')}
              </button>
            </div>
          </div>
        </div>
      </div>
      </form>
      )}
    </ThemeContext.Consumer>
    );
  }
  
}

const mapStateToProps = state => ({
  security: state.security,
  product: state.products,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchpracticeproducts
    },
    dispatch
  )
}

export default withTranslation()(connect(mapStateToProps, { mapDispatchToProps, fetchpracticeproducts, editScan, createScan })(CreateScan));
