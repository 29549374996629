import React, { Component } from 'react'
import { imageUploadMessage, getPracticeData } from '../../redux/actions/deviceSettingsActions'
import { connect } from 'react-redux'
import { backInstance } from '../../config'
import { withTranslation } from 'react-i18next'

class DeviceSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      description: '',
      active: '',
      letterhead: '',
      previewLogo: '',
      newLogoUploaded: false,
      newLogo: '',
      errorImage: false,
      practiceLogo: '',
      isUploading: false,
    }
    this.onImageChange = this.onImageChange.bind(this)
  }

  getLogo = async () => {
    await this.props.getPracticeData()
    this.setState({
      practiceLogo: this.props.device.myPractice,
    })
  }

  componentDidMount() {
    this.getDeviceData()
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.previewLogo)
  }

  getDeviceData = async () => {
    try {
      this.getLogo()
    } catch (error) {
      // window.alert("Get Device Data Error");
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onImageChange = event => {
    this.setState({
      errorImage: false,
    })
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      // Check if file size exceeds a certain limit (1MB)
      const maxSizeInBytes = 1 * 1024 * 1024 // 1MB
      if (file.size > maxSizeInBytes) {
        this.setState({
          errorImage: true,
        })
        return alert('File size exceeds limit of 1MB')
      }
      this.setState({
        previewLogo: URL.createObjectURL(event.target.files[0]),
        newLogo: event.target.files[0],
        newLogoUploaded: true,
        errorImage: false,
      })
    }
  }

  onSubmit = async e => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('upload', this.state.newLogo)
    this.setState({
      isUploading: true,
    })
    try {
      await backInstance.post(`/practice/mine/logo`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      })
      this.props.imageUploadMessage(this.props.t('imageUploadSuccess'), 'Success')
      await this.getLogo()
      this.setState({
        newLogoUploaded: false,
        newLogo: '',
        previewLogo: '',
      })
    } catch (error) {
      this.props.imageUploadMessage(this.props.t('imageUploadFailed'), 'Warning')
    } finally {
      this.setState({
        isUploading: false,
      })
    }
  }

  render() {
    return (
      <div className='company-logo'>
        <div>
          {(this.state.newLogoUploaded || this.state.practiceLogo) && (
            <img
              alt='logo'
              className='company-logo__preview'
              src={this.state.previewLogo || this.state.practiceLogo}
            />
          )}
          <div className='d-flex align-items-center' style={{ gap: '5px', flexWrap: 'wrap' }}>
            <label
              style={{
                border: '1px solid #212529',
                borderRadius: '5px',
                padding: '5px 10px',
                margin: 0,
                cursor: 'pointer',
              }}
              htmlFor='text'
            >
              {this.props.t('chooseFile')}
            </label>
            <input
              id='text'
              type='file'
              accept='.png, .jpg, .jpeg'
              name='myImage'
              onChange={e => this.onImageChange(e)}
              style={{ display: 'none' }}
            />

            <span
              style={{
                fontSize: '14px',
              }}
            >
              {this.state.newLogo ? this.state.newLogo?.name : this.props.t('noFileChosen')}
            </span>
          </div>
          <div className='company_logo__img-req'>
            <p>{this.props.t('imageUploadReq')}</p>
            <p
              style={{
                color: this.state.errorImage ? 'red' : 'black',
              }}
            >
              {this.props.t('imageSize')}
            </p>
          </div>
        </div>
        <button
          disabled={!this.state.newLogo && !this.state.isUploading}
          type='submit'
          className='company-logo__button'
          onClick={this.onSubmit}
        >
          {this.props.t(this.state.isUploading ? 'uploading' : 'update')}
        </button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  device: state.device,
})

export default withTranslation()(
  connect(mapStateToProps, { imageUploadMessage, getPracticeData })(DeviceSettings)
)
