import React, { Component } from "react";

class Select extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(e) {
    if(this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() {
    const {
      className,
      placeholder,
      options,
      name
    } = this.props;
    return(
        <select className={className} placeholder={placeholder} name={name} onChange={ this.handleChange }>
          {options.map(option => (
            <option value={option.value} key={option.value}>{option.label}</option>
          ))}
        </select>
    )
  }
}

export default Select;