import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import ThumbWithText from '../../../components/ThumbWithText'
import {ThemeContext} from '../../../context/theme-context';



const FlukeScanHelp = () => {
  const { t } = useTranslation();
  var eq = false
  var la = false
  var ca = false
  if(localStorage.getItem('practiceDocumentation')){var cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
  eq = cache['eq'];la = cache['la'];ca = cache['ca']
  
{/* <div className='white-background-customers about-us-container mx-auto pb-4'> */}
  return (
    <ThemeContext.Consumer>
    {({ theme, darkMode }) => (
    <div className='dashboard-container cow-bg' style={{ height: 'auto', background: theme.foreground, color: theme.textcolor}}>
      <div className='white-background about-us-container mx-auto pb-4'
        style={{
          alignContent: 'center',
          // background: '#f2f2f2c0',
          padding: '0px 25px 25px',
          background: theme.foreground,
          color: theme.textcolor
        }}
      >
        <h3 className='' style={{ paddingTop: '120px', color: theme.textcolor }}>
          {t('helpwithFlukeScan')}
        </h3>
        <div className='' style={{ paddingTop: '20px', color: theme.textcolor }}>
        <p>
            {t('thisIsAhelpPageFluke')}
            <br /> <br />
            {t('pleaseFollowBelow')}
            <br /> <br />
            {t('ovacyteSupportIsHereToHelp')}{' '}
            <Link to='/contact'>
              <u style={{ color: '#009FEE' }}>{t('contactUs')}</u>
            </Link>  
          </p>
        </div>

        {/* <div
          className='row'
          style={{
            color: '#212529',
            fontSize: '1rem',
            fontWeight: '400',
            margin: 'auto',
          }}
        > */}

        <div
          className='row'
          style={{
            color: '#212529',
            fontSize: '1rem',
            fontWeight: '400',
            margin: 'auto',
            color: theme.textcolor,
          }}
          >
          <div className='col-12 col-md-10 mr-5 pl-0'>
            <h4
              className='mb-4'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
            >
            </h4>
            <div className='ThumbWithText' style={{ paddingTop: '12px' }}>
            <ThumbWithText explainText='TestVid.mp4' url='Fluke_Sample_Prep.m4v' thumbnail='Fluke_Sample_Prep_Graphic.png'>
            </ThumbWithText>
            <br></br>
            </div>
            <div className='fecHowToList'  >
            <ol>
                <li>{t('placeAnEmptyCleanSamplePotOntoTheScale')}</li>
                <li>{t('flukePointTwoWeighFecalMatter')}</li>
                <li>{t('nowFillTheSamplePotWithWaterUpToThe50MlMark')}</li> 
                <li>{t('mixTheSampleVeryWellWithTheForcepsToEnsureThatAllEggsAreRealisedFromTheFaecalMatter')}</li>
                <li>{t('placeTheGreyFilterOnTopOfTheCoralFilterNumbers1and2PointingUpwards')} </li>
                <li>{t('flukePointSixPourFiltrate')}</li>
                <li>{t('NowPassTheSecondHalfThroughTheFilterStaceAndTapAnother30Times')}</li>
                <li>{t('flukePointEightHalfFillSamplePot')}</li>
                <li>{t('flukePointNineRemoveCoralFilter')}</li>
                <li>{t('flukePointTen')}</li>
                <li>{t('flukePointeEleven')}</li>
      
            </ol>
            </div>
            <div>
              <br></br>
              <br></br>
            </div>
            <h4
              className='mb-4'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
            >
            {t('browseHelpTopics')}
            </h4>
            
            <div className='helpLinks'> 
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/fec-scan-help' hidden={!eq}>
              <div style={{color: theme.textcolor}}>{t('helpwithFECScan')}</div>
              </Link>
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/ca-scan-help' hidden={!ca}>
                <div style={{color: theme.textcolor}}>{t('helpWithCaScan')} </div>
              </Link>
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/support'>
              <div style={{color: theme.textcolor}}>{t('frequentlyAskedQuestions')}</div>
              </Link>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
  </ThemeContext.Consumer>
  )
}

const mapStateToProps = state => ({
  security: state.security,
  practiceDocumentation: state.practiceDocumentation,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch)
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(FlukeScanHelp)
