import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'
import {
  fetchScan,
  selectResultHistory,
  fetchScanSpecies,
  getPracticeDataIndex,
} from '../../redux/actions/fetchResultHistoryActions'
import { getPracticeData } from '../../redux/actions/deviceSettingsActions'
import { editScanComment, getScanComment } from "../../redux/actions/scanActions";
import { withTranslation } from 'react-i18next'
import { FaAlignLeft, FaAlignRight, FaCloudDownloadAlt } from "react-icons/fa";
import {ThemeContext} from '../../context/theme-context';
import generatePDF, { Margin } from "react-to-pdf";


class DetailedReport extends Component {
  constructor() {
    super()
    this.state = {
      filterText: '',
      practiceEmblemState: false,
      showDetailPreview: false,
      selectedRow: {},
    }
  }

  async componentDidMount() {
    const { fetchScan, selectRH, fetchedPracticeIndex } = this.props
    await this.props.getPracticeDataIndex()
    await fetchScan(selectRH.scan_id)
    await this.props.getPracticeData()
  }

  async componentDidUpdate(prevProps) {
    const { selectRH, getScanComment } = this.props
    if (prevProps.fetchedScan !== this.props.fetchedScan) {
      await this.props.fetchScanSpecies(this.props.fetchedScan.species.$ref.split('/').at(-1))
      await getScanComment(selectRH.scan_id)

    }

  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit() {

    let textArea = document.getElementById('textArea');
    let input = textArea.value;
    let comment_instruction_span = document.getElementById('comment_instruction_span');
    let comment_button_text_span = document.getElementById('comment_button_text_span');
    let current_comment_span = document.getElementById('current_comment_span');

    const editRequest = {
      comment: input
    };
    const { selectRH } = this.props
    this.props.editScanComment(editRequest, selectRH.scan_id);

    current_comment_span.innerText = input

    if (comment_instruction_span.innerText == this.props.t('addComment')) {
      comment_instruction_span.innerText = this.props.t('editComment')
    }
    if (comment_button_text_span.innerText == this.props.t('add')) {
      comment_button_text_span.innerText = this.props.t('edit')
    }

  };


  myTextArea = () => {
    const { rhDetails, fetchedScan } = this.props
    if (
      fetchedScan === undefined ||
      fetchedScan == [] ||
      fetchedScan.created === undefined ||
      rhDetails.details.length < 1
    ) {
      return <>
      </>
    } else {
      return (
        <ThemeContext.Consumer>
        {({theme, darkMode}) => (
        <>
        
          <textarea
            id='textArea'
            name="comment"
            className="report-comments"
            type="text"
            wrap='soft'
            placeholder={fetchedScan.comment}
            title='Insert Additional Comments'
            style={{backgroundColor: theme.inputcolor, color:theme.textcolor}}
          />
        </>
      )}
      </ThemeContext.Consumer>
      )
    }
  }

  detailColumn = () => {
    const { rhDetails, fetchedScan, selectRH, fetchedScanSpecies, fetchedPracticeIndex, fetchedPracticeLabel } = this.props
    if (
      fetchedScan === undefined ||
      fetchedScan == [] ||
      fetchedScan.created === undefined ||
      fetchedScan.description === undefined ||
      rhDetails.details.length < 1
    ) {
      return <></>
    } else {
      const imageText = fetchedScan.scan_images.length === 1 ? 'image' : 'images'
      return (
        <>
          <div className=''>
            <div>
              <div className='flex-wrapper-report'>
                <span>{this.props.t('totalVolumeAnalysed')}: </span>{' '}
                <span>
                  <>{rhDetails.details[0].total_volume} ml</>
                  {/* <span className='ml-1'>
                    ({fetchedScan.scan_images.length} {imageText})
                  </span> */}
                </span>
              </div>
            </div>
          </div>

          <div className='col-12 p-0'>
            <table className='dr-table individual-report-table'>
              <tbody>
                {rhDetails.details.map(detail => (
                  <tr key={detail.description}>
                    <td>{detail.display_name}</td>
                    <td>{detail.eggs_per_gram > 0 ? <strong>{detail.eggs_per_gram}</strong>: detail.eggs_per_gram}</td>                  
                  </tr>
                ))}
              </tbody>
              <tbody className='dr-table-body'></tbody>
            </table>
          </div>
        </>
      )
    }
  }

  indvReportDetailColumn = () => {

    const { rhDetails, fetchedScan, selectRH, fetchedScanSpecies, fetchedPracticeIndex} = this.props

    if (
      fetchedScan === undefined ||
      fetchedScan == [] ||
      fetchedScan.created === undefined ||
      fetchedScan.description === undefined ||
      rhDetails.details.length < 1
    ) {
      return <></>
    } else {
      const imageText = fetchedScan.scan_images.length === 1 ? 'image' : 'images'

      return (
        <ThemeContext.Consumer>
        {({theme, darkMode}) => (
          
        <div className='mt-4 grid-container'>
          <div className='grid-item left-column'>
            <span className='left-col-text'>{this.props.t('scanID')}: </span>
            <span id='br-id'> {selectRH.scan_id}</span>
          </div>
          <div className='grid-item right-column'>
            <span className='left-col-text'>{this.props.t('testDate')}:</span>
            <span id='br-date'>
              {new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(fetchedScan.created.$date)}
            </span>
          </div>
          <div className='grid-item left-column'>
            <span className='left-col-text'>{this.props.t('ownerReference')}: </span>
            <span> {fetchedScan.owner_name}</span>
          </div>
          <div className='grid-item right-column'>
            <span className='left-col-text'>{'Practice'}:</span>
            <span> {fetchedPracticeIndex ? fetchedPracticeIndex.id ? fetchedPracticeIndex.id.label : '':''}</span>
          </div>
          <div className='grid-item left-column'>
            <span className='left-col-text'>{this.props.t('animalName')}:</span>
            <span> {fetchedScan.animal_id}</span>
          </div>
          <div className='grid-item left-column'>
            <span className='left-col-text'>{this.props.t('species')}: </span>
            <span
              style={{
                textTransform: 'capitalize',
              }}
            >
              {fetchedScanSpecies}
            </span>
          </div>
          <div className='grid-item left-column'>
            <span className='left-col-text'>{this.props.t('description')}: </span>
            <span className='flex-wrapper-report_text'> {fetchedScan.description}</span>
          </div>
        </div>
        )}
        </ThemeContext.Consumer>
      );


    }
  }

  toggleEmblem = () => {
    this.setState({ practiceEmblemState: !this.state.practiceEmblemState })
  }

  setEmblemCentre = () => {
    this.setState({ practiceEmblemState: false })
  }

  setEmblemRight = () => {
    this.setState({ practiceEmblemState: true })
  }

  downloadPDF(){

    let down_section = document.getElementById("section-to-print")
    let print_style = document.getElementById("print-style")
    let non_print_section = document.getElementById('no-print')
    let detailed_report_wrapper = document.getElementById('detailed-report-wrapper')
    let scan_id = ''
    let date = ''
    
    if(document.getElementById('br-id')) 
    {
      scan_id = document.getElementById('br-id').innerText.replace(/\s/g, '');
      date = document.getElementById('br-date').innerText.replace(/\s/g, '');
      let filename = 'Scan-Report_' + scan_id + '_' + date 
      non_print_section.setAttribute('hidden', 'true')
      
      down_section.style.backgroundColor = "white"
      print_style.style.backgroundColor= "white"
      down_section.style.color = "black"
      print_style.style.color= "black"

      generatePDF(() => down_section, {
        method: "save",
        filename: filename,
        page: { margin: Margin.MEDIUM },
      }); 

      non_print_section.removeAttribute('hidden')
      down_section.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
      print_style.style.backgroundColor= detailed_report_wrapper.style.backgroundColor
      down_section.style.color = detailed_report_wrapper.style.color
      print_style.style.color= detailed_report_wrapper.style.color
    }
    
  }

  render() {
    return (
      <>
      <ThemeContext.Consumer>
        {({theme, darkMode}) => (
        <div className='dr-container' >
          <div className='white-background-customers'>
            <div className='table-search-wrapper'>
              <h3 style={{ color:theme.textcolor}} className='dr-header'>{this.props.t('individualReport')}</h3>
            </div>
          </div>
          <div className='dr-button-row '>
            <NavLink to='/results-history'>
              <button type='button' className='dr-register-button dr-back'>
                {this.props.t('back')}
              </button>
            </NavLink>
            <div className='container d-flex justify-content-end'>
              <div className='row align-buttons'>
                <div className='col d-flex justify-content-center'>
                  <FaAlignLeft style={{color:theme.textcolor}} className='align-center' size={'100%'} onClick={this.setEmblemCentre}></FaAlignLeft>
                </div>

                <div className='col'>
                  <FaAlignRight style={{color:theme.textcolor}} className='align-right' size={'100%'} onClick={this.setEmblemRight}></FaAlignRight>
                </div>
              </div>

            </div>
            {this.props.rhDetails !== null && this.props.rhDetails.details.length !== 0 && (
              <div className='container d-flex justify-content-end' style={{padding:'0px'}}>
                <div className='row rh-print-buttons justify-content-end'>
                  <div className=''>
                    {/* <FaCloudDownloadAlt className='' size={'100%'} onClick={this.setEmblemRight}></FaCloudDownloadAlt> */}   
                    <button onClick={this.downloadPDF} type='button' className='dr-register-button dr-print'>
                      {this.props.t('📥	 PDF')}
                    </button>
                  </div>
                  <div className=''>
                    <button onClick={window.print} type='button' className='dr-register-button dr-print'>
                      {this.props.t('print')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='detailed-report-wrapper' id='detailed-report-wrapper' style={{ color:theme.textcolor, backgroundColor: theme.background}} >
            <div
              className='dr-print-wrapper section-to-print-detailed-width'
              id='section-to-print'
              style={{ color:theme.textcolor, backgroundColor: theme.background}}
            >
              <div className='container' >
                <div id='print-style' className='mx-auto print-styling' style={{ color:theme.textcolor, backgroundColor: theme.background}}>
                  {this.props.rhDetails !== null && this.props.rhDetails.details.length === 0 ? (
                    <p className='individual-report-title'>{this.props.t('noReportAvailable')}.</p>
                  ) : (
                    <>
                      {/* This is the practice emblem  */}
                      {this.props.device.myPractice && (
                        <div className='d-flex align-items-end report-logo'>
                          {this.state.practiceEmblemState ? (
                            <div className='col'></div>
                          ) : (<></>)}
                          <img src={this.props.device.myPractice} alt='' />
                        </div>
                      )}
                    </>
                  )}


                  {this.indvReportDetailColumn()}

                  <div className='table-search-wrapper'>
                    <h4 className='report-header-text'>{this.props.t('individualReport')}</h4>
                  </div>
                  {/* This is the line */}
                  <h4
                    className='mb-4'
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '15px',
                      borderBottom: '1px solid #4472C4',
                      fontSize: '20px',
                    }}
                  >
                  </h4>

                  <div className='table-search-wrapper'>
                    <h5 className='report-header-text-left'>{this.props.t('summary')}</h5>
                  </div>

                  {this.detailColumn()}

                  {this.props.rhDetails !== null && this.props.rhDetails.details.length !== 0 && (
                    <div>
                      <div className='ml-5 mt-3 parasite-text'>
                      </div>
                      <br />
                      {this.props.rhDetails.details.map(detail => {
                        const parasiteName = detail.description[0].toUpperCase() + detail.description.slice(1)
                        return (
                          detail.buckets !== '' && (
                            <div className='mb-3 parasite-text' key={detail.description}>
                              <p className='mb-1'>
                                {detail.description === 'anoplocephala'
                                  ? detail.display_name
                                  : parasiteName.replaceAll('_', ' ')}
                              </p>
                              <div className='api-text ml-5'>{detail.buckets}</div>
                            </div>
                          )
                        )
                      })}
                  
                    </div>

                  )}

                  <div className='table-search-wrapper'>
                    <h4 className='report-header-text'>{this.props.t('comments')}</h4>
                  </div>


                  <div className='flex-wrapper-report'>
                    <span><b>{this.props.t('currentScanComment')}: </b><span id='current_comment_span'> {this.props.fetchedScan.comment}</span></span>

                  </div>
                  <h4
                    className='mb-4'
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '15px',
                      borderBottom: '1px solid #4472C4',
                      fontSize: '20px',
                    }}
                  >
                  </h4>

                </div>

                <div id='no-print'  >
                  <div className="noprint scan-inputs-properties">
                    <span id='comment_instruction_span' >{this.props.fetchedScan.comment ? (this.props.t('editComment')) : this.props.t('addComment')}</span>
                  </div>
                  <div className='mr-5 pr-5 noprint'>
                    {this.myTextArea()}
                    <div className='noprint'>
                      <button id='submitButton' onClick={this.onSubmit.bind(this)} type='button' className='dr-submit-button'>
                        <span id='comment_button_text_span' > {this.props.fetchedScan.comment ? (this.props.t('edit')) : this.props.t('add')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      )}
      </ThemeContext.Consumer>
      </>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
  selectRH: state.selectRH,
  rhDetails: state.rhDetails,
  fetchComment: state.getScanComment,
  fetchedScan: state.scan.scan,
  fetchedScanSpecies: state.species?.scan?.label?.replaceAll('_', ' '),
  fetchedPracticeIndex: state.getPracticeDataIndex,
  fetchedImages: state.images.images,
  device: state.device,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchScan,
      selectResultHistory,
      fetchScanSpecies,
      getPracticeDataIndex,
      getPracticeData,
      editScanComment,
      getScanComment,
    },
    dispatch
  )
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DetailedReport))
