import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Gallery } from 'react-grid-gallery'
import { bindActionCreators, compose } from 'redux'
import { withRouter, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward } from '@fortawesome/free-solid-svg-icons'
import { fetchImages, clearImages } from '../../redux/actions/fetchResultHistoryActions'
import { withTranslation } from 'react-i18next'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import {ThemeContext} from '../../context/theme-context';
export class ImageGallery extends Component {
  constructor() {
    super()
    this.state = {
      index: -1,
    }
  }

  async componentDidMount() {
    const { selectRH, fetchImages, clearImages } = this.props
    clearImages()
    if (this.props.location.state) {
      fetchImages(selectRH.scan_id, this.props.location.state.parasite)
    } else {
      fetchImages(selectRH.scan_id)
    }
  }

//  Production
  makePhotoList(images) {
    return images.map(item => {
      const photo = {
        src: `/api${item.src}`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        thumbnail: `/api${item.src}`,
      }
      return photo
    })
  }

  // //Staging;
  // makePhotoList(images) {
  //   return images.map(item => {
  //     const photo = {
  //       src: `https://api.staging.telenostic.com${item.src}`,
  //       // src: `/api${item.src}`,
  //       thumbnailWidth: 200,
  //       thumbnailHeight: 150,
  //       thumbnail: `https://api.staging.telenostic.com${item.src}`,
  //       // thumbnail: `/api${item.src}`,
  //     }
  //     return photo
  //   })
  // }

  // //Testing;
  // makePhotoList(images) {
  //   return images.map(item => {
  //     const photo = {
  //       src: `https://api.test.telenostic.com${item.src}`,
  //       // src: `/api${item.src}`,
  //       thumbnailWidth: 200,
  //       thumbnailHeight: 150,
  //       thumbnail: `https://api.test.telenostic.com${item.src}`,
  //       // thumbnail: `/api${item.src}`,
  //     }
  //     return photo
  //   })
  // }

  handleSelect = (imagelist, index, item, event) => {
    imagelist[index].isSelected = !imagelist[index].isSelected
    if(imagelist[index].isSelected){
      imagelist[index].orientation = 2
    }
  };

  handleClick = index => this.setState({ index })
  handleClose = () => this.setState({ index: -1 })
  handleMovePrev = prevIndex => this.setState({ index: prevIndex })
  handleMoveNext = nextIndex => this.setState({ index: nextIndex })

  render() {
    const images = this.makePhotoList(this.props.fetchedImages)
    // console.log(images)
    const currentImage = images[this.state.index]
    const nextIndex = (this.state.index + 1) % images.length
    const nextImage = images[nextIndex] || currentImage
    const prevIndex = (this.state.index + images.length - 1) % images.length
    const prevImage = images[prevIndex] || currentImage

    for(let i =0; i < images.length; i++){
      images[i]['isSelected'] = false
    }
    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <div>
        <div className='white-background-customers' >
          <div className='table-search-wrapper'>
            <h3 className='header-worklist' style={{backgroundColor: theme.foreground,color: theme.textcolor}}>{this.props.t('imageGallery')}</h3>
            <p className='p-worklist'>
              <NavLink to='/results-history' >
                <FontAwesomeIcon style={{backgroundColor: theme.foreground,color: theme.textcolor}} icon={faBackward} size='1x' /> <p style={{backgroundColor: theme.foreground,color: theme.textcolor}} >{this.props.t('back')}</p>
              </NavLink>
            </p>
          </div>
          <div className='row rh-table-wrapper'>
            <div className='col-xl-12 col-12'>
              <Gallery images={images} enableImageSelection={true} onClick={this.handleClick} onSelect={this.handleSelect.bind(this,images)}/>
              {!!currentImage && (
                <Lightbox
                  mainSrc={currentImage.src}
                  mainSrcThumbnail={currentImage.src}
                  nextSrc={nextImage.src}
                  nextSrcThumbnail={nextImage.src}
                  prevSrc={prevImage.src}
                  prevSrcThumbnail={prevImage.src}
                  onCloseRequest={this.handleClose}
                  onMovePrevRequest={() => this.handleMovePrev(prevIndex)}
                  onMoveNextRequest={() => this.handleMoveNext(nextIndex)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      )}
      </ThemeContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  selectRH: state.selectRH,
  fetchedImages: state.images.images,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchImages,
      clearImages,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(ImageGallery))
