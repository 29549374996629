export const translationEn = {
  //Login
  loginToOvacyte: 'Login to OvaCyte',
  username: 'Username',
  password: 'Password',
  logIn: 'Log in',
  //Singup
  insertUsername: 'Insert username',
  insertPassword: 'Insert password',
  login: 'Login',
  usernameMustBeAtLeast6Characters: 'Username must be at least 6 characters',
  emailMustBeAtLeast6Characters: 'Email must be at least 6 characters',
  notAValidEmail: 'Not a valid email',
  passwordMustBeAtLeast8Characters: 'Password must be at least 8 characters',
  createAccount: 'Create account',
  alreadyHaveAnAccount: 'Already have an account?',
  email: 'Email',
  insertAddress: 'Insert address',
  signUp: 'Sign Up',
  //ForgotPassword
  emailMustMoreThan3Characters: 'Email must be more than 3 characters',
  forgotPassword: 'Forgot password?',
  enterYourEmailAddressToResetYourPassword: 'Enter your email address to reset your password.',
  enterEmailAddress: 'Enter email address',
  submit: 'Submit',
  back: 'Back',
  //VerifyEmail
  verifyYourEmail: 'Verify your email',
  anEmailHasBeenSentTo: 'An email has been sent to',
  withALinkToVerifyYourAccount: 'with a link to verify your account',
  ifThisIsNotYourEmailPlease: 'If this is not your email, please',
  updateIt: 'update it',
  ifYouHaveNotReceivedAnEmailAfterAFewMinutesPleaseCheckYourSpamFolder:
    'If you have not received an email after a few minutes, please check your "Spam" folder.',
  resendEmail: 'Resend Email',
  contactSupport: 'Contact Support',
  //DeviceSettings
  uploading: 'Uploading...',
  imageUploadSuccess: 'Image has been uploaded successfully',
  imageUploadFailed: 'Failed to upload image',
  imageUploadReq: 'Images (JPG, JPEG, PNG)',
  imageSize: 'Size of image should not be more than 1MB',
  deviceSettings: 'Device Settings',
  description: 'Description',
  location: 'Location',
  comms: 'Comms',
  active: 'Active',
  usage: 'Usage',
  letterhead: 'Letterhead',
  info: 'Info',
  currentBatchScanComment: 'Current Batch Scan Comment',
  commentHasBeenChangedSuccessfully: 'Comment has been changed successfully',
  currentScanComment: 'Current Scan Comment',
  commentNotAcceptedPleaseTryAgain: 'Comment not accepted, Please Try again',
  editComment: 'Edit Comment',
  addComment: 'Add Comment',
  add: 'Add',
  edit: 'Edit',
  companyLogo: 'Company Logo',
  hardware: 'Hardware',
  qrCode: 'QR Code',
  security: 'Security',
  network: 'Network',
  serviceDue: 'Service Due',
  update: 'Update',
  //CreateScan
  aSingleSpeciesMustBeSelectedToSubmitTheScan: 'A single species must be selected to submit the scan',
  pleaseEnterOwnerReference: 'Please enter owner reference',
  pleaseEnterBatchName: 'Please enter batch description',
  pleaseEnterAnimalName: 'Please enter animal name',
  animalName: 'Animal Name',
  animalNames: "Animal Names",
  batchComment:"Batch Comment",
  insertName: 'Insert Name',
  required: 'required',
  insertAdditionalComments: 'Insert Additional Comments',
  editScan: 'Edit Scan',
  createScan: 'Create Scan',
  ownerReference: 'Owner Reference',
  batchName: 'Batch Description',
  species: 'Species',
  equine: 'Equine',
  bovine: 'Bovine',
  ovine: 'Ovine',
  addAnotherAnimal: 'Add another animal',
  saveScan: 'Save Scan',
  testType: 'Test Type',
  //Contact Form
  fullName: 'Full Name',
  reasonForContact: 'Reason for Contact',
  choose: 'Choose',
  customerSupportQuery: 'Customer Support Query',
  scanRelatedQuery: 'Scan Related Query',
  orderQuery: 'Order Query',
  billingQuery: 'Billing Query',
  complaint: 'Complaint',
  dataProtection: 'Data Protection',
  detailsForYourContact: 'Details for your contact',
  //ContactUsOption
  sendNow: 'Send Now',
  helpSupport: 'Help & Support',
  askSupportAQuestion: 'Ask Support A Question',
  submitADataProtectionRequest: 'Submit a Data Protection Question / Request',
  salesSupport: 'Sales Support',
  raiseAQueryWithSales: 'Raise a sales / orders query',
  generalQuery: 'General Query',
  askAGeneralProductionQuestion: 'Ask a general production question',
  //ErrorModal
  badRequest: 'Bad Request',
  loginFailed: 'Login Failed',
  usernameOrPasswordIsIncorrectPleaseTryAgain: 'Username or Password incorrect. Please try again.',
  noAccessAllowed: 'No Access Allowed',
  theResourceDoesNotExist: 'The resource does not exist',
  connectionTimeout: 'Connection Timeout',
  close: 'Close',
  //Sidemenu
  pendingScans: 'Pending Scans',
  resultsHistory: 'Results History',
  aboutUs: 'About Us',
  support: 'Support',
  contactUs: 'Contact Us',
  logout: 'Logout',

  //submenu
  FECPreperation: 'FEC Preperation', 
  FECPrep: 'FEC Prep', 
  FlukePreperation: 'Fluke Preperation', 
  FlukePrep: 'Fluke Prep', 
  CaPreperation: 'CA Preperation', 
  CaPrep: 'CA Prep',
  makeSureUpdateAnalyser: "If selecting a Fluke test type, you MUST first ensure your Analyser is updated to the latest software version.",
  //About
  aboutP1:
    'As an innovative global technology company, we are committed to the development of a new gold standard in point-of-care digital parasitology for targeted treatment.',
  aboutP2a:
    'We have completed the development of an automated, affordable and accurate platform technology that is being used to quickly and effectively identify and analyse important parasites in equine.',
  aboutP2b:
    "OvaCyte™'s revolutionary parasite egg recovery and retention process utilises a proprietary consumable cassette that creates a novel geometric surface tension egg recovery (STER) for flotation, retention and concentration.",
  aboutP3a:
    "The patented platform technology harnesses the power of Artificial Intelligence, providing users with vital <strong>analysis</strong> delivered in real time. For the veterinarian, this means they can avoid the time-consuming and costly process of sending the patient's faecal sample to an external laboratory for testing. Instead, our superior AI technology processes and analyses the faecal sample in minutes, empowering veterinarians to make rapid, informed, evidence-based decisions about targeted treatment.",
  aboutP3b:
    'When analysing samples, our technology utilises advanced digital microscopy to obtain multiple digital images, running these against a specific image recognition software to identify specific parasite species and the number of parasitic eggs or oocysts present.',
  aboutP3c:
    'Our AI provides invaluable geographical prevalence data. The more users that engage our technology, the greater the accuracy, speed of diagnosis and insight into the parasites present within animals in specific areas. The collaborative nature of our AI creates better outcomes for all, empowering medical and veterinary professionals around the world with invaluable knowledge that can be shared and used to further research, science, animal health and welfare.',
  aboutP3d:
    'We believe OvaCyte™ and other technologies we are developing have the capacity to positively impact the broader veterinary, agricultural, medical and environmental industries.',
  aboutP4: 'Our mission is to become the global leader in point-of-care digital parasitology.',
  //BatchReport
  batchReport: 'Batch Report',
  batchDescription: 'Batch Description',
  batchID: 'Batch ID',
  testDate: 'Test Date',
  results: 'RESULTS',
  scanID: 'Scan ID',
  scanState: 'Scan State',
  epg: 'EPG',
  eggsPerGram: 'Eggs Per Gram',
  noEggsDetected: 'No eggs detected',
  print: 'Print',
  loading: 'Loading',
  noReportAvailable: 'No report available',
  comments: 'Comments',
  //Contact
  welcomeToOvaCyteSupport: 'Welcome to OvaCyte Support',
  contactP1:
    'Connecting with our customers is one of the most important things we can do, either hearing what you have say or helping you find a solution, we want to know.',
  contactP2:
    "Using either the contact us options below or our contact details to the right, please don't hesitate to contact is if you help.",
  talkToUs: 'Talk To Us',
  ovaCyteSupport: 'OvaCyte Support',
  hebronIndustrialEstate: 'Hebron Industrial Estate',
  hebronRd: 'Hebron Rd',
  kilkenny: 'Kilkenny',
  ireland: 'Ireland',
  //DetailedSupport
  totalVolumeAnalysed: 'Total Volume Analysed',
  individualReport: 'Individual Report',
  practice:'Practice',
  summary: 'Summary',
  //FAQ
  faq: 'FAQ',
  imageGallery: 'Image Gallery',
  //ResultsHistory
  Incorrectsearchurl: "Incorrect Search Url",
  InputmustbeanumberforanID: 'Input must be a number for an ID',
  NoOperatorSelected: 'No Operator Selected',
  NoDropdownText: 'No Dropdown Text',
  EnterSearchText: 'Enter Search Text',
  EnterFilterFirst: 'Select a Filter First',
  selectaSpecies: 'Select a Species',
  filterHeading: 'Filter Heading',
  filterOperator: 'Filter Operator',
  contains: 'Contains',
  equals: 'Equals',
  notEquals: 'Not Equals',
  addQuery: 'Add Query',
  query: 'Query',
  clearAll: 'Clear All',
  search: 'Search',
  ownerName: 'Owner Name',
  searchScans:'Search Scans',
  selectSpecies: 'Select Species',
  date: 'Date',
  parasites: 'Parasites',
  batchScans: 'Batch Scans',
  animal: 'Animal',
  noBatchAvailable: 'No Batch Available',
  scanDetails: 'Scan Details',
  batchDetails: 'Batch Details',
  details: 'Details',
  searchResultHistory: 'Search Result History',
  scanReport: 'Scan Report',
  images: 'Images',
  emailMustBeMoreThan3Characters: 'Email must be more than 3 characters',
  PasswordMustBeBetween3And100Characters: 'Password must be between 3 and 100 characters',
  resultsForInTable: 'Results',
  //Updates
  noFileChosen: 'No file chosen',
  chooseFile: 'Choose File',
  updates: 'Updates',
  searchWorklist: 'Search Worklist',
  delete: 'Delete',
  edit: 'Edit',
  //HelpLinks
  browseHelpTopics: "Browse Help Topics",
  helpwithFlukeScan: "Fluke Sample Preparation",
  helpWithCaScan: "Companion Animal Sample Prepaeration",
  helpwithFECScan: "FEC Sample Preparation",
  frequentlyAskedQuestions: "Frequently Asked Questions",
  //Support
  usernameIsRequired: 'Username is required',
  passwordIsRequired: 'Password is required',
  invalidUsernameOrPassword: 'Invalid username or password',
  loggedInSuccessfully: 'Logged in successfully',
  passwordHasBeenChanged: 'Password has been changed successfully',
  signOutFromAllDevices: 'Sign out from all devices',
  cancel: 'Cancel',
  confirm: 'Confirm',
  confirmChangePasswordMessage: 'Are you sure you want to change your password?',
  confirmChangePassword: 'Confirm Change Password',
  supportMaterial: 'Support Material',
  resetPassword: 'Reset Password',
  currentPassword: 'Current Password',
  newPasswordMatches:
    'New password should be alphanumeric, min 12 characters including at least 1 uppercase and 1 special character',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  requiredField: 'Required Field',
  passwordsMustMatch: 'Passwords must match',
  ovacyteSupport: 'OvaCyte Support',
  welcomeToOvacyteSupport:
    'Welcome to the OvaCyte support page. Here you will find everything from support material to Frequently Asked Questions.',
  ovacyteSupportIsHereToHelp:
    'If you are unable to find what you are looking for, then please do not hesitate to',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  ovaCyteFaecalAnalyserScanningHelp: "OvaCyte Faecal Analyser / Scanning Help - 'Not-valid' is appearing",
  ovaCyteFaecalAnalyserScanningHelpText1:
    'A "not-valid" message will appear in the Cassette field the Ovacyte Faecal Analyser\'s Cassette field, when either:',
  ovaCyteFaecalAnalyserScanningHelpText2:
    'a cassette which has already been scanned is used / scanned again.',
  ovaCyteFaecalAnalyserScanningHelpText3: 'the QR code is reading as invalid. What do I do to resolve',
  ovaCyteFaecalAnalyserScanningHelpText4: 'What do I do to resolve this?',
  ovaCyteFaecalAnalyserScanningHelpText5:
    'If the cassette has already been scanned and used before, please open a new cassette and fill the cassette using your already prepared sample (if this within 30 minutes) of the preparation process.',
  ovaCyteFaecalAnalyserScanningHelpText6:
    'If this is a newly opened cassette please contact us on our support number for updated QR code.',
  ovaCyteFaecalAnalyserScanningLongRunningScan: 'OvaCyte Faecal Analyser Help / Scanning - Long running scan',
  ovaCyteFaecalAnalyserScanningLongRunningScanText1:
    'A long running scan can occur for multiple reasons, please check for the following:',
  ovaCyteFaecalAnalyserScanningLongRunningScanText2: 'Slow Wi-Fi or slow broadband connection speed',
  ovaCyteFaecalAnalyserScanningLongRunningScanText3:
    'If the Ovacyte Faecal Analyser is connected wirelessly, please try the following:',
  ovaCyteFaecalAnalyserScanningLongRunningScanText4:
    'Move the Ovacyte Faecal Analyser closer to your modem router / wireless access point.',
  ovaCyteFaecalAnalyserScanningLongRunningScanText5:
    'Run a test and check if there has been a positive impact in performance.',
  ovaCyteFaecalAnalyserScanningLongRunningScanText6:
    'The cassette has not been placed level on the Ovacyte Faecal Analyser',
  ovaCyteFaecalAnalyserScanningLongRunningScanText7:
    'The next time you place a cassette onto the Ovacyte Faecal Analyser, please:',
  ovaCyteFaecalAnalyserScanningLongRunningScanText8:
    'Use both hands to place the cassette onto the central spindle.',
  ovaCyteFaecalAnalyserScanningLongRunningScanText9:
    'Ensure the locking nut is placed straight down into the spindle and remains straight whilst screwing it in (the locking nut should be tight but not overly tight).',
  ovaCyteFaecalAnalyserScanningLongRunningScanText10:
    'Please ensure the flat surface of the spindle is free from debris (please see',
  ovaCyteFaecalAnalyserScanningLongRunningScanText11:
    'How to maintain / clean the Ovacyte Faecal Analyser)',
  ovaCyteFaecalAnalyserScanningLongRunningScanText12: 'There are a lot of air bubbles in the cassette',
  ovaCyteFaecalAnalyserScanningLongRunningScanText13: 'The next time you are preparing a sample, please:',
  ovaCyteFaecalAnalyserScanningLongRunningScanText14: 'Ensure that there no air bubbles in the syringe',
  ovaCyteFaecalAnalyserScanningLongRunningScanText15:
    "Hold the cassette is a vertical position so the white circular sticker is at the 12 O' Clock position.",
  ovaCyteFaecalAnalyserScanningLongRunningScanText16:
    'Slowly depress the plunger on the syringe to ensure an even flow into cassette.',
  cassetteHelpCanIReuseTheCassette: 'Cassette Help - Can I reuse the cassette?',
  cassetteHelpCanIReuseTheCassetteText1:
    "Each cassette is clean and designed to capture a one-time only faecal sample in such way that it does not allow for contamination from another animal's sample.",
  cassetteHelpCanIReuseTheCassetteText2:
    "Please do not attempt to clean the cassette and reuse the cassette as it will not always guarantee that it is free from eggs or other debris which can interfere with the Ovacyte Faecal Analyser's ability to scan for the eggs.",
  cassetteHelpCanIReuseTheCassetteText3:
    'Once you are finish with the cassette, please dispose of it through your clinical waste process.',
  cassetteHelpCanIReuseTheCassetteText4:
    'If you need to order new cassettes please contact us at our support number.',
  cassetteHelpTheStickerIsLooseOrMissing: 'Cassette Help - The sticker is loose or missing',
  cassetteHelpTheStickerIsLooseOrMissingText1:
    'The white circular sticker covering the vent on the cassette is loose.',
  cassetteHelpTheStickerIsLooseOrMissingText2:
    'With a clean dry finger, gently roll your finger over the sticker, starting from thenon-loose side.',
  cassetteHelpTheStickerIsLooseOrMissingText3: 'Do not attempt to clue the sticker down.',
  cassetteHelpTheStickerIsLooseOrMissingText4: 'Do not attempt to remove the sticker',
  cassetteHelpTheStickerIsLooseOrMissingText5:
    'The scan will still actively run is the sticker is loose and flat.',
  cassetteHelpTheStickerIsLooseOrMissingText6:
    'The white circular sticker covering the vent on the cassette is missing.',
  cassetteHelpTheStickerIsLooseOrMissingText7:
    'Check the foil package for the sticker. If the sticker is within the foil package, please with a clean and dry fingers,',
  cassetteHelpTheStickerIsLooseOrMissingText8: 'Remove the sticker from the package',
  cassetteHelpTheStickerIsLooseOrMissingText9:
    'Locate the small air hole (located directly inline with the sample injection hole)',
  cassetteHelpTheStickerIsLooseOrMissingText10:
    'Place the sticker over the hole and gently press down to ensure it is stuck down.',
  cassetteHelpTheStickerIsLooseOrMissingText11: 'Do not attempt to clue the sticker down.',
  cassetteHelpTheStickerIsLooseOrMissingText12:
    'If the white sticker is missing when you attempt run a scan it will case the "Cassette Error Identified" message to appear.',
  cassetteHelpTheStickerIsLooseOrMissingText13: 'If this message occurs after trying the above solution.',
  cassetteHelpTheStickerIsLooseOrMissingText14: 'Please contact us on our support number for further support',
  cassetteHelpTheStickerIsLooseOrMissingText15:
    'Please retain the cassette as we track our cassettes for quality assurance purposes.',
  cassetteHelpDroppedMyCassetteWhatDoIDo: "Cassette Help - I've dropped my cassette what do I do?",
  cassetteHelpDroppedMyCassetteWhatDoIDoText1:
    'It is important to ensure that all care is taken with the cassette after it is has been filled with a faecal sample. This is to ensure that the eggs are given time to float to the top and any air bubbles have had time to rest.',
  cassetteHelpDroppedMyCassetteWhatDoIDoText2: 'If you have dropped the cassette, please do the following:',
  cassetteHelpDroppedMyCassetteWhatDoIDoText3:
    "Hold the cassette perpendicular to the floor, so that the white circular sticker is at 12 o' clock position, to allow any large air bubbles to move to the top to the cassette.",
  cassetteHelpDroppedMyCassetteWhatDoIDoText4:
    'Check if any of the faecal sample has spilled from the cassette. If it has, please top up the cassette so there are no visible air bubbles.',
  cassetteHelpDroppedMyCassetteWhatDoIDoText5:
    'Leave the cassette to sit flat for six minutes before placing in back on to the OvaCyte Faecal Analyser.',
  cassetteHelpDroppedMyCassetteWhatDoIDoText6:
    'Important: Any time the cassette has been dropped or shaken small air bubbles are formed into the cassette, which will cause the scan time to increase. Please be patient during this time, while the scan isolates these air bubbles.',
  clinicalHelpCanIUseMyOwnFlotationFluid: 'Clinical Help - Can I use my own Flotation Fluid?',
  clinicalHelpCanIUseMyOwnFlotationFluidText1:
    'Please do not attempt to use you own Flotation Fluid (NaCL), our floatation fluid has been designed to work with our cassette and Ovacyte Faecal Analyser.',
  clinicalHelpCanIUseMyOwnFlotationFluidText2:
    'Attempting to use your own solution may result in inaccurate or no egg counts, early rupturing of the parasites member or non-elevation of the eggs to appropriate level within the cassette.',
  clinicalHelpCanIUseMyOwnFlotationFluidText3:
    'This can lead to you requiring the use of another cassette to load a fresh sample with our own floatation fluid.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScan:
    'Clinical Help - Can I skip flotation process and just do a scan?',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText1:
    'The OvaCyte system allows our customers to prepare samples in advance of starting their scan. If you wish to skip the flotation process, please do the following:',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText2:
    'Wait roughly 10 minutes before you begin the sample preparation process.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText3:
    'Prepare the sample for insertion into the cassette.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText4:
    'Inject the sample into the cassette and lay on a flat surface next to the Ovacyte Faecal Analyser.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText5: 'Important',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText6:
    'The sample must have more six minutes of time resting before starting the scan.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText7:
    'When the current scan has finished, place the cassette onto the Ovacyte Analyser.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText8:
    'Do not shake, twist or flip the cassette during the placement of the cassette on to the Ovacyte',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText9: 'Faecal Analyser, if this does occur, please see',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText10: "I've dropped my cassette what do I do?",
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText11: 'Start the scan procedure.',
  clinicalHelpCanISkipFlotationProcessAndJustDoAScanText12:
    'After pressing Start Scan, tap Skip to the top-right of the screen.',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetected:
    'Cassette Error Identified - No valid cassette can be detected',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText1:
    'If the above message appears when running a scan, please:',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText2:
    'cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText1 Ensure the cassette has been placed onto the Ovacyte Faecal Analyser prior to starting the scan.',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText3:
    'If the cassette is on the Ovacyte Faecal Analyser, check if the white circular sticker is missing from the outer rim of the cassette?',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText4: 'Yes, the sticker is missing from the cassette.',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText5:
    'Check the foil package for the sticker. If the sticker is within the foil package, with a clean and dry fingers,',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText6: 'Remove the sticker from the package',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText7:
    'Locate the small air hole (directly in line with the sample injection hole)',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText8:
    'Place the sticker over the hole and gently press it down to ensure it is firmly on stuck.',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText9: 'Do not attempt to clue the sticker down.',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText10: 'No, the sticker is not in the foil bag',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText11: 'Please contact us on our support number',
  cassetteErrorIdentifiedNoValidCassetteCanBeDetectedText12:
    'Please retain the cassette as we track our cassettes for quality assurance purposes.',
  cassetteErrorIdentifiedAValidCassetteIsRequired: 'Cassette Error Identified - A valid cassette is required',
  cassetteErrorIdentifiedAValidCassetteIsRequiredText1:
    'A valid cassette is required message will appear if an invalid or used QR has been scanned and the Start Scan option is selected',
  cassetteErrorIdentifiedAValidCassetteIsRequiredText2: 'What do I do to resolve this?',
  cassetteErrorIdentifiedAValidCassetteIsRequiredText3:
    'If the cassette has already been scanned and used before, please open a new cassette and fill the cassette using your already prepared sample (if this is within 30 minutes) of the preparation process.',
  cassetteErrorIdentifiedAValidCassetteIsRequiredText4:
    'If this is a newly opened cassette please contact us on our support number for an updated QR code.',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectly:
    'Cassette Error Identified - The cassette may not be secured correctly',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText1:
    'This error can occur when the cassette is not level on the Ovacyte Faecal Analyser and requires re-adjusting, please:',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText2: 'Unscrew the locking nut',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText3:
    'Remove the cassette and place it on a flat clean surface and ensure:',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText4:
    'the hole at the centre of the cassette is free of any debris, and',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText5:
    'the underside of the cassette close to the hole is clean and free of debris.',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText6:
    'Ensure the spindle is free of any debris,',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText7:
    '(please see How to maintain / clean the Ovacyte Faecal Analyser)',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText8:
    'Place the cassette back onto the Ovacyte Faecal Analyser using both hands',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText9:
    'Insert the locking nut straight down into place in the spindle, ensure that it remains straight whilst screwing it in (the locking nut should be tight but not overly tight).',
  cassetteErrorIdentifiedTheCassetteMayNotBeSecuredCorrectlyText10:
    "Tap Confirm ready for retest on the Ovacyte Faecal Analyser's screen.",
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyser:
    'General Support - How to maintain / clean the OvaCyte10 Faecal Analyser',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText1:
    'The OvaCytem 10 Faecal Analyser should be powered down correctly and then disconnected from the mains power supply prior to any cleaning;',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText2: 'Cleaning the spindle',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText3:
    'To clean the spindle, please do the following:',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText4:
    'Brush any loose debris off the surface or sides of the spindle.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText5:
    'Using a damp cloth (not dripping), wipe away any salt crystals on the spindle',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText6:
    'Using a dry lint free cloth wipe off any moisture.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText7:
    'Never use an alcohol or solvent based cleaning agent on the Ovacyte Faecal Analyser.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText8: 'Cleaning the lens (light source)',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText9:
    '   To clean the lens area and to ensure the light emitting lens is clean and dry. Please do the following:',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText10:
    'Using a damp cloth (not dripping), dab the lens gently to moisten any salt build up.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText11: 'Wait for 10 seconds',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText12:
    'Using a dry lint free cloth dab the lens gently to gather up an moisture.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText13:
    'Repeat the above steps until the lens area is clean.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText14: 'Cleaning the lens (magnification)',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText15:
    'To check if the magnification lens requires a clean, please do the following:',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText16:
    'Using a small mirror, place the mirror at a 45 degree angle and observe if there any debris, splash marks, etc.',
  generalSupportHowToMaintainCleanTheOvaCyte10FaecalAnalyserText17:
    'Using a clean empty syringe, blow air across the lens to dislodge any dust or other particles.',
  whatIsOvaCytesPrivacyPolicy: "What is OvaCyte's Privacy Policy",
  whatIsOvaCytesPrivacyPolicyText1:
    'It is important for you to understand what personal data we use as part of the OvaCyte service. To view our privacy statement please download it here:',
  whatIsOvaCytesPrivacyPolicyText2: 'Privacy Policy',
  privacyPolicyDownload: '/PrivacyPolicy.pdf',
  supprtMaterial: 'Support Material',
  installingOvacyte: 'Installing the Ovacyte Faecal Analyser',
  usingOvacyteFaecalAnalyzer: 'Using the Ovacyte Faecal Analyser',
  ovacytePrivacyPolicy: 'Ovacyte Privacy Policy',
  usermanualEq: 'User Manual for Large Animal',
  userManualDownloadEQ: '/OvaCyte Faecal Analyser User Manual v1.4.pdf',
  usermanualCa: 'User Manual for Companion Animal',
  userManualDownloadCA: '/OvaCyte Faecal Analyser User Manual v1.4.pdf',


  // datatables
  WebAppSearchFunctionalityUpdate: '/WebAppSearchFunctionalityUpdateEN.pdf',
  // Fec scan support
  FECScan: 'FEC Scan',
  placeEmptySamplePotOntoTheScaleAndPressT_toZeroTheScale: 'Place an empty sample pot onto the scale and press T to zero the scale.', // need french
  nowWeighOut3gOfFaecalMatterTakenFromYourOriginalSamplePot:'Weigh out 3g of faecal matter taken from your original sample pot.', // needs french
  fecPointThreeAddFloatationFluid: 'Without removing the sample pot, add 47 grams of FEC floatation fluid taking the total up to 50 grams. You now have 3 grams of faecal matter and 47 grams of floatation fluid.', // needs french
  fecPointFourMixSample: 'Using the forceps or spatula provided, mix the sample well for 15 seconds to ensure that all faecal balls are broken up in the fluid and eggs are released into the liquid.', //needs french
  fecPointFivePourLiquid: 'With the blue filter placed on a clean beaker, pour the liquid through the filter mesh and tap a number of times to ensure that all the fluid passes into the beaker together with the eggs.', // needs french
  fecPointSixSwirlLiquid: 'Using a syringe, swirl the liquid, remove approximately 10 ml from the centre of the liquid and place into the cassette. Ensure that the sticker on the cassette is facing 12 o’clock.', // needs french 
  fecPointSevenInsertLiquid: 'With gentle pressure, insert the liquid into the channel of the cassette and you are now ready to place it on the analyser and proceed with the analysis.', // needs french
  fecPointEightPooledSamples: 'For pooled samples or dry pelleted sample prep, please click here: ',
  pooledSampleInstructions: 'FEC Supporting Sample Prep',
  pooledSampleInstructionsDownload: '/FEC-Supporting-Sample-Prep.pdf',
  // Fluke scan support 

  FlukeScan: 'Fluke Scan',
  placeAnEmptyCleanSamplePotOntoTheScale: 'Place an empty clean sample pot onto the scale. Press T to zero the scale.' , // needs french
  flukePointTwoWeighFecalMatter: 'Now weigh out 5 grams of faecal matter from your original sample pot. Ensure that the original sample is well mixed so that a representative sample will be tested.', // needs french
  nowFillTheSamplePotWithWaterUpToThe50MlMark:'Now fill the sample pot with water until it reaches the 50 ml mark.', // needs french
  mixTheSampleVeryWellWithTheForcepsToEnsureThatAllEggsAreRealisedFromTheFaecalMatter:'Mix the sample very well with the forceps to ensure that all eggs are realised from the faecal matter.', // needs french
  placeTheGreyFilterOnTopOfTheCoralFilterNumbers1and2PointingUpwards: 'Place the grey filter on top of the coral filter numbers 1 and 2 pointing upwards', // needs french
  flukePointSixPourFiltrate: 'Pour half of the filtrate into the filter stack and tap 30 times to ensure that all the liquid passes through into the beaker.', // needs french
  NowPassTheSecondHalfThroughTheFilterStaceAndTapAnother30Times: 'Now pass the second half through the filter stack and tap another 30 times.', // needs french
  flukePointEightHalfFillSamplePot: 'Now half fill your sample pot with water, swirl it around and then pour that into the filter stack. This is to remove any eggs that may have attached to the surface of the sample pot. Tap a further 30 times to ensure that all the liquid passes through both filter stacks into the pot below.', // needs french
  flukePointNineRemoveCoralFilter: 'Now remove the coral filter, invert it and place it on top of a clean 50 ml beaker. Before inverting the filter, you must ensure that there is no surface fluid on the coral mesh. If the filter step is slow and the prescribed number of taps is not sufficient to complete the filtration process, it is an indication that your filter has a buildup of organic residue, and it will need soaking. A second filter is provided to facilitate this, and soaking instructions are described in the documentation.', // needs french 
  flukePointTen: 'Ensure that the number 3 on the coral filter is facing upwards. Remove 50 ml of Fluke floatation fluid and flood the surface of the filter. Once the fluid has passed through the filter and into the beaker, tap the filter 30 times to remove any water droplets and eggs that may have adhered to the undersurface of the filter. The sample prep is almost complete.', // needs french
  flukePointeEleven: 'Now, using a syringe, remove the fluid that is in the beaker and insert it into the cassette with the sticker facing at 12:00 o’clock. Now you are ready to place it on the analyser and proceed with the test.',


  // CA support
  thisIsAhelpPageFEC: 'This the help page for FEC devices. Here the preperation required for starting an FEC scan is explained.',
  thisIsAhelpPageFluke: 'This the help page for Fluke devices. Here the preperation required for starting a Fluke scan is explained.',
  thisIsAhelpPageCA: 'This the help page for Companion Animal devices. Here the preperation required for starting a PET scan is explained.',
  pleaseFollowBelow: 'Please follow the steps shown below as illustrated in our example video.',
  
  fillTheCutterEndOfTheInsertWithFaeces: 'Fill the cutter end of the insert with faeces. In the case of diarrhea use syringe provided.',
  pushTheInsertIntoTheTube: 'Push the insert into the tube.',
  takeFlotationFluidUsingTheSyringe: 'Take 12 ml of the Flotation Fluid (FF) using the syringe.',
  InjectAllTheFFIntoTheTube: 'Inject all the FF into the tube.',
  GentlyMashTheSolutionUntilMixedThoroughly: 'Gently mash the solution until mixed thoroughly.',
  UsingTheSyringeExtractTheFiltrate: 'Using the syringe, extract as much of the filtrate as possible.',
  WithFingerOnTheSyringeTopPullBackThePlunder: 'With a finger on the syringe top, pull back the plunder as far as feasible, then remove finger.',
  DepressThePlungerWithTheSyringePointingUpwardsUntilTheFluidReachesTheTop: 'Depress the plunger with the syringe pointing upwards until the fluid reaches the top',
  InjectTheFluidIntoTheCassetteAndPlaceOnTheAnalyser: 'Inject the fluid into the cassette and place on the analyser'


}
