import {
  FETCH_WORKLIST_START,
  FETCH_WORKLIST_SUCCESS,
  FETCH_WORKLIST_FAILURE,
  EDIT_WORKLIST,
  DELETE_WORKLIST,
  GET_ERRORS,
} from "./types";
import history from "../../history";
import {backInstance} from "../../config";

const fetchWorklistStart = () => {
  return {
    type: FETCH_WORKLIST_START,
  };
};

const fetchWorklistSuccess = (worklist, count) => {
  return {
    type: FETCH_WORKLIST_SUCCESS,
    worklist,
    count,
  };
};

const fetchWorklistFailure = (error) => {
  return {
    type: FETCH_WORKLIST_FAILURE,
    error,
    status: error.response.status,
  };
};

const fetchWorklistDetails = (page, vars=false, column=false, newPerPage=10) => {
  if(!vars){vars={}}
  try{
    vars['scan_state'] = 'created'
    let query = `scan?where=${JSON.stringify(vars)}&page=${page}&per_page=${newPerPage}&desc=false&sort={"created":true}`

    const response = backInstance.get(query)
    // console.log(response)
    return response
  }
  catch(e){
    console.log('error', e)
  }
}

export const fetchWorklist =
  (page, searchTerm = '', sortCol=false, newPerPage=10) =>
  dispatch => {
    dispatch(fetchWorklistStart())
    return fetchWorklistDetails(page, searchTerm, sortCol, newPerPage).then(
      response => dispatch(fetchWorklistSuccess(response.data, response.headers['x-total-count'])),
      error => dispatch(fetchWorklistFailure(error))
    )
  }


// const fetchWorklistDetails = (page, searchTerm) => {
//   let query = `scan/search?fields=[owner_name,animal_id,id,parent_batch_id]&value=${searchTerm}&page=${page}&per_page=10&desc=true&state=created`;
//   const response = backInstance.get(query);
//   return response;

// };

// export const fetchWorklist =
//   (page, searchTerm = "") =>
//   (dispatch) => {
//     dispatch(fetchWorklistStart());
//     return fetchWorklistDetails(page, searchTerm).then(
//       (response) => {
//         dispatch(fetchWorklistSuccess(response.data, response.headers["x-total-count"]));
//       },
//       (error) => dispatch(fetchWorklistFailure(error))
//     );
//   };

export const editWorklist = (id) => {
  return async (dispatch) => {
    const response = await backInstance.get(`/scan/${id}`);
    dispatch({type: EDIT_WORKLIST, payload: response.data});
    history.push({
      pathname: "/edit-scan",
      prePopulate: response.data,
    });
  };
};

export const deleteWorklist = (id) => async (dispatch) => {
  try {
    const res = await backInstance.delete(`/scan/${id}`);
    history.go(0);
    dispatch({
      type: DELETE_WORKLIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
