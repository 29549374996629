import React, { Component } from 'react'
import { NavLink, Router } from 'react-router-dom'
import Routes from '../src/Routes'
import { connect } from 'react-redux'
import { logout } from './redux/actions/securityActions'
import Sidemenu from './components/Sidemenu'
import BurgerMenu from './components/BurgerMenu'
import history from './history'
import OvaCyteImg from './assets/ovacyte-logo.png'
import jwt from 'jwt-decode'
import { withTranslation } from 'react-i18next'
import LangSelector from './components/LangSelector'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { resultsHistoryPageHandler, pendingScansPageHandler } from "./redux/actions/tablePaginationActions";
import ThemeTogglerButton from './components/theme-toggler-button';
import {ThemeContext, themes} from './context/theme-context';
import {version} from './config.js'

class App extends Component {
  constructor(props) {
    // this.theme = this.context

    super(props)
    this.toggleTheme = async () => { // this is async to synch local storage and react state theres probs a better way to do it
      await this.setState(state => ({
        theme:
          state.theme.background === themes.dark.background
            ? themes.light
            : themes.dark,
        darkMode: !this.state.darkMode
      }));
      await localStorage.setItem("theme", JSON.stringify(this.state.theme))
      await localStorage.setItem("darkMode", this.state.darkMode)
    };

    // console.log(JSON.parse(localStorage.getItem("theme")))
    this.state = {
      mobileMenu: false,
      // theme: themes.light,
      theme: JSON.parse(localStorage.getItem("theme"))|| themes.light,
      darkMode: (localStorage.getItem("darkMode") === 'true') || false,
      toggleTheme: this.toggleTheme,
    }
  }

  logout() {
    this.props.resultsHistoryPageHandler(1);
    this.props.pendingScansPageHandler(1);
    this.props.logout();
    window.location.href = '/'
  }

  callback = mobileToggle => {
    this.setState({
      mobileMenu: mobileToggle,
    })
  }

  componentDidMount() {

    const token = localStorage.getItem('AccessToken')
    if(token && localStorage.getItem("version") !== version){
      this.logout()
    }
    if(localStorage.getItem("darkMode") === 'true' || localStorage.getItem("darkMode") === 'false'){
      this.setState({
        theme: 
          localStorage.getItem("darkMode") === 'true'
          ? themes.dark 
          : themes.light,
        darkMode: (localStorage.getItem("darkMode") === 'true') || false,
      })
    }
    else if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setState({
          theme: themes.dark,
          darkMode: true,
        })
    }
    if (!token) return
    try {
      const decodedToken = jwt(token)
      const dateNow = new Date()

      if (decodedToken?.exp < dateNow.getTime() / 1000) {
        this.logout()
      }
    } catch {
      this.logout()
    }
  }

  render() {
    const { user, validToken } = this.props.security

    return (
      
      <ThemeContext.Provider value={this.state}>
      <Router history={history}>
        {user && validToken ? (
          
          <ThemeContext.Consumer>
          {({theme, darkMode}) => (
          <div className='App' style={{backgroundColor: theme.background}}>
            <div style={{backgroundColor: theme.background}} className='container-fluid' id='dashboard-container'>

              <div className='row d-print-none' id='app-row-header' style={{backgroundColor: theme.background}} >
                <div className='col-6' id='header-col'>
                  <NavLink to='/' exact>
                    <img id='sidemenu-logo' alt='ovacyte-logo' src={OvaCyteImg} />
                  </NavLink>
                </div>

                <div className='lang-selector' id='header-col'>

                  <div className='theme-options-loggedin'>
                    <ThemeTogglerButton />
                  </div>

                  <div>
                    <LangSelector />
                  </div>

                  <div onClick={this.logout.bind(this)} className='menu-options-loggedin'>
                    <p style={{ fontWeight: '500', transform: 'translateY(8px)', cursor: 'pointer', color:theme.textcolor }}>
                      {this.props.t('logout')}
                    </p>
                  </div>
                </div>
              </div>
              <div className='row' id='dashboard-row'>
                <BurgerMenu parentCallback={this.callback} />
                <div className={`col-auto sidemenu-col  ${this.state.mobileMenu ? 'showMenu' : ''}`}>
                  <Sidemenu parentCallback={this.callback} />
                </div>
    
                <div className='col' id='dashboard-content' style={{backgroundColor: theme.foreground}}  >
                  <Routes />
                </div>
                <div>
                 
                </div>
              </div>

            </div>
            
          </div>
          )}
          </ThemeContext.Consumer>

        ) : (
          <div>
            <div className='col' id='sidemenu-hidden'></div>
            <div className='row d-print-none' id='app-row-header-login'>
              <div className='col-6 col-sm-12' id='header-col'>
                <NavLink to='/'>
                  <img id='sidemenu-logo' alt='ovacyte-logo' src={OvaCyteImg} />
                </NavLink>
              </div>
              <div className='col-6 menu-options' id='header-col-menu'>
                <div>
                  <div>
                    <LangSelector />
                  </div>
                  {/* <NavLink to='/' exact>
                    {this.props.t('login')}
                  </NavLink> */}
                </div>
              </div>
            </div>
            <div className='col-12 body-content'>
              <div id='body-container body-content'>
                <Routes />
              </div>
            </div>
            
          </div>
         
        )}
      </Router>
      </ThemeContext.Provider>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
})


const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout,
      resultsHistoryPageHandler,
      pendingScansPageHandler,
    },
    dispatch
  );
};

// export default withTranslation()(connect(mapStateToProps mapDispatchToProps,  { logout })(App))

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withTranslation()(App));
