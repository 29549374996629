import React, { Component } from 'react';
import '../css/ThumbWithText.css'
import { connect } from "react-redux";
import { compose } from "redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown'
import { ThemeContext } from '../context/theme-context';
import history from '../history'
import { USER_ALERT } from '../redux/actions/types'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
createTheme('solarized', {
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
  },
  background: {
    default: '#232322',
  },
  context: {
    background: '#ffffff',
    text: '#ffffff',
  },
  divider: {
    default: '#000001',
  },
  button: {
    default: '#CDCDCD',
    focus: '#232322',
    hover: '#363636',
    disabled: '#363636',
  },
  action: {
    button: '#ffffff',
    hover: '#ffffff',
    disabled: '#ffffff',
  },
}, 'solarized');

const initialState = {
  dropDownText: [{ query: "", plain_text: "", type: "" }],
  species_selected: [{ species: "", plain_text: "" }],
  operators: [{ operator: "", plain_text: "" }],
  inputTextArray: [{ inputText: "", i: 0 }],
  filterTextQuery: '',
  docs: [],
  id: "",
  title: "",
  selectedRowID: null,
  totalRows: 0,
  perPage: 10,
  page: 1,
  filteredData: [],
}

class FilterDataTable extends Component {
  constructor(props) {
    super(props);
    this.searchTextFromUrl = false
    this.scan_product_dict = JSON.parse(localStorage.getItem('scanProductDict'))
    this.number_per_page = 10;
    this.state = initialState
    this.operator_plain_text_dict = { // used on url load
      '$contains': 'Contains',
      '$eq': 'Equals',
      '$ne': 'Not Equals'
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitQuery()
    }
  }

  loadFromUrlOnMount() {

    const { fetchTableData, columns } = this.props;
    let search = this.props.location.search
    if (search) {
      try { // catch bad urls
        var searchSplit = search.split('&')
        var wholeqQuery = searchSplit[0]
        var page = searchSplit[1].split('=')[1]
        this.number_per_page = parseInt(searchSplit[2].split('=')[1])
      }
      catch {
        this.props.dispatch({
          type: USER_ALERT,
          message: this.props.t('Incorrectsearchurl'),
          alertType: 'Caution',
        })
        return 0
      }
      var queryDict = JSON.parse(wholeqQuery.replace('?query=', '').replace(/\%22/g, '"'))
      let dropDownText = [...this.state.dropDownText];
      let inputTextArray = [...this.state.inputTextArray];
      let operators = [...this.state.operators];
      let species_selected = [...this.state.species_selected];
      let count = 0
      let searchText = []
      for (var key in queryDict) {
        if (count !== 0) { this.addQuery() }
        for (let i = 0; i < columns.length; i++) {
          if (columns[i].filterTerm === key) {
            let query = columns[i].filterTerm
            let plain_text = this.props.t(columns[i].name)
            let type = columns[i].type

            dropDownText[count] = { ...dropDownText[count], query };
            dropDownText[count] = { ...dropDownText[count], plain_text };
            dropDownText[count] = { ...dropDownText[count], type };

            let operator = Object.keys(queryDict[key])[0]
            plain_text = ''

            if (operator) {plain_text = this.operator_plain_text_dict[Object.keys(queryDict[key])[0]]}
            searchText.push(Object.values(queryDict[key])[0])

            operators[count] = { ...operators[count], operator };
            operators[count] = { ...operators[count], plain_text };

            let inputText = Object.values(queryDict[key])[0]
            if(typeof(inputText) !== 'object'){inputText = inputText.replace('/scan/', '')}
            inputTextArray[count] = { ...inputTextArray[count], inputText };

            let species = ''
            plain_text = ''
            if (key === 'scan_product') {
              species = Object.values(queryDict[key])[0].$ref
              plain_text = this.scan_product_dict[species]
            }
            species_selected[count] = { ...species_selected[count], species };
            species_selected[count] = { ...species_selected[count], plain_text };
          }
        }
        this.setState({ dropDownText: dropDownText });
        this.setState({ operators });
        this.setState({ species_selected });
        this.setState({ inputTextArray });
        count += 1
      }
      this.handlePageChange(parseInt(page))
      this.submitQuery_searchInput(dropDownText, operators, searchText)
      return page
    }
    else { fetchTableData(this.props.paginationPage); return false }
  }

  refreshData(vars) {
    vars['props'].fetchTableData(vars['props'].paginationPage, vars['filterTextQuery'], false, vars['number_per_page'])
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
  }

  componentDidMount() {
    this.scan_product_dict = JSON.parse(localStorage.getItem('scanProductDict'))
    this.speciesDict = JSON.parse(localStorage.getItem('species'))

    let page = this.loadFromUrlOnMount()
    if (page) { this.props.fetchTableData(page, this.filterTextQuery, false, this.number_per_page); } // honestly not sure why but we have to call fetch again for it to update
  }

  handlePageChange = page => {
    const { fetchTableData, pageHandler } = this.props;
    fetchTableData(page, this.filterTextQuery, false, this.number_per_page);
    pageHandler(page);
  };

  componentDidUpdate(prevProps) {

    if (this.props.workList !== prevProps.workList) {
      const tableData = this.props.renderList;
      this.setState({ filteredData: tableData });
    }

    if (this.props.status !== prevProps.status) { // if we detect a new 404 its cause the pagination page is too high for the query
      if (this.props.status === 404) {
        this.props.fetchTableData(1, this.filterTextQuery, false, this.number_per_page)
        history.replace({
          search: `?query=${JSON.stringify(this.filterTextQuery)}&page=${1}&per_page=${parseInt(this.number_per_page)}`
        })
      }
    }

    clearInterval(this.refreshInterval)
    let vars = {
      'props': this.props,
      'filterTextQuery': this.filterTextQuery,
      'number_per_page': this.number_per_page
    }
    this.refreshInterval = setInterval(this.refreshData, 1 * 5 * 60 * 1000, vars);
  }

  handleFilterDropDownChange(query, type, plain_text, i) {

    if (type === 'str') {
      this.handleOperatorDropDownChange('$contains', this.props.t('contains'), i)
    }
    else {
      this.handleOperatorDropDownChange('$eq', this.props.t('equals'), i)
    }

    let dropDownText = [...this.state.dropDownText];
    dropDownText[i] = { ...dropDownText[i], query };
    dropDownText[i] = { ...dropDownText[i], plain_text };
    dropDownText[i] = { ...dropDownText[i], type };
    this.setState({ dropDownText: dropDownText });
  }

  handleSpeciesDropDownChange(species, plain_text, i) {
    let species_selected = [...this.state.species_selected];
    species_selected[i] = { ...species_selected[i], species };
    species_selected[i] = { ...species_selected[i], plain_text };
    this.setState({ species_selected });
  }

  handleInputTextChange(inputText, i) {
    let inputTextArray = [...this.state.inputTextArray];
    inputTextArray[i] = { ...inputTextArray[i], inputText };
    inputTextArray[i] = { ...inputTextArray[i], i };
    this.setState({ inputTextArray });
  }

  handleOperatorDropDownChange(operator, plain_text, i) {


    let input_id = 'filter_text_' + i
    let textArea = document.getElementById(input_id);
    if(textArea){textArea.focus()}

    let operators = [...this.state.operators];
    operators[i] = { ...operators[i], operator };
    operators[i] = { ...operators[i], plain_text };
    this.setState({ operators });
  }

  filterDropDown = (i) => {
    return (
      <ThemeContext.Consumer>
        {({ theme }) => (

          <Dropdown alignRight>
            <Dropdown.Toggle className="results-history-dropdown" id="" value={this.state.dropDownText} >
              {this.state.dropDownText[i]['plain_text'] || this.props.t('filterHeading')}
            </Dropdown.Toggle>

            <Dropdown.Menu className='results-history-dropdown-menu' style={{ backgroundColor: theme.background }}>
              {this.props.columns.map(column =>
                column.type === 'str' || column.type === 'int' || column.type === 'dropdown' ?
                  this.state.dropDownText.some((e => e.query === column.filterTerm) // if its already selected we cant select it again
                  ) ? '' :
                    <Dropdown.Item key={i + column.filterTerm} className="results-history-dropdown-menu" onClick={() => this.handleFilterDropDownChange(column.filterTerm, column.type, this.props.t(column.name), i)}><div style={{ color: theme.textcolor }}>{this.props.t(column.name)}</div></Dropdown.Item>
                  : '')
              }
            </Dropdown.Menu>
          </Dropdown>
        )}
      </ThemeContext.Consumer>
    );
  }

  speciesDropDownItem(i) {
    return (
      <ThemeContext.Consumer>
        {({ theme }) => (
          Object.entries(this.scan_product_dict).map((species) => (
            <Dropdown.Item key={species[0]} className="rh-species-dropdown" onClick={() => this.handleSpeciesDropDownChange(species[0], species[1], i)}><div style={{ color: theme.textcolor }}>{species[1]}</div></Dropdown.Item>
          )))}
      </ThemeContext.Consumer>
    )
  }

  speciesDropDown = (i) => {
    return (
      <ThemeContext.Consumer>
        {({ theme }) => (

          <Dropdown alignRight key={i}>
            <Dropdown.Toggle className="results-history-dropdown-species" id={`filter_text_species_${i}`} value={this.state.species_selected[i]}>
              {this.state.species_selected[i]['plain_text'] || this.props.t("selectSpecies")}
            </Dropdown.Toggle>
            <Dropdown.Menu key={i} className='rh-species-dropdown' style={{ backgroundColor: theme.background }}>
              {this.speciesDropDownItem(i)}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </ThemeContext.Consumer>
    );
  }

  operatorDropDown = (i) => {
    return (
      <ThemeContext.Consumer>
        {({ theme }) => (
          <Dropdown required >
            <Dropdown.Toggle className="results-history-dropdown" id="dropdown-basic2" value={this.state.operators}>
              {this.state.operators[i]['plain_text'] || this.props.t('filterOperator')}
            </Dropdown.Toggle>

            <Dropdown.Menu className='results-history-dropdown-menu' style={{ backgroundColor: theme.background }}>
              <Dropdown.Item onClick={() => this.handleOperatorDropDownChange('$eq', this.props.t('equals'), i)}><div style={{ color: theme.textcolor }}>{this.props.t('equals')}</div></Dropdown.Item>
              <Dropdown.Item onClick={() => this.handleOperatorDropDownChange('$ne', this.props.t('notEquals'), i)}><div style={{ color: theme.textcolor }}>{this.props.t('notEquals')}</div></Dropdown.Item>

              {this.state.dropDownText[i]['type'] === 'str'
                ?
                <div>
                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item onClick={() => this.handleOperatorDropDownChange('$contains', this.props.t('contains'), i)}><div style={{ color: theme.textcolor }}>{this.props.t('contains')}</div></Dropdown.Item>
                  {/* <Dropdown.Item onClick={() => this.handleOperatorDropDownChange('$startswith', "Starts With", i)}><div style={{ color: theme.textcolor}}>Starts With</div></Dropdown.Item>
                  <Dropdown.Item onClick={() => this.handleOperatorDropDownChange('$endswith', "Ends WIth", i)}><div style={{ color: theme.textcolor}}>Ends With</div></Dropdown.Item> */}
                </div>
                : ''
              }
            </Dropdown.Menu>
          </Dropdown>
        )}
      </ThemeContext.Consumer>
    );
  }

  removeQuery(i) {

    let inputTextArray = [...this.state.inputTextArray];
    inputTextArray.splice(i, 1);
    this.setState({ inputTextArray });

    if (this.searchTextFromUrl[i]){this.searchTextFromUrl[i] = false}

    let dropDownText = [...this.state.dropDownText];
    dropDownText.splice(i, 1);
    this.setState({ dropDownText });

    let operators = [...this.state.operators];
    operators.splice(i, 1);
    this.setState({ operators });

    let species_selected = [...this.state.species_selected];
    species_selected.splice(i, 1);
    this.setState({ species_selected });

  }

  addQuery() {
    this.setState(prevState => ({
      dropDownText: [...prevState.dropDownText, { plain_text: "" }],
      operators: [...prevState.operators, { operator: "" }],
      species_selected: [...prevState.species_selected, { species: "" }],
      inputTextArray: [...prevState.inputTextArray, { inputText: "" }],
    }));
  }

  clearAll() {
    let input_id = 'filter_text_0'
    let textArea = document.getElementById(input_id);
    history.replace({
      search: ``
    })
    if(textArea){textArea.value = ''}
    this.filterTextQuery = ''
    this.setState(initialState);
    this.props.fetchTableData(this.props.paginationPage)

  }

  submitQuery_searchInput = (dropDownText, operators, searchText) => {

    let filter = {}
    let queryWithOperator;
    this.searchTextFromUrl = searchText

    for (let i = 0; i < dropDownText.length; i++) {
      queryWithOperator = {}
      queryWithOperator[operators[i]['operator']] = searchText[i]
      filter[dropDownText[i]['query']] = queryWithOperator
    }
    this.filterTextQuery = filter
    this.props.fetchTableData(1, filter, false, this.number_per_page)
  }

  submitQuery = () => {

    let filter = {}
    let queryWithOperator;
    let searchText = ''

    for (let i = 0; i < this.state.dropDownText.length; i++) {
      if (this.state.dropDownText[i]['query'] === "scan_product") {
        searchText = {}
        searchText["$ref"] = this.state.species_selected[i]['species']
        if(this.state.species_selected[i]['species'] === '' || this.state.species_selected[i]['species'] == undefined) // no species selected
        {
          this.props.dispatch({
            type: USER_ALERT,
            message: this.props.t('Select a Species'),
            alertType: 'Caution',
          })
          return
        }
      }
      else {
        searchText = this.state.inputTextArray[i].inputText
      }

      queryWithOperator = {}
      if (searchText) {
        if (this.state.dropDownText[i]['query']) {
          if (this.state.operators[i]['operator']) {
            // we want to add "/scan/*ID*" if we are searching for an scan id and "parent_batch": {"$ref": "/batch/*ID*"} if its a batch
            if (!isNaN(searchText)) { // check is number
              if (this.state.dropDownText[i]['query'] === "$uri") { // is scan query
                searchText = `/scan/${searchText}`
              }
              else if (this.state.dropDownText[i]['query'] === "parent_batch") { // is batch query doesnt work
                // searchText = `{"$ref":"/batch/${textArea.value}"}`
                searchText = {}
                searchText["$ref"] = `/batch/${searchText}`
              }
            }
            else if (this.state.dropDownText[i]['type'] === "int") {
              this.props.dispatch({
                type: USER_ALERT,
                message: this.props.t('InputmustbeanumberforanID'),
                alertType: 'Caution',
              })
              return
            }
            queryWithOperator[this.state.operators[i]['operator']] = searchText
            filter[this.state.dropDownText[i]['query']] = queryWithOperator
          }
          else {
            this.props.dispatch({
              type: USER_ALERT,
              message: this.props.t('NoOperatorSelected'),
              alertType: 'Caution',
            })
            return
          }
        }
        else {
          this.props.dispatch({
            type: USER_ALERT,
            message: this.props.t('NoDropdownText'),
            alertType: 'Caution',
          })
          return
        }
      }
      else {
        this.props.dispatch({
          type: USER_ALERT,
          message: this.props.t('EnterSearchText'),
          alertType: 'Caution',
        })
        return
      }
    }

    this.filterTextQuery = filter
    history.replace({
      search: `?query=${JSON.stringify(filter)}&page=${this.props.paginationPage}&per_page=${parseInt(this.number_per_page)}`
    })
    this.props.fetchTableData(this.props.paginationPage, filter, false, this.number_per_page)
  }

  queryrow() {
    return this.state.dropDownText.map((el, i) => (
      <ThemeContext.Consumer key={i}>
        {({ theme }) => (
          <div className='rh-button-row-search-query-top p-2 pr-4' key={i}>
              <div>
                <a href={this.props.t('WebAppSearchFunctionalityUpdate')} target='_blank' >
                <FontAwesomeIcon icon={faInfoCircle} style={{color: theme.textcolor }} className='infoIcon-dt' disabled={i>0} hidden={i>0} />
                </a>
              </div>
            <div className=''>
              {this.filterDropDown(i)}
            </div>

            {this.operatorDropDown(i)}

            {this.state.dropDownText[i]['plain_text'] === 'Species' ? this.speciesDropDown(i) : ''}

            {this.state.dropDownText[i]['plain_text'] !== 'Species' ?  
            (<div className=''>
              <input
                className='rh-query-search'
                style={{ backgroundColor: theme.inputcolor, color: theme.textcolor }}
                placeholder={this.state.dropDownText[i].query === '' || this.state.dropDownText[i].query === undefined ? this.props.t('EnterFilterFirst') : this.state.dropDownText[i]["plain_text"] ? this.props.t("query") + " " + this.state.dropDownText[i]["plain_text"] : this.props.t('searchScans')}
                id={'filter_text_' + i}
                onKeyDown={this._handleKeyDown.bind()}
                disabled={this.state.dropDownText[i].query === '' || this.state.dropDownText[i].query === undefined}
                onChange={e => this.handleInputTextChange(e.target.value, i)}
                value={this.state.inputTextArray[i].inputText ? this.state.inputTextArray[i].inputText : ''}
              />
            </div>):''
            }

            {this.state.dropDownText.length > 1 && (
              <div className="rh-button-row-clear">
                <input className={window.innerWidth > 705 ? `pt-3 rh-button-row-clear` : `rh-button-row-clear`} style={{ backgroundColor: theme.foreground, color: theme.textcolor }} type="button" value="X" onClick={this.removeQuery.bind(this, i)} />
              </div>
            )}

          </div>
        )}
      </ThemeContext.Consumer>
    ))
  }

  findNumUsableCols() {
    let count = -1; // starts at minus one cause there should already be a filter row when page loads
    for (let i = 0; i < this.props.columns.length; i++) {
      if (['str', 'int', 'dropdown'].includes(this.props.columns[i].type)) {
        count += 1
      }
    }
    return count
  }

  searchFiler() {
    return (
      <ThemeContext.Consumer>
        {({ theme }) => (
      <div className='search-filter-container'>
        <div className='row mb-1'>
          {this.queryrow(this)}
        </div>
        <div className='rh-button-row-search-query'>
          <input
            disabled={this.state.dropDownText.length > this.findNumUsableCols()}
            type="button"
            onClick={this.addQuery.bind(this)}
            className="btn btn-primary results-history"
            value={this.props.t('addQuery')}
          />
          <input
            type="button"
            onClick={this.clearAll.bind(this)}
            value={this.props.t('clearAll')}
            className="btn-primary rh-clear-filter-button"
          />
          <input
            type="button"
            className="btn btn-primary results-history-search"
            value={this.props.t("search")}
            onClick={this.submitQuery.bind()}
          />
        </div>
      </div>
      )}
      </ThemeContext.Consumer>
    )
  }

  handlePerRowsChange = async (newPerPage, page) => {
    this.number_per_page = newPerPage
    this.props.fetchTableData(page, this.filterTextQuery, false, newPerPage);
  }

  render() {
    const { columns, conditionalRowStyles, extraButtons, onRowClicked, customStyles, workList } = this.props;
    let count; if (!workList) { count = 10 } else { count = workList.count }

    return (
      <ThemeContext.Consumer>
        {({ theme, darkMode }) => (
          <div>
            <div>
              {this.searchFiler(this)}
            </div>
            <div>
              {extraButtons}
            </div>
            <div className="data-table pb-5">
              <DataTable
                progressPending={false}
                expandOnRowClicked={this.props.expandOnRowClicked}
                expandableRowsComponent={this.props.expandableRowsComponent}
                expandableRowExpanded={this.props.expandableRowExpanded}
                expandableRowsHideExpander={true}
                expandableRows={this.props.expandableRows}
                paginationDefaultPage={this.props.paginationPage}
                columns={columns}
                onChangeRowsPerPage={this.handlePerRowsChange}
                data={this.state.filteredData}
                pagination
                onChangePage={this.handlePageChange}
                paginationTotalRows={parseInt(count)}
                paginationServer
                paginationPerPage={10}
                paginationRowsPerPageOptions={[99, 50, 40, 30, 20, 15, 10, 5]}
                onRowClicked={onRowClicked}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                responsive={true}
                theme={darkMode ? 'solarized' : ''}
                onSort={this.props.handleSort}
              />
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({
});

export default compose(withRouter, connect(mapStateToProps))(withTranslation()(FilterDataTable));
