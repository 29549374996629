import React, { Component } from 'react';
import Chevron from './Chevron'
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ThemeContext} from '../context/theme-context';
import { compose } from "redux";
import { withTranslation } from 'react-i18next'

class MenuAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  
  toggleAccordion = () => { // allows a tab to opened and closed
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { isOpen } = this.state;
    const { title, children, linksToShow } = this.props;
    let height = 0
    if(linksToShow){height =  100 * linksToShow.length}
    
    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <div style={{color:theme.textcolor, backgroundColor:theme.background}} key={children} className={`${isOpen ? '' : ''}`} onClick={() => [this.toggleAccordion()]}>
      <div style={{color:theme.textcolor, backgroundColor:theme.background}} className={`sidemenu-sub-li${this.state.isHover ? '-hover' : ''}`} id="sidemenu-home">
          <FontAwesomeIcon icon={faQuestionCircle} size="1x" className="fontawesome-sidemenu" />
        
          {this.props.t('support')}
          <Chevron
            className={`submenu-chevron ${(isOpen) ?  'accordion__icon rotate' : 'accordion__icon'}`}
            width={8}
            fill={'#777'}
          />
        </div>
        <div style={isOpen ? {height: `${height}px`} : {height:'0px'}}  className={`submenu-child${isOpen ? '-transformed-state':''}`} id='child'>
        {<div key={title}>{children}</div>}
        </div>
      </div>
            )}
      </ThemeContext.Consumer>   
    );
  }

  
}
export default compose(withTranslation()(MenuAccordion));
