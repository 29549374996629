import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  BsFillPatchQuestionFill,
  BsFileEarmarkLock2Fill,
  BsBasket3Fill,
  BsQuestionCircle,
  BsTelephone,
  BsEnvelope,
} from 'react-icons/bs'
import { FaRegAddressCard } from 'react-icons/fa'
import ContactUsOption from '../../components/ContactUsOption'
import { withTranslation } from 'react-i18next'
import { ThemeContext } from '../../context/theme-context'

export class Contact extends Component {
  render() {
    return (
      <ThemeContext.Consumer>
      {({ theme, darkMode }) => (
      <div>
        <div className='white-background-customers' style={{ background: theme.foreground, color: theme.textcolor}}>
          <h3  style={{ color: theme.textcolor}} className='header-worklist ml-2' >{this.props.t('contactUs')}</h3>
          <div
            className='row'
            style={{
              color: '#212529',
              fontSize: '1rem',
              fontWeight: '400',
              margin: 'auto',
              color: theme.textcolor
            }}
          >
            <div className='col-lg-7 pl-5 pr-5 pb-2 pt-2'>
              <h4
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  borderBottom: '1px solid #4472C4',
                  fontSize: '20px',
                  color: theme.textcolor
                }}
              >
                {this.props.t('welcomeToOvaCyteSupport')}
              </h4>
              <p className='mt-4'>{this.props.t('contactP1')}</p>
              <p>{this.props.t('contactP2')}</p>
            </div>
            <div className='col-lg-4 pl-5 pr-5 pb-2 pt-2'>
              <h4
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  borderBottom: '1px solid #4472C4',
                  fontSize: '20px',
                }}
              >
                {this.props.t('talkToUs')}
              </h4>
              <div className='mt-4' style={{ display: 'flex' }}>
                <BsTelephone style={{ fontSize: '30px' }} />
                <p style={{ margin: 'auto 10px' }}>
                  <a href='tel:+353564492380'><u style={{color: theme.textcolor}}>(+353) 564492380</u></a> <br />
                </p>
              </div>
              <br />
              <div style={{ display: 'flex', color: theme.textcolor}}>
                <BsEnvelope style={{ fontSize: '30px' }} />
                <p style={{ margin: 'auto 10px', color: theme.textcolor }}>
                  <a style={{color: theme.textcolor}} href='mailto:support@ovacyte.com'><u style={{color: theme.textcolor}}>support@ovacyte.com</u></a>
                </p>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <FaRegAddressCard style={{ fontSize: '30px' }} />
                <p style={{ margin: 'auto 10px' }}>
                  {this.props.t('ovaCyteSupport')}.
                  <br />
                  71 - 72 {this.props.t('hebronIndustrialEstate')},
                  <br />
                  {this.props.t('hebronRd')},
                  <br />
                  {this.props.t('kilkenny')},
                  <br />
                  {this.props.t('ireland')}.
                </p>
              </div>
              <br />
            </div>
          </div>
          <br />
          <div
            className='row'
            style={{
              color: '#212529',
              fontSize: '1rem',
              fontWeight: '400',
              margin: 'auto',
            }}
          >
            <div className='col-xl-4 col-12 d-none d-xl-block d-print-block pl-5'>
              <hr
                style={{
                  borderColor: '#4472C4',
                  marginLeft: '0',
                }}
              />
            </div>

            <div className='col-xl-3 col-12'>
              <center>
                <h4 style={{color: theme.textcolor}}>{this.props.t('contactUs')}</h4>
              </center>
            </div>

            <div className='col-xl-4 col-12 d-none d-xl-block d-print-block pr-5'>
              <hr
                style={{
                  borderColor: '#4472C4',
                  marginRight: '0',
                }}
              />
            </div>
          </div>
          <br />
          {/* <ContactForm /> */}

          <div className='contactus-options-container' >
            <ContactUsOption
              h2Text={this.props.t('helpSupport')}
              pText={this.props.t('askSupportAQuestion')}
              mailTo='mailto:support@ovacyte.com?subject=Customer%20Support%20Query'
            >
              <BsFillPatchQuestionFill />
            </ContactUsOption>
            <ContactUsOption
              h2Text={this.props.t('dataProtection')}
              pText={this.props.t('submitADataProtectionRequest')}
              mailTo='mailto:dataprotection@telenostic.com?subject=Data%20Protection%20Question%20%2F%20Request'
            >
              <BsFileEarmarkLock2Fill />
            </ContactUsOption>
            <ContactUsOption
              h2Text={this.props.t('salesSupport')}
              pText={this.props.t('raiseAQueryWithSales')}
              mailTo='mailto:sales@ovacyte.com?subject=Sales%20%2F%20Orders%20Question'
            >
              <BsBasket3Fill />
            </ContactUsOption>
            <ContactUsOption
              h2Text={this.props.t('generalQuery')}
              pText={this.props.t('askAGeneralProductionQuestion')}
              mailTo='mailto:info@ovacyte.com?subject=General%20Query'
            >
              <BsQuestionCircle />
            </ContactUsOption>
          </div>
        </div>
      </div>
      )}
      </ThemeContext.Consumer>
    )
    
  }
}

const mapStateToProps = state => ({
  security: state.security,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withTranslation()(Contact))
