import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { login } from '../redux/actions/securityActions'
import { connect } from 'react-redux'

import Button from '../components/Button'
import InputField from '../components/InputField'
import ErrorModal from '../components/ErrorModal'
import { withTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      valEmail: '',
      valPassword: '',
      show: false,
      loading: false,
      showPassword: false,
      cacheVars:{
        "species":{}
      }
    }
    this.onSubmit = this.handleFormSubmit.bind(this)
    this.onChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const rememberMe = localStorage.getItem('rememberMe') === 'true'
    const email = rememberMe ? localStorage.getItem('email') : ''
    this.setState({ email, rememberMe })
  }

  onShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleChange = event => {
    const input = event.target
    const value = input.type === 'checkbox' ? input.checked : input.value
    this.setState({ [input.name]: value })
  }

  errorPopUp = () => {
    if (this.props.errors !== null) {
      return <ErrorModal onHide={this.handleClose} handleClose={this.handleClose} show={this.state.show} />
    }
  }

  // Check input fields for errors
  validate = () => {
    let isError = false
    if (this.state.email.length < 1) {
      isError = true
      this.setState({
        valEmail: this.props.t('usernameIsRequired'),
      })
    }
    if (this.state.password.length < 1) {
      isError = true
      this.setState({
        valPassword: this.props.t('passwordIsRequired'),
      })
    }
    return isError
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const { email, rememberMe } = this.state
    localStorage.setItem('rememberMe', rememberMe)
    localStorage.setItem('email', rememberMe ? email : '')
    
    this.setState({
      loading: true,
      valEmail: '',
      valPassword: '',
    })

    const err = this.validate()
    if (!err) {
      const LoginRequest = {
        username: this.state.email,
        password: this.state.password,
      }
      this.props.login(LoginRequest, this.props.t)
    }
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  render() {
    const { email, password, valPassword, valEmail } = this.state
    return (
      <div className='wrapper-login-container'>
        <div className='row'>
          <div className='opacity-background'>
            <div className='col'>
              <div className='login-container' id='login'>
                <p className='login-header'>{this.props.t('loginToOvacyte')}</p>
                <form onSubmit={this.handleFormSubmit}>
                  <label className='labels'>{this.props.t('username')}</label>
                  <InputField
                    className='input-field'
                    name='email'
                    value={email}
                    placeholder={this.props.t('insertUsername')}
                    onChange={this.handleChange}
                  />
                  {valEmail && <div className='invalid-feedback d-block'>{valEmail}</div>}
                  <span>
                    <label className='labels'>{this.props.t('password')}</label>
                    {this.state.showPassword ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        size='1x'
                        id='password-eye-icon'
                        onClick={this.onShowPassword}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        size='1x'
                        id='password-eye-icon'
                        onClick={this.onShowPassword}
                      />
                    )}
                  </span>
                  {this.state.showPassword ? (
                    <InputField
                      className='input-field'
                      name='password'
                      value={password}
                      placeholder={this.props.t('insertPassword')}
                      type='text'
                      onChange={this.handleChange}
                    />
                  ) : (
                    <InputField
                      className='input-field'
                      name='password'
                      value={password}
                      placeholder={this.props.t('insertPassword')}
                      type='Password'
                      onChange={this.handleChange}
                    />
                  )}
                  {valPassword && <div className='invalid-feedback d-block'>{valPassword}</div>}
                  <Button value='logIn' styleClass='btn-login btn-primary' type='submit' />
                  <div>{this.errorPopUp()}</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  security: state.security,
  errors: state.security.errors,
})

export default withTranslation()(connect(mapStateToProps, { login })(Login))
